import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {


  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  id: number;
  item;


  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
 
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      //this.getState();
      this.getItemById(this.id);
    }
    this.id = this.route.snapshot.params['id'];

    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      basic: ['', Validators.required],
      hra: ['', Validators.required],
      ta: ['', Validators.required],
      da: ['', Validators.required],
      bonus: ['', Validators.required],
      graturity: ['', Validators.required],
      pt: ['',],
      medical: ['',],
      special: ['',],
      incentive: ['',],
      education: ['',],
      monthly_ctc: ['',],
      annual_ctc: ['',],
      provident_fund: ['',],
      professional_tax: ['',],
      income_tax: ['',],
      welfare_trust: ['',],
      esi: [''],
      pension_ded: [''],
      casual_leave: [''],
      medical_leave: [''],
      maternity_leave: [''],
      other_leave: [''],
      previlage_leave: [''],
      restricted_holiday: [''],
      comp_off: [''],
      status: [true],
    });

  }

  get f() { return this.registerForm.controls; }

  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.response = true;
    let request = this.registerForm.value;
    request.type = 'hr-grid';
    request.school_user_id = localStorage.getItem('org_id');
    request.id = this.id;

    this.api.save('add', request).subscribe(
      data => {
        if (data.id != undefined) {
          if(this.id){
            Swal.fire('Success', 'Updated successfully', 'success');
          }else{
            this.submitted = false;
            this.registerForm.reset();
            this.ngOnInit();
            Swal.fire('Success', 'Created successfully', 'success');
          }
        }
      }, err => {
        if (err == 'duplicate') {
          Swal.fire('OOps', 'Duplicate entry for this department please edit for assign designation', 'error');
        } else {
          Swal.fire('OOps', 'Server error found', 'error');
        }
      },()=>{
        this.response = false;
      }

    )

  }

  getItemById(id) {
    let send = { type: 'hr-grid', id: id };
    this.api.get(send).subscribe(
      data => {
        this.item = data;
        this.registerForm = this.formBuilder.group({
          name: [this.item.name, Validators.required],
          basic: [this.item.basic, Validators.required],
          hra: [this.item.hra, Validators.required],
          ta: [this.item.ta, Validators.required],
          da: [this.item.da, Validators.required],
          bonus: [this.item.bonus, Validators.required],
          graturity: [this.item.graturity, Validators.required],
          pt: [this.item.pt],
          medical: [this.item.medical],
          special: [this.item.special],
          incentive: [this.item.incentive],
          education: [this.item.education],
          monthly_ctc: [this.item.monthly_ctc],
          annual_ctc: [this.item.annual_ctc],
          provident_fund: [this.item.provident_fund],
          professional_tax: [this.item.professional_tax],
          income_tax: [this.item.income_tax],
          welfare_trust: [this.item.welfare_trust],
          esi: [this.item.esi],
          pension_ded: [this.item.pension_ded],
          casual_leave: [this.item.casual_leave],
          medical_leave: [this.item.medical_leave],
          maternity_leave: [this.item.maternity_leave],
          other_leave: [this.item.other_leave],
          previlage_leave: [this.item.previlage_leave],
          restricted_holiday: [this.item.restricted_holiday],
          comp_off: [this.item.comp_off],
          status: [this.item.status],
        });



      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }




}
