import { AfterContentChecked, Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { ApiService } from "../../../../api.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import * as moment from "moment";

@Component({
  selector: "app-edit-booking",
  templateUrl: "./edit-booking.component.html",
  styleUrls: ["./edit-booking.component.css"],
})
export class EditBookingComponent implements OnInit {
  projectFormGroup: FormGroup;
  paymentFormGroup: FormGroup;
  personalFormGroup: FormGroup;
  paymentType;
  submitted;
  is_via = false;
  paymentSubmitted = false;
  personalSubmitted = false;
  otherCharges: Number = 0;
  error;
  plotLists: any = [];
  colSpanted = false;
  positionStatus = "No";
  countries;
  states: any = [];
  items: any;
  cities;
  bookingfields: any = [];
  checkedList: any;
  devlopmentCharge = 0;
  adminCharge = 0;
  plcCharge = 0;
  is_party_customer = false;
  checkedListSum: any = [];
  parties: any = [];
  customers: any = [];
  netPlotCost: number = 0;
  grandTotal = 0;
  total_cost = 0;
  projects = [];
  plotNos: any = [];
  projectLists = [];
  properties: any;
  religionLists: any = [];
  paymentModeLists: any = [];
  nationalityLists: any = [];
  nomineeRelationLists: any = [];
  filterStates: any;
  grandAmount: number;
  totalAmount: number = 0;
  dropdownSettings: IDropdownSettings = {};
  totalArea: number = 0;
  booking_id: any;
  item: "";
  discounts = [
    {
      title: "Total Property Cost",
      id: "1",
    },
    {
      title: "Per Unit",
      id: "2",
    },
  ];
  comissionTypes = [
    {
      title: "Per Unit",
      value: "1",
    },
    {
      title: "Percentage",
      value: "2",
    },
    {
      title: "Amount",
      value: "3",
    },
  ];
  response;
  title = "newMat";
  isLinear = true;
  pFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  id;
  other_charges;
  masterSelected: boolean;

  interests = [
    {
      title: "Interest Rate(Per Annum)",
      key: "interest",
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute
  ) {}

  // ngAfterContentChecked(): void {
  //   let grandSum =
  //     this.devlopmentCharge +
  //     this.adminCharge +
  //     this.plcCharge +
  //     this.netPlotCost;
  //   this.total_cost = grandSum;
  // }

  ngOnInit() {
    this.booking_id = this.route.snapshot.paramMap.get("id");
    this.getBooking();
    console.log("booking ID", this.booking_id);

    this.getProjectList({ page_number: 1, limit: 10 });
    this.getCountry();
    this.fields();
    this.getOtherCharges();
    this.getPartyList();
    this.getState();
    this.is_party_customer = false;
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "plot_no",
      selectAllText: "Select All",
      enableCheckAll: false,
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    // this.projectFormGroup = this.formBuilder.group({
    //   projectId: ["", Validators.required],
    //   plotId: ["", Validators.required],
    //   area: ["", Validators.required],
    //   rate: ["", Validators.required],
    //   discount_base: ["", Validators.required],
    //   discount_value: ["", Validators.required],
    //   net_plot_cost: ["", Validators.required],
    //   development_charge: ["", Validators.required],
    //   admin: ["", Validators.required],
    //   plc_charge: ["", Validators.required],
    //   total_cost: [0],
    // });

    this.projectFormGroup = this.formBuilder.group({
      projectId: [""],
      plot_ids: this.formBuilder.array([]),
      area: [""],
      rate: [""],
      discount_base: [""],
      discount_value: [""],
      net_plot_cost: [""],
      development_charge: [""],
      admin: [""],
      plc_charge: [""],
      total_cost: [0],
    });

    this.paymentFormGroup = this.formBuilder.group({
      payment_type: [""],
      bookingTokenEmiDate: [""],
      clearing_date: [""],
      payment_description: [""],
      payment_mode: [""],
      payment_date: [""],
      position_date: [""],
      position_condition: [""],
      manual: [""],
      possession_status: ["No"],
      line_block: [""],
      possession_no: [""],
      message: [""],
      amount: [""],
      emi_mode: [""],
      interestRate: [""],
      emi_date: [""],
      installment: [""],
      interest_type: [""],
      comission: [""],
      total_comission: [""],
      commission_type: [""],
      party_id: [""],
    });

    this.personalFormGroup = this.formBuilder.group({
      customer_name: ["", Validators.required],
      customer_mobile: ["", Validators.required],
      alternate_number: [""],
      customer_email: [""],
      pan: [""],
      adhaar: [""],
      address: [""],
      religion: [""],
      nationality: [""],
      country_id: [""],
      state_id: [""],
      city: [""],
      relation: [""],
      nominee_name: [""],
      email: [""],
      gender: [""],
    });
  }

  onItemSelect(event) {
    this.plotLists.forEach((ele) => {
      if (event.id == ele.id) {
        this.totalArea = this.totalArea + parseInt(ele.area);
        this.projectFormGroup.patchValue({ area: this.totalArea });
        this.plotNos.push(ele.plot_no);
        // this.projectFormGroup.patchValue({ 'rate': ele.rate })
      }
    });
  }


  onItemDeSelect(event) {
    this.plotLists.forEach((ele) => {
      if (event.id == ele.id) {
        this.totalArea = this.totalArea - parseInt(ele.area);
        this.projectFormGroup.patchValue({ area: this.totalArea });
        const newPlotNo = this.plotNos.filter((e) => e.plot_no != ele.plot_no);
        this.plotNos = newPlotNo;
        // this.projectFormGroup.patchValue({ 'rate': ele.rate })
        // console.log('afterRemoveItem', this.plotNos)
      }
    });
  }

  newComission(): FormGroup {
    return this.formBuilder.group({
      party_id: "",
      commission_type: "",
      comission: "",
      total_comission: "",
    });
  }

  get f() {
    return this.projectFormGroup.controls;
  }
  get p() {
    return this.paymentFormGroup.controls;
  }
  get per() {
    return this.personalFormGroup.controls;
  }

  onSubmit() {
    this.response = true;
    this.colSpanted = true;
    if (
      this.projectFormGroup.invalid ||
      this.paymentFormGroup.invalid ||
      this.personalFormGroup.invalid
    ) {
      return;
    }
    let project = this.projectFormGroup.value;
    let payment = this.paymentFormGroup.value;
    let personal = this.personalFormGroup.value;

    let request = {
      projectId: project.projectId,
      plotId: project.plot_ids,
      area: project.area,
      rate: project.rate,
      discount_base: project.discount_base,
      discountAmount: project.discount_value,
      development_charges: project.development_charge,
      admin_charges: project.admin,
      plc_charges: project.plc_charge,
      net_plot_cost: project.net_plot_cost,
      totalAmount: project.total_cost,
      other_charges: this.otherCharges,

      paymentType: payment.payment_type,
      bookingTokenEmiDate: payment.bookingTokenEmiDate,
      clearingDate: payment.clearing_date,
      paymentDescription: payment.payment_description,
      paymentMode: payment.payment_mode,
      paymentDate: payment.payment_date,
      positionDate: payment.position_date,
      positionCondition: payment.position_condition,
      paymentRefNo: payment.manual,
      possesionStatus: payment.possession_status,
      ref_id: payment.party_id,
      ref_type: this.is_via == true ? "MLM" : "Normal",
      lineBlock: payment.line_block,
      possessionNo: payment.possession_no,
      message: payment.message,
      amount: payment.amount,
      emiMode: payment.emi_mode,
      interestRate: payment.interestRate,
      installment: payment.installment,
      emiDate: payment.emi_date,
      comissions: payment.comissions,
      comissionsType: payment.commission_type,
      totalComission: payment.comission,
      totalReceivedAmount: payment.amount,
      currentDues: Number(project.total_cost) - Number(payment.amount),

      customerName: personal.customer_name,
      mobileNo: personal.customer_mobile,
      alternateNo: personal.alternate_number,
      email: personal.email,
      panNo: personal.pan,
      aadharNo: personal.adhaar,
      address: personal.address,
      country: personal.country_id,
      state: personal.state_id,
      city: personal.city,
      religion: personal.religion,
      nationality: personal.nationality,
      nomineeRelation: personal.relation,
      nomineeName: personal.nominee_name,
      gender: personal.gender,
      password: personal.mobileNo,
      school_user_id: localStorage.getItem("school_user_id"),
    };

    // let request = {
    //   'project': this.projectFormGroup.value,
    //   'personal': this.personalFormGroup.value,
    //   'payment': this.paymentFormGroup.value,

    // }
    this.response = false;
    this.api.save("edit-booking/"+this.booking_id, request).subscribe(
      (data) => {
        if (data.code == 200) {
          if (this.id) {
            Swal.fire("Success", "Updated successfully", "success");
          } else {
            Swal.fire({ title: "Success", html: "Created successfully" });
            this.router.navigateByUrl("/realstate/booking/list");
          }
        } else if (data.code == 422) {
          Swal.fire("Error", "Check the all mendatory field", "warning");
        }
      },
      (err) => {
        this.response = false;
        this.error = "Server error found";
        Swal.fire("Error", this.error, "warning");
      },
      () => {
        this.response = false;
      }
    );
  }

  getBooking() {
    this.api.pull("booking-by-id/" + this.booking_id).subscribe((resp: any) => {
      let item = resp.result;
      let payment = item.payment;
      let customer = item.customer;
      this.total_cost = item.netcost;
      this.paymentType = item.booking_type;
      this.setUnit(item.project_id);

      this.onChange(customer.country);

      this.projectFormGroup = this.formBuilder.group({
        projectId: [item.project_id],
        plot_ids: [""],
        area: [item.area],
        rate: [item.rate],
        discount_base: [item.discount_base],
        discount_value: [item.discount_amount],
        net_plot_cost: [item.netcost],
        development_charge: [item.development_charge],
        admin: [item.admin_charge],
        plc_charge: [item.plc_charge],
        total_cost: [item.total_cost],
      });

      // setTimeout(() => {
      //   item.plots.forEach((plot) => {
      //     this.plotLists.forEach((ele) => {
      //       if (plot.id == ele.id) {
      //         this.totalArea = this.totalArea + parseInt(item.area);
      //         this.projectFormGroup.patchValue({ area: this.totalArea });
      //         // this.projectFormGroup.controls['area'].patchValue(this.totalArea);
      //         this.plotNos.push(ele.plot_no);
      //       }
      //     });
      //   });
      // }, 800);

      this.is_party_customer =
        customer.ref_type == "MLM" && this.is_party_customer ? true : false;
      this.is_via = customer.ref_type == "MLM" ? true : false;

      this.paymentFormGroup = this.formBuilder.group({
        payment_type: [payment.booking_type],
        bookingTokenEmiDate: [payment.booking_token_emi_date],
        clearing_date: [payment.emi_clearing_date],
        payment_description: [payment.payment_description],
        payment_mode: [payment.mode],
        payment_date: [payment.emi_start_date],
        position_date: [payment.booking_type],
        position_condition: [payment.booking_type],
        manual: [payment.payment_ref_no],
        possession_status: [payment.possesion_status ? "Yes" : "No"],
        line_block: [payment.booking_type],
        possession_no: [payment.booking_type],
        message: [payment.booking_type],
        amount: [payment.booking_token_emi_amount],
        emi_mode: [payment.booking_token_emi_amount],
        interestRate: [payment.booking_type],
        emi_date: [payment.booking_type],
        installment: [payment.booking_type],
        interest_type: [payment.booking_type],
        comissions: [payment.comissions],
        comissionsType: [payment.commission_type],
        totalComission: [payment.comission],
        totalReceivedAmount: payment.amount,
        comission: [""],
        total_comission: [""],
        commission_type: [""],
        party_id: [customer.ref_id],
      });

      this.personalFormGroup = this.formBuilder.group({
        customer_name: [customer.customer_name],
        customer_mobile: [customer.mobile_no],
        alternate_number: [customer.alternate_mob_no],
        customer_email: [customer.email],
        pan: [customer.pan_no],
        adhaar: [customer.aadhar_no],
        address: [customer.address],
        religion: [customer.religion],
        nationality: [customer.nationality],
        country_id: [customer.country],
        state_id: [customer.state],
        city: [customer.city],
        relation: [customer.nominee_relation],
        nominee_name: [customer.nominee_name],
        email: [customer.email],
        gender: [customer.gender],
      });

      if (resp.code == 200) this.other_charges = resp;
    });
  }

  // addComission() {
  //   this.comissions().push(this.newComission());
  // }

  // removeComission(i: number) {
  //   this.comissions().removeAt(i);
  // }

  paymentClick() {
    this.response = true;
    this.paymentSubmitted = true;

    if (this.paymentFormGroup.invalid) {
      return;
    }
  }

  personalClick() {
    this.personalSubmitted = true;
    if (this.personalFormGroup.invalid) {
      return;
    }
  }

  projectClick() {
    this.submitted = true;
    if (this.projectFormGroup.invalid) {
      return;
    }
  }

  newComissions(): FormGroup {
    return this.formBuilder.group({
      party_id: "",
      commission_type: "",
      comission: "",
      total_comission: "",
    });
  }

  fields() {
    // this.api.post('booking-fields' + "?id=" + localStorage.getItem('user_id'),null)
    this.api
      .post("organization/list", {
        type: "field",
        user_id: localStorage.getItem("user_id"),
      })
      .subscribe((resp) => {
        if (resp.response.length) {
          resp.response.forEach((field) => {
            if (field.field_type_id == 4) {
              this.religionLists.push({
                religionId: field.field_type_id,
                religionName: field.name,
              });
            } else if (field.field_type_id == 25) {
              this.paymentModeLists.push({
                paymentModeId: field.field_type_id,
                paymentModeName: field.name,
              });
            } else if (field.field_type_id == 10) {
              this.nationalityLists.push({
                nationalityId: field.field_type_id,
                nationalityName: field.name,
              });
            } else if (field.field_type_id == 47) {
              this.nomineeRelationLists.push({
                nomineeRelationId: field.field_type_id,
                nomineeRelationName: field.name,
              });
            }
          });
        }
      });
  }

  getOtherCharges() {
    // ('charge-list?school_user_id=' + localStorage.getItem('school_user_id'))
    this.api
      .post(
        "charge-list?school_user_id=" + localStorage.getItem("school_user_id"),
        "Null"
      )
      .subscribe((resp) => {
        this.other_charges = resp;

        if (this.other_charges.code == 200) this.other_charges = resp;
        this.other_charges = this.other_charges.result.list;
      });
  }

  // Check All Checkbox Checked
  isAllSelected(event) {
    this.otherCharges =
      this.otherCharges + event.target.checked ? event.target.value : 0;
    this.totalAmount = event.target.checked
      ? this.totalAmount + parseInt(event.target.value)
      : this.totalAmount - parseInt(event.target.value);
    let projectForm = this.projectFormGroup.value;
    let net_plot_cost = projectForm.net_plot_cost;
    this.netPlotCost = parseInt(net_plot_cost) + this.totalAmount;
  }

  setClearingDate() {
    let paymentFormControls = this.paymentFormGroup.value;
    let emiStrtDate = paymentFormControls.emi_date;
    let emiTenure = paymentFormControls.emi_mode;
    let emiClearingDate = moment(emiStrtDate)
      .add(emiTenure, "M")
      .format("DD-MM-YYYY");
    this.paymentFormGroup.patchValue({ clearing_date: emiClearingDate });
  }

  netPlotCostChange(event) {
    let projectForm = this.projectFormGroup.value;
    let discount = projectForm.discount_base;
    let area = projectForm.area;
    let unit = projectForm.rate;
    let discountValues = projectForm.discount_value;
    this.netPlotCost =
      discount == 1
        ? area * unit - discountValues
        : area * (unit - discountValues);
    // this.totalAmount=event.target.checked?this.totalAmount+parseInt(event.target.value):this.totalAmount-parseInt(event.target.value)
    this.projectFormGroup.patchValue({ net_plot_cost: this.netPlotCost });
  }

  getPartyList() {
    this.api
      .post("list/party", {
        id: localStorage.getItem("school_user_id"),
        type: "party",
      })
      .subscribe((resp) => {
        this.parties = resp.response;
      });
    this.api
      .post("list/booking-customer", {
        id: localStorage.getItem("school_user_id"),
        type: "booking-customer",
      })
      .subscribe((resp) => {
        this.customers = resp.response;
      });
  }

  getProjectList(extra = {}) {
    var filter = { user_id: localStorage.getItem("user_id") };
    filter = { ...filter, ...extra };
    this.response = true;

    this.api.post("project-list-withPlot", filter).subscribe(
      (data) => {
        this.response = false;
        this.projectLists = data.result.list;
      },
      (err) => {
        this.response = false;
      },
      () => console.log("loading completed")
    );
  }

  setUnit(projectId) {
    this.api
      .post("unSold-plotList", {
        projectId: projectId,
        userId: localStorage.getItem("user_id"),
      })
      .subscribe(
        (data) => {
          this.response = false;
          this.plotLists = data.result.list;
        },
        (err) => {
          this.response = false;
        },
        () => console.log("loading completed")
      );
  }

  // setAreaRate(plotId) {
  // }

  getState() {
    this.api.post("list", { type: "state" }).subscribe(
      (data) => {
        this.states = data;
        if (data) {
          this.onChange(data.country_id);
        }
      },
      (err) => {
        console.error(err);
      },
      () => console.log("loading completed")
    );
  }

  setStateByCountryId(country_d) {
    let newStates = [];
    this.states.forEach(function (value) {
      if (value.country_id == country_d) {
        newStates.push(value);
      }
    });
    this.filterStates = newStates;
  }

  getCountry() {
    this.api.post("list", { type: "country" }).subscribe(
      (data) => {
        this.countries = data;
      },
      (err) => {
        console.error(err);
      },
      () => console.log("loading completed")
    );
  }

  onChange(country_d) {
    let newStates = [];
    this.states.forEach(function (value) {
      if (value.country_id == country_d) {
        newStates.push(value);
      }
    });
    this.filterStates = newStates;
  }

  calcuRateOfInterest(value) {
    if (value == "interest") {
      let paymentFormGroup = this.paymentFormGroup.value;
      let totalAmount = this.projectFormGroup.value.total_cost;
      let reduceAmount = paymentFormGroup.amount;
      let emaiMode = paymentFormGroup.emi_mode;
      let interestRate = paymentFormGroup.interestRate;
      let newTotAmount = parseInt(totalAmount) - parseInt(reduceAmount);
      let calInterestAmount = (newTotAmount * parseInt(interestRate)) / 100;
      let emiAmount = (newTotAmount + calInterestAmount) / parseInt(emaiMode);
      this.paymentFormGroup.patchValue({ installment: emiAmount.toFixed(2) });
    }
  }

  // changeCommisionType() {
  //   let projectForm = this.projectFormGroup.value;
  //   let commision;
  //   this.comissions().value.forEach((element, item) => {
  //     if (element.commission_type == 1) {
  //       let projectForm = this.projectFormGroup.value;
  //       commision = parseInt(element.comission) * parseInt(projectForm.area);
  //       this.comissions().controls[item].patchValue({
  //         total_comission: commision,
  //       });
  //     } else if (element.commission_type == 2) {
  //       commision =
  //         (parseInt(projectForm.total_cost) * parseInt(element.comission)) /
  //         100;
  //       this.comissions().controls[item].patchValue({
  //         total_comission: commision,
  //       });
  //     } else if (element.commission_type == 3) {
  //       commision = parseInt(element.comission);
  //       this.comissions().controls[item].patchValue({
  //         total_comission: commision,
  //       });
  //     }
  //   });
  // }
}
