import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { ConfirmationDialogService } from 'src/app/modal/confirmation-dialog.service';

import Swal from 'sweetalert2';

import * as $ from 'jquery';
import { ChangeChequeStatusComponent } from 'src/app/modal/change-cheque-status/change-cheque-status.component';


@Component({
  selector: 'app-payment-report-listing',
  templateUrl: './payment-report-listing.component.html',
  styleUrls: ['./payment-report-listing.component.css']
})
export class PaymentReportListingComponent implements OnInit {

  childData: string = 'Hello';


  hideFilter = false;
  response = true;
  paymentFormGroup: FormGroup;
  clearCashForm: FormGroup;
  items: any = [];
  pageNumber: number = 1
  pageOfItems: Array<any>;
  totalCount: number;
  submitted = false;
  bookingType;
  totalReceivedAmount = 0;
  selected_check_tatus = false;
  cheque_payment_id;
  check_status = [
    {
      key: "",
      value: "Select one"
    },
    {
      key: 1,
      value: "Clear"
    },
    {
      key: 2,
      value: "Bounce"
    },
    {
      key: 3,
      value: "Pending"
    }
  ];
  // totalAmount=0;
  totalDuesAmount = 0;
  constructor(private formBuilder: FormBuilder, private api: ApiService) { }


  ngOnInit() {
    this.getlist({
      page_number: 1,
      limit: 10
    });
    this.clearCashForm = this.formBuilder.group({
      transaction_id: ['', Validators.required],
      description: ['', Validators.required],
      status: ["", Validators.required],
    });
  }

  emittedDataByChild(item) {
    this.getlist();
  }

  get p() { return this.paymentFormGroup.controls; }
  get clear() { return this.clearCashForm.controls; }


  generatePaymentReport(payment_id) {
    this.response = true;
    this.api.getPdf('customer/payment/generate-report?payment_id=' + payment_id).subscribe((data: any) => {

      const blob = new Blob([data], { type: 'application/pdf' });
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = payment_id + ".pdf";
      link.click();
      this.response = false;


    });
  }


  getlist(extra = {}) {
    this.totalReceivedAmount = 0;
    this.totalDuesAmount = 0;
    // this.totalAmount=0;
    let filter = { type: this.bookingType, schoolUserId: localStorage.getItem('school_user_id') }
    filter = { ...filter, ...extra };
    let p = extra["limit"];
    let pageNumber = extra["page_number"];
    this.api.post('customer-payment-list', filter).subscribe(async (resp) => {
      if (resp.code == 200) {
        this.response = false;
        this.totalCount = resp.total;
        this.items = resp.result.map((element, index) => {
          element.no = p * (pageNumber - 1) + index + 1
          this.totalDuesAmount = Number(this.totalDuesAmount) + Number(element.current_dues)
          this.totalReceivedAmount = Number(this.totalReceivedAmount) + Number(element.booking_token_emi_amount)
          return element;
        });
      }
      // this.api.getItem('booking-list, localStorage.getItem('school_user_id')).subscribe(resp => {

    },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  }

  public paymentTabsData(data) {
    this.response = true;
    this.bookingType = data;
    console.log('bookingType', this.bookingType);
    this.getlist({
      page_number: 1,
      limit: 10
    });
  }

  onChangePage(pageOfItems) {
    this.pageNumber = pageOfItems;
    this.getlist({
      "page_number": pageOfItems,
      "limit": 10,
    })
  }
  showHideFilter() {
    this.hideFilter = !this.hideFilter;
  }




  deleteItem(id, reason) {
    let item = { id: id, reason: reason }
    this.response = true;
    this.api.post('delete-customer-payment', item).subscribe(
      data => {
        this.ngOnInit()
        this.response = false;
      }, err => {
        this.response = false;
        console.log(err);
      }
    )

  };

  public openConfirmationDialog(id) {
    const Toast = Swal.mixin({
      input: 'textarea',
      inputPlaceholder: 'Reason...',
      inputAttributes: {
        'aria-label': 'Reason'
      },
      showCancelButton: true
    })
    Toast.fire('Please confirm...', 'Do you really want to delete... ?', 'info').then((result) => {
      if (result.isConfirmed) {
        this.deleteItem(id, result.value);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your file record is safe :)', 'error')
      }
    })
  }

  getChequePaymentId(id) {
    this.cheque_payment_id = id;

  }

}
