import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'
import { IDropdownSettings } from 'ng-multiselect-dropdown';

declare var $:any;

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  id: number;
  item;
  fieldTypes;
  routes;
  pickup_id:any;
  transports;
  pick_drops;
  route_pickup_point:any;
  vichles;
  allReadySelected = [];

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getFiledByKeys();
    this.getVichle();
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);

    }
    this.registerForm = this.formBuilder.group({
      transport_id: ['', Validators.required],
      route_id: ['', Validators.required],
      route_pickup_point: ['', Validators.required],
      status: [true],
    });
    setTimeout(() => {
      $('.selectpicker').selectpicker('refresh');
    },500);
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    let request = this.registerForm.value;
    request.type = 'add-assign-pickup-drop';
    request.school_user_id = localStorage.getItem('org_id');
    request.user_id = localStorage.getItem('user_id');
    request.id = this.id;
    this.response = true;

    this.api.save('add', request).subscribe(
      data => {
        
        if (data.id != undefined) {
          if(this.id){
            Swal.fire('Success', 'Updated successfully', 'success');
          }else{
            this.submitted = false;
            this.registerForm.reset();
            this.ngOnInit();
            Swal.fire('Success', 'Created successfully', 'success');
          }
        }
        
      }, err => {
        this.response = false;
        this.error = "Server error found";
      },()=>{
        this.response = false;
      }

    )

  }

  getItemById(id) {
    const MAIN = this;
    let send = { type: 'assign-pick-drop', id: id };
    this.api.get(send).subscribe(
      async (data) => {
        this.item = data;
        MAIN.allReadySelected = [];
        await this.item.point.forEach((p)=>{
          MAIN.allReadySelected.push(p.pickup_route_id);
        });

        await this.clickRoute(this.item.route_id);
        
        this.registerForm = this.formBuilder.group({
          transport_id: [this.item.transport_id, Validators.required],
          route_id: [this.item.route_id, Validators.required],
          route_pickup_point: [MAIN.allReadySelected.map(String), Validators.required],
          status: [this.item.status, Validators.required]
        });

        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        }, 200);
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }

  getVichle(){
    this.response = true;

    this.api.post('list/vichles',{type:'get-transport',id:localStorage.getItem('org_id')}).subscribe(
      data=>{
        this.response = false;
        this.vichles = data;
        this.vichles = this.vichles.response;
      },
      err =>{
        this.response = false;
      },
      ()=>console.log('loading completed')
    )
  };



  getFiledByKeys() {
    this.response = true;
    let send = { type: 'add-assign-pickup-drop', id:localStorage.getItem('org_id') };
    this.api.post('list/fields',send).subscribe(
      data => {
        this.response = false;
        this.fieldTypes = data;
        this.fieldTypes = this.fieldTypes.response;
        this.transports = this.fieldTypes.transport;
        this.routes = this.fieldTypes.route;
        if(this.id && this.item){
          this.clickRoute(this.item.route_id);
        }
      },
      err => {
        this.response = false;
        console.error(err);
      },
      () => console.log('loading completed')
    )
  
  }
  clickRoute(id){
    const MAIN = this;
    return new Promise(async function (resolve, reject) {
      MAIN.pick_drops = []; 
      if(MAIN.routes){
        await MAIN.routes.forEach(async (element) => {
          if(element.id==id){
            await element.pickdrops.forEach(async (pi)=>{
              var string = [];
              await pi.pickin.forEach((p)=>{
                string.push(p.pick.name)
              });
              MAIN.pick_drops.push({
                id:pi.id,
                name: string.join(', ')+' ('+pi.mode.length+' mode)'
              })
            })
          }
        });
      }
      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh');
      },500);
      resolve({});
    })
  }

}
