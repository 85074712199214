import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../../modal/confirmation-dialog.service';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  response = true;
  pageOfItems: Array<any>;
  closeResult = '';
  registerForm: FormGroup;
  submitted = false;
  error;
  success: string;
  items: any;
  id: number;
  parentProperty;
  enquiry_id:number;
  loader;

  constructor(private formBuilder: FormBuilder,private api:ApiService,public router:Router,private confirmationDialogService: ConfirmationDialogService, private modalService: NgbModal) { }

  ngOnInit() {
    this.getLists();
    this.api.isLogged();
  }


  getLists(){
    this.response = true;

    this.api.post('list/lists',{type:'get-transport',id:localStorage.getItem('org_id')}).subscribe(
      data=>{
        this.response = false;
        this.items = data;
        this.items = this.items.response;
      },
      err =>{
        this.response = false;
      },
      ()=>console.log('loading completed')
    )
  };

  onChangePage(pageOfItems) {
    this.pageOfItems = pageOfItems;
  }


  deleteItem(id){
    this.response=true;
    let item = {id:id,type:'party2'};
    this.api.delete(item).subscribe(
      data=>{
        this.response=false;
        this.pageOfItems.splice(this.pageOfItems.findIndex(function(i){
          return i.id == id;
      }), 1);
      },err=>{
        this.response = false;
        console.log(err);
      }
    )

  };

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);
    }

    })
  }





}
