import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-member-tree',
  templateUrl: './member-tree.component.html',
  styleUrls: ['./member-tree.component.css']
})
export class MemberTreeComponent implements OnInit {
  customerData: any;
  rows: any;
  page = {
    limit: 10,
    count: 0,
    offset: 0,
  };
  constructor(private api: ApiService, public router: Router,) { }

  ngOnInit() {
    this.getTreeMemebrData()
  }


  getTreeMemebrData() {
    this.api.post('get-tree-member', { school_user_id: localStorage.getItem('user_id') }).subscribe(
      data => {
        console.log('asddfs', data.result)
        this.rows = data.result;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  }

}
