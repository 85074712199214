import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-category-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;
  
  submitted = false;
  response;
  error;
  success:string;
  items: any;
  id: number;
  constructor(private formBuilder: FormBuilder, private api:ApiService, private route: ActivatedRoute) {

   }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);
    }


    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      status: ['1']
  });
  // this.getSessions();

  }

     // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

     onSubmit() {

      this.submitted = true;
      if (this.registerForm.invalid) {
          return;
      }
      this.response = true;
let request =this.registerForm.value;
request.type='category';
request.id= this.id;
request.school_user_id=localStorage.getItem('org_id');
 this.api.save('add',request).subscribe(
   data => {
    if (data.id != undefined) {
      if(this.id){
        this.success = "Updated successfully";
      }else{
        this.submitted = false;
        this.registerForm.reset();
        this.ngOnInit();
        this.success="New cateogry added successfully";
      }
    }   
  },err =>{
    this.response = false;
    this.error = "Server error found";

   },
   ()=>{
    this.response = false;
   }
   
 )
      
  }

  getItemById(id) {
    let send = { type: 'category', id: id };
    this.api.get(send).subscribe(
      data => {
        this.registerForm = this.formBuilder.group({
          name: [data.name, Validators.required],
          status: [data.status, Validators.required]
        });

      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  }

  // //get school sessions

  // getSessions(){
  //   this.api.post('list/category',{type:'school-category',id:localStorage.getItem('org_id')}).subscribe(
  //     data=>{
  //       this.response = false;
  //       this.items = data;
  //       this.items = this.items.response;
  //     },
  //     err =>{
  //       this.response = false;

  //       console.error(err);
  //     },
  //     ()=>console.log('loading completed')
  //   )
  // }

}
