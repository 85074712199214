import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';


@Component({
  selector: 'app-sms-report',
  templateUrl: './sms-report.component.html',
  styleUrls: ['./sms-report.component.css']
})
export class SmsReportComponent implements OnInit {
  items;
  item;
  buttonView ;
  constructor(private api: ApiService) { }

  ngOnInit() {
    this.getReport(); 
  }

  getReport() {
    this.api.getReport({type:'school'}).subscribe(
      data => {
        this.items = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  isPlanRequest(item) {
    item.show = !item.show;

    // CHANGE THE NAME OF THE BUTTON.
    if (item.show)
      this.buttonView = "show";
    else
      this.buttonView = "hide";
  }

}
