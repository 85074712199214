import { Component, OnInit,KeyValueChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  id: number;
  item;
  transport_types
  payment_modes;
  fieldTypes;
  parties;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getFiledByKeys();
    this.geParty();
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);
    }


    this.registerForm = this.formBuilder.group({
      party_id: ['', Validators.required],
      vichle_type: ['', Validators.required],
      vehicle_no: ['', Validators.required],
      reg_date: [''],
      reg_valid: [''],
      vehicle_detail: [''],
      reg_address: [''],
      insurance: [''],
      finance:[''],
      seat: ['', Validators.required],
      driver: ['', Validators.required],
      driver_mob: [''],
      dl: [''],
      rc_no:[''],
      dl_expiry: [''],
      conductor: [''],
      conductor_mob: [''],
      payment_mode: [''],
      payment_amount:[''],
      document_type:[''],
      document: [''],
      contract_date: [''],
      renew_date: [''],
      status: [true],
    });

  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.response = true;
    let request = this.registerForm.value;
    request.type = 'add-transport';
    request.school_user_id = localStorage.getItem('org_id');
    request.user_id = localStorage.getItem('user_id');
    request.id = this.id;

    this.api.save('add', request).subscribe(
      data => {
        this.response = false;

        if (data.id != undefined) {
          if(this.id){
            Swal.fire('Success', 'Updated successfully', 'success');
          }else{
            this.submitted = false;
            this.registerForm.reset();
            this.ngOnInit();
            Swal.fire('Success', 'Created successfully', 'success');
          }
        }
        
      }, err => {
        this.response = false;
        if(err.error=="duplicate"){
          Swal.fire('OOps', 'This vehicle number already available', 'error');
        }else{
          Swal.fire('OOps', 'Server error found', 'error');
        }
      }, ()=>{
        this.response = false;
      }
    )
  }

  getItemById(id) {
    this.response = true;
    let send = { type: 'transport', id: id };
    this.api.get(send).subscribe(
      data => {
        this.item = data;
        this.registerForm = this.formBuilder.group({
          party_id: [this.item.party_id, Validators.required],
          vichle_type: [this.item.vichle_type, Validators.required],
          vehicle_no: [this.item.vehicle_no, Validators.required],
          reg_date: [this.item.reg_date],
          reg_valid: [this.item.reg_valid],
          vehicle_detail: [this.item.vehicle_detail],
          reg_address: [this.item.reg_address],
          insurance: [this.item.insurance],
          finance:[this.item.finance],
          seat: [this.item.seat, Validators.required],
          driver: [this.item.driver, Validators.required],
          driver_mob: [this.item.driver_mob],
          dl: [this.item.dl],
          rc_no:[this.item.rc_no],
          dl_expiry: [this.item.dl_expiry],
          conductor: [this.item.conductor],
          conductor_mob: [this.item.conductor_mob],
          payment_mode: [this.item.payment_mode],
          payment_amount:[this.item.payment_amount],
          document_type:[this.item.document_type],
          document: [this.item.document],
          contract_date: [this.item.contract_date],
          renew_date: [this.item.renew_date],
          status: [this.item.status ? true : false],
    });
        this.response = false;



      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }



getFiledByKeys() {
  this.response = true;
  let send = { type: 'field-by-keys', user_id: localStorage.getItem('user_id'),keys:['transport_type','payment_mode'] };
  this.api.post('list/fields',send).subscribe(
    data => {
      this.response = false;
      this.fieldTypes = data;
      this.fieldTypes = this.fieldTypes.response;
      this.transport_types = this.fieldTypes.transport_type;
      this.payment_modes = this.fieldTypes.payment_mode;
    },
    err => {
      this.response = false;
      console.error(err);
    },
    () => console.log('loading completed')
  )

}

geParty(){
  this.api.post('list/lists',{type:'party',id:localStorage.getItem('org_id')}).subscribe(
    data=>{
      this.response = false;
      this.parties = data;
      this.parties = this.parties.response;
    },
    err =>{
      this.response = false;
    },
    ()=>console.log('loading completed')
  )
};



}
