import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  @ViewChild('myform', { static: false }) myform: NgForm;
  
  items:any;
  reqSuccess;
  error;
  response = false;
  registerForm: FormGroup;
  submitted = false;
  status:any;
  reqResponse;
  reqResponseError;
  constructor(private api:ApiService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.items = {
      email:""
    }
  }

  
  submitItem(request){
    
    this.reqResponseError = false;
    this.reqSuccess = false;
    this.response = true;
    this.submitted = true;

    this.api.add('reset-password',request).subscribe(
      data=>{
        this.reqResponse = data;
        this.response =false;
        if(this.reqResponse.status=='success'){
          this.reqSuccess = "New Password Send To Your Email";
          this.submitted = false;
          this.myform.resetForm();
          this.ngOnInit();
        }
      },
      err =>{
        this.response = false;
        this.reqResponseError = err.statusText;
      },
      ()=>console.log('loading completed')
    )
  }

}
