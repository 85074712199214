import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../../modal/confirmation-dialog.service';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  response = true;
  pageOfItems: Array<any>;
  closeResult = '';
  registerForm: FormGroup;
  submitted = false;
  error;
  success: string;
  items: any;
  id: number;
  parentProperty;
  enquiry_id:number;

  constructor(private formBuilder: FormBuilder,private api:ApiService,public router:Router,private confirmationDialogService: ConfirmationDialogService, private modalService: NgbModal) { }

  ngOnInit() {
    this.getLists();
    this.api.isLogged();

    this.registerForm = this.formBuilder.group({
      remark: ['', Validators.required],
      comment : ['', Validators.required],
      follow_up_date: ['', Validators.required],
    });
  }


  getLists(){
    this.api.post('list/lists',{type:'party',id:localStorage.getItem('org_id')}).subscribe(
      data=>{
        this.response = false;
        this.items = data;
        this.items = this.items.response;
      },
      err =>{
        this.response = false;
      },
      ()=>console.log('loading completed')
    )
  };

  onChangePage(pageOfItems) {
    this.pageOfItems = pageOfItems;
  }


  deleteItem(id){
    this.response=true;
    let item = {id:id,type:'party'};
    this.api.delete(item).subscribe(
      data=>{
        this.response=false;
        this.pageOfItems.splice(this.pageOfItems.findIndex(function(i){
          return i.id == id;
      }), 1);
      },err=>{
        this.response = false;
        console.log(err);
      }
    )

  };

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);
    }

    })
  }

  open(content,id) {
    this.parentProperty = id;
    this.enquiry_id =id;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }



  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  
  onSubmit() {
    //let enquiry_id = this.getEnquiryId(3);
    let request = this.registerForm.value;
    request.type = 'add-party';
    request.school_user_id = localStorage.getItem('org_id');
    request.user_id = localStorage.getItem('user_id');
    request.enquiry_id = this.enquiry_id;
    request.id = this.id;

    this.api.save('add', request).subscribe(
      data => {
        this.parentProperty=this.enquiry_id;
        if (data == 'success') {
          if (this.id) {
            this.success = "Updated successfully";
          } else {
            this.registerForm.reset();
            this.success = "New field created successfully";
          }
     

        }
      }, err => {
        this.response = false;
        this.error = "Server error found";

      }

    )

  }



}
