import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'

declare var $:any;


@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.css']
  })
  export class ListComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  loader;
  error;
  success: string;
  items: any;
  id: number;
  item;
  fieldTypes;
  removeids = [];

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }


  ngOnInit() {
    this.getType();



    this.registerForm = this.formBuilder.group({
      form_id: this.formBuilder.array([])
    });

  }

  get f() { return this.registerForm.controls; }






  selectAll(isChecked: boolean) {
    const main = this;
    for(const type of this.fieldTypes){
      for(const form of type.forms){
        form.setting = isChecked;
        main.onChange(form.id, isChecked)
      }
    }
  }
  onChange(id, isChecked: boolean) {
    const FormArray = <FormArray>this.registerForm.controls.form_id;
    if(isChecked){
      FormArray.push(new FormControl(id));
    }
    else {
      let index = FormArray.controls.findIndex(x => x.value == id)
      this.removeids.push(id);
      FormArray.removeAt(index);
    }
  }




  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
  this.loader= true;
  let request = this.registerForm.value;
    request.type = 'employee-form-setting';
    request.school_user_id = localStorage.getItem('org_id');
    request.id = this.id;
    request.removeids = this.removeids;

    this.error = "";
    this.api.save('add', request).subscribe(
      data => {
        this.loader= false;

        Swal.fire('Success', 'ok','success');

      }, err => {
        //this.response = false;
        this.loader= false;
        this.error = "Server error found";
        Swal.fire('Oops', 'error','error');
      }

    )

  }

  getType() {
    this.loader=true;
    this.api.post('list/get', { type: 'employee-seting-type',school_user_id:localStorage.getItem('org_id') }).subscribe(
      data => {
        this.loader=false;
        this.response = false;
        this.fieldTypes = data;
        this.fieldTypes = this.fieldTypes.response;
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };

}
