import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/api.service';
import { SharingServiceService } from 'src/app/services/sharing-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-property-evaluation',
  templateUrl: './add-property-evaluation.component.html',
  styleUrls: ['./add-property-evaluation.component.css']
})

export class AddPropertyEvaluationComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private api: ApiService, private sharingService: SharingServiceService, private router: Router) { }
  isLinear = true;
  countries: any;
  prpertyFormGroup: FormGroup;
  plotFormGroup: FormGroup;
  properties: any[] = [];
  projects: any[] = [];
  projectLists = [];
  filterStates = [];
  genders = [{ id: 1, title: "Male" }, { id: 2, title: "Female" }];
  units = [{ id: 1, title: "ACRE" }, { id: 2, title: "BIGHA" }, { id: 3, title: "BISWA" }, { id: 4, title: "SQ. METER" }, { id: 5, title: "SQ. FEET" }, { id: 6, title: "SQ. YARDS" }]
  data: any;
  response: boolean = false;
  numberPattern = "^[-+]?[0-9]*\\.?[0-9]*$"
  aadharNoPattern = "^[0-9]*$"
  isPropertySizeEntered: boolean = false;
  actualUnit = '';
  submitted: boolean = false;
  avoidedNumbers = [];
  sellerSubmitted: boolean = false;
  total: Number = 0;

  ngOnInit() {
    this.getProjectList({ "page_number": 1, "limit": 10 });
    this.prpertyFormGroup = this.formBuilder.group({
      project_id: [''],
      property_size: [''],
      unit: [''],
      projected_unit: [''],
      projected_area: [''],
      road: [''],
      severage: [''],
      temple_mosque: [''],
      community_hall: [''],
      parking: [''],
      garden: [''],
      play_ground: [''],
      other: ['', Validators.required],
      total_covered: [''],
      available_land_for_sale: [''],
    });

    this.plotFormGroup = this.formBuilder.group({
      property_size: [''],
      rest_land: [''],
      extra_req_land: [''],
      prefix: [''],
      prefix_start: [''],
      avoid_number: [''],
      plots: this.formBuilder.array([
      ]),

    });
    // this.plotDetails().push(this.newPlotDetails())
  }
  initForm() {

  }

  setProjectedArea(projectedUnitType) {
    console.log('value', projectedUnitType)
    let calc = 1;
    //units=[{id:1,title:"ACRE"},{id:2,title:"BIGHA"},{id:3,title:"BISWA"},{id:4,title:"SQ. METER"},{id:5,title:"SQ. FEET"},{id:6,title:"SQ. YARDS"}]
    if (projectedUnitType == "ACRE") {
      if (this.prpertyFormGroup.value.unit.toUpperCase() == "ACRE") {
        calc = Number(this.actualUnit);

      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "BIGHA") {
        calc = Number(this.actualUnit) * 0.6198347106;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "BISWA") {
        calc = Number(this.actualUnit) * 0.03099173553;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. METER") {
        calc = Number(this.actualUnit) / 4047;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. FEET") {
        calc = Number(this.actualUnit) / 43560;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. YARDS") {
        calc = Number(this.actualUnit) / 4840;
      }
    }
    else if (projectedUnitType == "BIGHA") {
      if (this.prpertyFormGroup.value.unit.toUpperCase() == "ACRE") {
        calc = Number(this.actualUnit) * 1.613;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "BIGHA") {
        calc = Number(this.actualUnit);
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "BISWA") {
        calc = Number(this.actualUnit) * 0.05;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. METER") {
        calc = Number(this.actualUnit) * 0.000398663;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. FEET") {
        calc = Number(this.actualUnit) / 27000;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. YARDS") {
        calc = Number(this.actualUnit) * 0.000333333;
      }
    }
    else if (projectedUnitType == "BISWA") {
      if (this.prpertyFormGroup.value.unit.toUpperCase() == "ACRE") {
        calc = Number(this.actualUnit) * 32.00000001;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "BIGHA") {
        calc = Number(this.actualUnit) / 0.05;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "BISWA") {
        calc = Number(this.actualUnit);
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. METER") {
        calc = Number(this.actualUnit) / 125.419104;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. FEET") {
        calc = Number(this.actualUnit) / 1350;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. YARDS") {
        calc = Number(this.actualUnit) / 149.9999854;
      }
    }
    else if (projectedUnitType == "SQ. METER") {
      if (this.prpertyFormGroup.value.unit.toUpperCase() == "ACRE") {
        calc = Number(this.actualUnit) * 4047;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "BIGHA") {
        calc = Number(this.actualUnit) * 2508.38;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "BISWA") {
        calc = Number(this.actualUnit) * 125.419104;

      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. METER") {
        calc = Number(this.actualUnit);
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. FEET") {
        calc = Number(this.actualUnit) / 10.764;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. YARDS") {
        calc = Number(this.actualUnit) / 1.196;
      }
    }
    else if (projectedUnitType == "SQ. FEET") {
      if (this.prpertyFormGroup.value.unit.toUpperCase() == "ACRE") {
        calc = Number(this.actualUnit) * 43560;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "BIGHA") {
        calc = Number(this.actualUnit) * 27000;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "BISWA") {
        calc = Number(this.actualUnit) * 1350;

      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. METER") {
        calc = Number(this.actualUnit) * 10.764;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. FEET") {

        calc = Number(this.actualUnit);
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. YARDS") {
        calc = Number(this.actualUnit) * 9;
      }
    }
    else if (projectedUnitType == "SQ. YARDS") {
      if (this.prpertyFormGroup.value.unit.toUpperCase() == "ACRE") {
        calc = Number(this.actualUnit) * 4840;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "BIGHA") {
        calc = Number(this.actualUnit) * 2999.999707;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "BISWA") {
        calc = Number(this.actualUnit) * 149.9999854;

      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. METER") {
        calc = Number(this.actualUnit) * 1.196;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. FEET") {

        calc = Number(this.actualUnit) / 9;
      }
      else if (this.prpertyFormGroup.value.unit.toUpperCase() == "SQ. YARDS") {
        calc = Number(this.actualUnit);
      }
    }
    let calculatedAmount = calc.toFixed(2)
    console.log('amountCalculated', calculatedAmount);
    this.prpertyFormGroup.patchValue({ 'projected_area': calculatedAmount });
  }

  setTotalCovered(amount) {
    console.log('TypeOfAmount', typeof (amount))
    // this.total = Number(this.total) + Number(amount);
    this.total = Number(this.prpertyFormGroup.value.road) + Number(this.prpertyFormGroup.value.severage) + Number(this.prpertyFormGroup.value.temple_mosque) + Number(this.prpertyFormGroup.value.community_hall) +
      Number(this.prpertyFormGroup.value.parking) + Number(this.prpertyFormGroup.value.garden) + Number(this.prpertyFormGroup.value.play_ground) + Number(this.prpertyFormGroup.value.other)
    this.prpertyFormGroup.patchValue({ 'total_covered': this.total });
    console.log('Total', this.total);
    let projectedArea = this.prpertyFormGroup.value.projected_area
    let landForSale = Number(projectedArea) - Number(this.total);
    if (landForSale > 0) {
      this.prpertyFormGroup.patchValue({ 'available_land_for_sale': landForSale.toFixed(2) });
    }
    else {
      this.prpertyFormGroup.patchValue({ 'available_land_for_sale': 0 });
    }
    this.setRestLand();
  }

  getProjectList(extra = {}) {
    let filter = { user_id: localStorage.getItem('user_id') };
    this.response = true;
    this.api.post('projectList-withoutPlot', filter).subscribe(
      data => {
        this.response = false;
        console.log("response", this.projectLists);
        this.projectLists = data.result.list;

        // data.result.list.forEach(element=>{
        //   this.projects.map(x=>{
        //     if(x.id==Number(element.project)){
        //       this.projectLists.push({"name":x.name,"id":element.id})
        //     }
        //   })

        // });
        // return this.projectLists
      },

      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };
  // getProperty(){
  //   this.api.post('organization/list',{type:"field",user_id:localStorage.getItem('user_id')}).subscribe(resp => {
  //     if (resp.response.length > 0) {
  //       this.properties=resp.response.filter(ele=>{
  //        return  ele.field_type.name.toLowerCase()=="property type"
  //       });
  //       this.projects=resp.response.filter(ele=>{
  //         return  ele.field_type.name.toLowerCase()=="project";
  //        });
  //     }
  //     console.log("projects",this.projects)
  //     this.getProjectList({"page_number":1,"limit":10});
  //   })
  // }
  // getProperty(){
  //   this.api.post('organization/list',{type:"field",user_id:localStorage.getItem('user_id')}).subscribe(resp => {
  //     if (resp.response.length > 0) {
  //       this.properties=resp.response.filter(ele=>{
  //        return  ele.field_type.name.toLowerCase()=="property type"
  //       });
  //       this.projects=resp.response.filter(ele=>{
  //           return ele.field_type.name.toLowerCase()=="project";
  //        });
  //       this.projectList.forEach(e => {
  //         this.projects.forEach((d,i)=> {
  //           if(d.id!==e.project){

  //             this.projects.splice(i,1)
  //           }
  //         })
  //       })
  //     }
  //     console.log("projectList",this.projects)
  //     return this.projects
  //   })
  // }

  setUnit(projectId) {
    // console.log("data",data)
    this.projectLists.forEach(element => {
      if (element.id == projectId) {
        this.prpertyFormGroup.patchValue({ 'property_size': element.property_size });
        this.prpertyFormGroup.patchValue({ 'unit': element.property_size_type });
        this.actualUnit = element.property_size
        // this.units.map(x => {
        //   if (x.id == Number(element.projected_unit)) {
        //     this.prpertyFormGroup.patchValue({ 'unit': x.title });
        //   }
        // })
      }
    });
  }

  // setEditData(){
  //   this.prpertyFormGroup = this.formBuilder.group({
  //     project: [this.data.project,Validators.required],
  //     project_type: [this.data.project_type,Validators.required],
  //     property_size: [this.data.property_size,Validators.required],
  //     property_size_type: [this.data.property_size_type],
  //     projected_unit:[this.data.projected_unit,Validators.required],
  //     projected_area:[this.data.projected_area],
  //     measurement: [this.data.measurement,Validators.required],
  //     purchaseType: [this.data.purchaseType,Validators.required],
  //     property_description: [this.data.property_description,Validators.required],
  //     aggrement_date: [moment(this.data.aggrement_date).format('yyyy-MM-DDThh:mm'),Validators.required],
  //     gata_no: [this.data.gata_no],
  //     khata_no: [this.data.khata_no],
  //     final_amount: [this.data.final_amount,Validators.required],
  //     property_valuation: [this.data.property_valuation],
  //     country_id: [this.data.country_id,Validators.required],
  //     state_id: [this.data.state_id,Validators.required],
  //     city: [this.data.city,Validators.required],
  //     tehshil: [this.data.tehshil,Validators.required],
  //     street: [this.data.street],
  //     town_village: [this.data.town_village],
  //     user_Id: [this.data.user_Id]
  //   });
  //   setTimeout(y=>{this.data.owners.map(x=>{
  //     this.seller().push(this.setSeller(x));
  //   }),1000})
  // }
  // setSeller(seller):FormGroup{
  //     return this.formBuilder.group({
  //       title: [],
  //       seller_name: [seller.seller_name,Validators.required],
  //       mobile_number: [seller.mobile_number,[Validators.required,Validators.pattern(this.numberPattern)]],
  //       pan: [seller.pan,Validators.required],
  //       adhar: [seller.adhar,[Validators.required,Validators.pattern(this.aadharNoPattern)]],
  //       gender: [seller.gender,Validators.required],
  //       profession: [seller.profession],
  //       seller_amount: [seller.seller_amount,Validators.required],
  //       property_size:[seller.property_size,Validators.required],
  //       country_id: [seller.country_id,Validators.required],
  //       state_id: [seller.state_id,Validators.required],
  //       city: [seller.city,Validators.required],
  //       town_village: [seller.town_village,Validators.required],
  //       street: [seller.street],
  //       address: [seller.address,Validators.required],
  //       owner_id:[seller.id],
  //       id:[seller.id]
  //     })

  // }
  setRestLand() {
    // this.prpertyFormGroup.patchValue({'total_covered':total});   
    let totalAvailLand = this.prpertyFormGroup.value.available_land_for_sale;
    this.plotFormGroup.value.plots.forEach((element, index) => {
      totalAvailLand = totalAvailLand - element.total_cover;
      // if(index== i){
      //   debugger
      //   let ew= Number(element.ew);
      //   let ns=Number(element.ns);
      //    area= ew*ns;
      //   let no= Number(element.no_of_property);
      //   if(element.no_of_property && (element.ew || element.ns) ){
      //      calcValue= area*no;
      //   }
      // }
    });
    let availableLand = totalAvailLand
    if (totalAvailLand >= 0) {
      this.plotFormGroup.patchValue({ 'rest_land': availableLand });
    }
    else {
      this.plotFormGroup.patchValue({ 'extra_req_land': (-availableLand) })
      this.plotFormGroup.patchValue({ 'rest_land': 0 });
    }
  }
  plotDetails(): FormArray {
    return this.plotFormGroup.get("plots") as FormArray
  }

  newPlotDetails(): FormGroup {
    return this.formBuilder.group({
      eastw: [''],
      norths: [''],
      total_area: ['', Validators.required],
      total_plot: ['', Validators.required],
      total_cover: ['', Validators.required],
      rate: [''],
      facing: ['']
    })
  }

  get f() { return this.prpertyFormGroup.controls; }
  // onSubmit() {

  //   this.submitted=true;
  //   // if(this.data){
  //   //   setTimeout(y=>{this.data.owners.map(x=>{
  //   //     this.seller().push(this.setSeller(x));
  //   //   }),1000})

  //   // }

  //    //this.addSeller();
  //   // this.response = true;
  //   // this.submitted = true;
  //   // if (this.projectFormGroup.invalid) {
  //   //   return;
  //   // }
  // }

  setStartNo(e) {
    let start_no = this.plotFormGroup.value.prefix_start;
    if (this.avoidedNumbers.length > 0 && this.avoidedNumbers.includes(start_no)) {
      this.plotFormGroup.patchValue({ 'prefix_start': '' });
    }
  }

  setAvoidNo(e) {
    console.log("e", e)
    setTimeout(x => {
      this.avoidedNumbers = this.plotFormGroup.value.avoid_number.split(',');
      let start_no = this.plotFormGroup.value.prefix_start;
      if (this.avoidedNumbers.length > 0 && this.avoidedNumbers.includes(start_no)) {
        this.plotFormGroup.patchValue({ 'prefix_start': '' });
      }
    }, 1000)
  }



  // getCountry() {
  //   this.api.post('list',{"type":"country"}).subscribe(resp => {

  //     this.countries = resp;
  //     if (this.countries.code == 200) {
  //       this.countries = this.countries.data;
  //     }
  //     console.log('datas', this.countries);
  //   })
  // }
  // states: any = [];
  // countryHandler(event) {
  //   this.api.post('list',{"type":"state"}).subscribe(resp => {
  //     this.states = resp;
  //     if (this.states.code == 200) {
  //       this.states = this.states.data;
  //     }
  //     console.log('datas', this.states);
  //   })

  // }
  //   states;
  //   item;
  //   onChange(country_d) {
  //     let newStates = [];
  //     this.states.forEach(function (value) {

  //       if(value.country_id==country_d){
  //         newStates.push(value);
  //       }
  //   });

  //   this.filterStates = newStates;
  //   // this.countries.forEach(element => {
  //   //   if(element.id==country_d){
  //   //   this.item.currency = element.currency;
  //   // }
  //   // });

  // }

  // getCountry() {
  //   this.api.post('list', {type:"country"}).subscribe(
  //     data => {
  //       this.countries = data;
  //     },
  //     err => {
  //       console.error(err);
  //     },
  //     () => console.log('loading completed')
  //   )
  // };  

  // getState() {
  //   this.api.post('list', {type:"state"}).subscribe(
  //     data => {
  //       this.states = data;
  //       if(this.data){
  //         this.onChange(this.data.country_id);
  //       }
  //     },
  //     err => {
  //       console.error(err);
  //     },
  //     () => console.log('loading completed')
  //   )
  // };
  calcTotalArea(e, type, i) {
    let calcValue = -1;
    let area;
    // calcValue=Number(e.data);
    this.plotFormGroup.value.plots.forEach((element, index) => {
      if (index == i) {
        let ew = Number(element.eastw);
        let ns = Number(element.norths);
        area = Number(element.total_area);
        console.log('addArea', area)
        // area = ew * ns;
        let no = Number(element.total_plot);
        if (element.total_plot && area) {
          calcValue = area * no;
        }
      }
    });
    // if (area >= 0) {
    //   this.plotDetails().controls[i].get('total_area').patchValue(area);
    // }
    // else {
    //   this.plotDetails().controls[i].get('total_area').patchValue('');
    // }
    if (calcValue >= 0) {
      console.log("calcValue", calcValue)
      this.plotDetails().controls[i].get('total_cover').patchValue(calcValue);
      this.setRestLand();
    }
    else {
      this.plotDetails().controls[i].get('total_cover').patchValue('');
    }

    // ((this.plotDetails.get('controls') as FormArray).at(index) as FormGroup).get('description').patchValue(item.description);
  }

  addPlotRows(e) {
    this.plotDetails().clear();
    if (Number(e.data) > 0) {
      for (let i = 0; i < Number(e.data); i++) {
        this.plotDetails().push(this.newPlotDetails());
      }
      this.isPropertySizeEntered = true;
    }
    else {
      this.isPropertySizeEntered = false;
    }
  }

  getItems() {
    this.api.post('organization/list', { type: 'field-by-keys', 'user_id': localStorage.getItem('user_id'), keys: ['gender'] }).subscribe(
      data => {
        //  this.genders = data.gender;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  removeSeller(i: number) {
    this.plotDetails().removeAt(i);
  }

  addSeller() {
    this.plotDetails().push(this.newPlotDetails());
    console.log('added');
  }

  selectionChange() {
  }

  // sellerSubmit(seller) {

  //   this.response=true;
  //   this.sellerSubmitted = true;
  //   if (seller.invalid) {
  //     this.response=false;
  //     return;
  //   }

  //   let request = this.prpertyFormGroup.value;
  //   request.property_size_type= request.projected_unit;
  //   request.projected_area= request.property_size;
  //   request.property_status="Agreement";
  //   request.user_id = localStorage.getItem('user_id');
  //   let isEdit= false;
  //   this.response = true;
  //   if(this.data){
  //     request.property_id= this.data.id;
  //     isEdit=true;
  //   }

  //   this.api.addProject(request,isEdit).subscribe(
  //     (data) => {


  //       if (data.code == 200) {
  //           this.prpertyFormGroup.reset();
  //           this.submitted = false;
  //           seller.value.seller.forEach((element,index) => {

  //             let sellerEdit= false;
  //             let sellerRequest= element;
  //             sellerRequest.user_id=localStorage.getItem('user_id');
  //             sellerRequest.property_id= data.result.id;
  //             if(element.id){
  //               sellerEdit=true;
  //             }

  //             if(isEdit && element.id){
  //               sellerRequest.property_id= this.data.id;
  //             }
  //             this.api.addProjectOwner(sellerRequest,sellerEdit).subscribe(
  //               (res)=>{


  //                 //count++;
  //                 if(res.code == 200){
  //                   if((index+1)==this.sellerFormGroup.value.seller.length){
  //                     this.response = false;
  //                     this.sellerFormGroup.reset();
  //                     this.sellerSubmitted=false;
  //                     if(isEdit){
  //                       Swal.fire('Success', 'Updated successfully', 'success');
  //                     }
  //                     else{
  //                       Swal.fire('Success', 'Created successfully', 'success');
  //                     }
  //                     this.router.navigateByUrl('/realstate/projectmanage');
  //                   }

  //                 } 
  //                 else{
  //                   this.response = false;
  //                   this.sellerFormGroup.reset();
  //                   this.sellerSubmitted=false;
  //                 }
  //               }
  //             )
  //            });
  //         // }
  //       }
  //     }, err => {
  //       this.response = false;
  //       Swal.fire('OOps', 'Server error found', 'error');
  //     },()=>{
  //       this.response = false;
  //     })
  // }
  plotSubmitted: boolean = false;
  propertySubmit(plot) {
    this.response = true;
    this.plotSubmitted = true;
    if (plot.invalid) {
      console.log("Invalid");
      this.response = false;
      return;
    }
    console.log('requestBody', plot)
    let request = this.prpertyFormGroup.value;
    request.user_id = localStorage.getItem('user_id')
    request.plots = plot.value.plots;
    request.avoid_number = plot.value.avoid_number;
    request.extra_req_land = plot.value.extra_req_land;
    request.prefix = plot.value.prefix;
    request.prefix_start = plot.value.prefix_start;
    // request.projected_area = plot.value.projected_area;
    // request.projected_unit = plot.value.projected_unit
    request.rest_land = plot.value.rest_land;
    this.response = false;

    console.log('requestBody', request)
    this.api.addProjectPlot(request).subscribe(
      (data) => {
        if (data.code == 200) {
          Swal.fire('Success', 'Property saved successfully!', 'success');
          this.router.navigateByUrl('/realstate/property_evaluation');
        }
        else {
          Swal.fire('OOps', 'Server error found', 'error');
        }
      }, err => {
        this.response = false;
        Swal.fire('OOps', 'Server error found', 'error');
      }, () => {
        this.response = false;
      })
  }
}