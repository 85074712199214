import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../..///api.service';
import { ConfirmationDialogService } from '../../../modal/confirmation-dialog.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-school-list',
  templateUrl: './school-list.component.html',
  styleUrls: ['./school-list.component.css']
})
export class SchoolListComponent implements OnInit {

  items: any = [];
  response;
  type;
  private sub: any;
  constructor(private api: ApiService, private route: ActivatedRoute, private confirmationDialogService: ConfirmationDialogService) {
    // this.type = this.route.snapshot.params['type'];


  }

  ngOnInit() {
    this.getSchool();
    this.api.isLogged()

  }

  getSchool() {
    let item = { id: localStorage.getItem('user_id'), type: 'school' };

    this.api.post('list/school', item).subscribe(
      data => {
        this.items = data;
        this.items = this.items.response;

        console.log('data', this.items)
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  deleteItem(id) {
    this.response = true;

    let item = { id: id, type: 'school' };
    this.api.delete(item).subscribe(
      data => {
        this.response = false;
        this.getSchool();
      }, err => {
        this.response = false;
        console.log(err);
      }
    )

  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
      .then((confirmed) => {
        console.log('User confirmed:', confirmed)
        if (confirmed) {
          this.deleteItem(id);

        }

      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}
