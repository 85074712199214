import { Component, OnInit, ViewEncapsulation,ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {formatDate} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApiService } from 'src/app/api.service';


@Component({
  selector: 'app-out-attendance',
  templateUrl: './out-attendance.component.html',
  styleUrls: ['./out-attendance.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OutAttendanceComponent implements OnInit {
  dropdownList = [];
  selectedItems = [];
  projectSellers=[{id:1,name:"Test1"},{id:1,name:"Test2"}];
  projects=[{id:1,name:"TestProject1"},{id:1,name:"TestProject2"}];
  dropdownSettings :IDropdownSettings= {};
  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  id: number;
  item;
  fieldTypes;
  enquiry_sources;
  statuses;
  visitor_types;
  enquiry_purposes;
  genders;
  product_service;
  selectedService:any=[];;
  deal_priority;
  deal_status;
  deal_source;
  leadservices;
  priorities;
  school_users =[];
  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }
  actionType:string="ADD NEW";
  ngOnInit() {
    this.actionType="ADD NEW";
    this.id = this.route.snapshot.params['id'];
    if(this.id>0){
      this.actionType="Edit";
    }
    this.getItems();
    this.getTeamMember();
    

    this.registerForm = new FormGroup({
      date: new FormControl(formatDate(new Date(), 'dd-MMM-yyyy', 'en')),
      outTime: new FormControl(new Date().toString().split(' ')[4],Validators.required),
      geoLocation:new FormControl(''),
      title: new FormControl('',Validators.required),
      pics:new FormControl(''),
      feeling: new FormControl('')
    });
   
    
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      
    };
  }

  get f() { return this.registerForm.controls; }
 productservice:any=[];
 isServiceValid:boolean=true;
  onSubmit(item: any) {
    this.submitted = true;
    this.isServiceValid=true;
    if (item.invalid) {
      if(item.value.services.length==0){
        this.isServiceValid=false;
      }
      return false;
    }
    if(item.value.services.length==0){
      this.isServiceValid=false;
      return false;
    }
    
    item.value.services.map(x=>{
      this.productservice.push(x.id.toString());
    })
    item.value.services=this.productservice;
    let request = item.value;
    request.type = 'lead';
    request.user_id = localStorage.getItem('user_id');
    request.id = this.id;

    console.log("requst",request)
    this.response = true;
    this.api.addLead(request).subscribe(
      (data) => {
        this.response = false;
        if (data.code == 200) {
          if (this.id) {
            Swal.fire('Success', 'Updated successfully', 'success');
          } else {
            this.registerForm.reset();
            this.submitted = false;
            this.isServiceValid=true;
            Swal.fire('Success', 'Created successfully', 'success');
          }
        }
      }, err => {
        this.response = false;
        Swal.fire('OOps', 'Server error found', 'error');
      },()=>{
        this.response = false;
      }
    )
  }
  @ViewChild("refresh",{static:false}) refresh:ElementRef;
  getItemById(id) {

    this.response = true;
    let send = { type: 'lead', id: id };
    this.api.post('edit', send).subscribe(
      data => {
        this.item = data;
       
        this.item.services.map(x=>{
         
            this.selectedService.push({id:x.service.id,name:x.service.name});
      
        });
        let leadData = {
          source: Number(this.item.source),
          name: this.item.name,
          email: this.item.email,
          mobile: this.item.mobile,
          whatsapp: this.item.whatsapp,
          assign_to_id: this.item.assign_to_id,
          organization: this.item.organization,
          address: this.item.address,
          deal: this.item.deal,
          services: this.selectedService,
          budget:this.item.budget,
          enquiry_date:moment(this.item.enquiry_date).format('yyyy-MM-DDThh:mm'),
      deal_prority: this.item.deal_prority,
      deal_status:this.item.deal_status,
      location: this.item.location,
      note: this.item.note,
      status:this.item.status,
        };
        this.registerForm.setValue(leadData);
      this.refresh.nativeElement.click();
        this.response = false;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }
  onItemSelect(item: any) {
    if(this.submitted==true && this.registerForm.value.services.length==0){
     this.isServiceValid=false;
    }
    else{
      this.isServiceValid=true;
     }
  }
  OnItemDeSelect(item: any) {
    if(this.submitted==true && this.registerForm.value.services.length==0){
      this.isServiceValid=false;
     }
     else{
      this.isServiceValid=true;
     }
  }
  onSelectAll(items: any) {
    if(this.submitted==true && this.registerForm.value.services.length==0){
      this.isServiceValid=false;
     }
     else{
      this.isServiceValid=true;
     }
  }
  onDeSelectAll(items: any) {
    if(this.submitted==true && this.registerForm.value.services.length==0){
      this.isServiceValid=false;
     }
     else{
      this.isServiceValid=true;
     }
  }
  getTeamMember() {
    this.api.post('organization/list',{type:'organizationuser',organization_id:localStorage.getItem('user_id')}).subscribe(
      data => {
        this.school_users = data.response;
        // setTimeout(() => {
        //   // @ts-ignore
        //   $('.selectpicker').selectpicker('refresh');
        // });
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  
  getItems() {
    this.api.post('organization/list', { type: 'field-by-keys', 'user_id':localStorage.getItem('user_id'),keys:['gender','deal_priority','deal_status','deal_source','product_service','enquiry_source','enquiry_status'] }).subscribe(
      data => {
        this.fieldTypes = data;
        this.fieldTypes = this.fieldTypes.response;
        this.visitor_types = this.fieldTypes.visitortype;
        this.enquiry_sources = this.fieldTypes.enquiry_source;
        this.statuses = this.fieldTypes.enquiry_status;
        this.genders = this.fieldTypes.gender;
        this.product_service = this.fieldTypes.product_service;
        this.deal_priority = this.fieldTypes.deal_priority;
        this.deal_status = this.fieldTypes.deal_status;
        this.deal_source = this.fieldTypes.deal_source;
        this.leadservices = this.fieldTypes.service;
        this.priorities = this.fieldTypes.priority;
        this.response = false;
       
        
        if (this.id) {
          this.getItemById(this.id);
        }
        setTimeout(() => {
          // @ts-ignore
          $('.selectpicker').selectpicker('refresh');
        }, 0);
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };







}

