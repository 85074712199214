import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {ApiService} from '../../..///api.service';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../modal/confirmation-dialog.service';



@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.css']
})
export class ModuleListComponent implements OnInit {
  items;
  response = true;


  constructor(private api:ApiService,public router:Router,private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
    this.getLists();
    this.api.isLogged();
  }

  viewItem(item){
    this.router.navigateByUrl('/module/add', { state: item });

  }

  getLists(){
    this.api.getModules().subscribe(
      data=>{
        this.response = false;

        this.items = data;
      },
      err =>{
        this.response = false;

        console.error(err);
      },
      ()=>console.log('loading completed')
    )
  };

  deleteItem(id){
    this.response=true;

    let item = {id:id,type:'module'};
    this.api.delete(item).subscribe(
      data=>{
        this.response=false;
        this.getLists();
      },err=>{
        this.response = false;
        console.log(err);
      }
    )

  };

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);
    }

    })
  }

}
