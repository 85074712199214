import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'

declare var $:any;


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  loader;
  error;
  success: string;
  items: any;
  id: number;
  item;
  fieldTypes;
  removeids = [];

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }


  ngOnInit() {
    this.getType();

    this.registerForm = this.formBuilder.group({
      form_id: this.formBuilder.array([])
    });

  }

  get f() { return this.registerForm.controls; }

  selectAll(isChecked: boolean) {
    const main = this;
    for(const type of this.fieldTypes){
      for(const form of type.forms){
        form.setting = isChecked;
        main.onChange(form.id, isChecked)
      }
    }
  }
  
  anyTypeIsActive(id) {
    for(const ft of this.fieldTypes){
      if(ft.id == id){
        for(const form of ft.forms){
          if(form.setting){
            return true;
          }
        }
      }
    }
    return false;
  }

  onChange(id, isChecked: boolean, type="C") {
    if(type == "P"){
      const MAIN = this;
      for(const ft of this.fieldTypes){
        if(ft.id == id){
          for(const form of ft.forms){
            form.setting = isChecked;
            MAIN.onChange(form.id, isChecked)
          }
        }
      }
      return 0;
    }

    const FormArray = <FormArray>this.registerForm.controls.form_id;
    if(isChecked){
      FormArray.push(new FormControl(id));
    }
    else {
      let index = FormArray.controls.findIndex(x => x.value == id)
      this.removeids.push(id);
      FormArray.removeAt(index);
    }
  }




  onSubmit() {
    let checkedValue=[];
    $("input:checkbox[name=form_id]:checked").each(function(){
        checkedValue.push($(this).val());
    });
    
    let unCheckedValue=[];
    $("input:checkbox[name=form_id]:not(:checked)").each(function(){
      unCheckedValue.push($(this).val());
    });

    this.loader= true;

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
      this.loader= true;
      let request = this.registerForm.value;
    request.removeids = unCheckedValue;
    request.form_id = checkedValue;
    request.type = 'student-enrollment-setting';
    request.school_user_id = localStorage.getItem('org_id');
    request.id = this.id;
    request.removeids = this.removeids;

    this.api.save('add', request).subscribe(
      data => {
        this.loader= false;
        this.error = "";
        Swal.fire('Success', 'Data updated successfully','success');
        this.ngOnInit();
        if (data == 'success') {
        }

      }, err => {
        //this.response = false;
        this.loader= false;
        this.error = "Server error found";
        Swal.fire('Oops', 'error','error');
      },()=>{
        this.loader= false;
      }

    )

  }

  getType() {
    this.api.post('list/get', { type: 'student-enrollment-type',school_user_id:localStorage.getItem('org_id') }).subscribe(
      data => {
        this.response = false;
        this.fieldTypes = data;
        this.fieldTypes = this.fieldTypes.response;
      },
      err => {
        this.response = false;
      },
      () => {
        this.response = false;
        console.log('loading completed')
      }
    )
  };

}
