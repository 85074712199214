import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'

declare var $: any;

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  item;
  noStudents = [1];
  total_section=0;
  categories;
  datas;
  subjects: any = [];
  batches: any = [];
  id: number;
  tot:number = 0;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.noStudents = [1];
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      batch_id: ['', Validators.required],
      medium: ['', Validators.required],
      total_section: ['', Validators.required],
      subjects: ['', Validators.required],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      tsection: this.formBuilder.array([])
    });

    this.getBatch();
    this.getSubjectByCategory();
    this.id = this.route.snapshot.params['id'];


  }

  get phoneForms() {
    return this.registerForm.get('tsection') as FormArray
  }



  getSubjectByCategory() {
    this.api.post('list/category', { type: 'category', id: localStorage.getItem('org_id') }).subscribe(
      data => {
        this.response = false;
        this.datas = data;
        this.categories = this.datas.response;
        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        }, 200);
        if(this.id){
          this.getItemById(this.id);
        }

      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };



  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {


    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.response = true;

    let request = this.registerForm.value;
    request.type = 'class';
    request.id = this.id;
    request.school_user_id = localStorage.getItem('org_id');
    this.api.save('add', request).subscribe(
      data => {
        if (data.id != undefined) {
          if(this.id){
            Swal.fire('Success', 'Updated successfully', 'success');
          }else{
            this.submitted = false;
            this.registerForm.reset();
            this.ngOnInit();
            Swal.fire('Success', 'Created successfully', 'success');
          }
        }   
      }, err => {
        this.response = false;
        this.error = "Server error found";
        Swal.fire('Success', this.error, 'warning');

      }, ()=>{
        this.response = false;
      }

    )

  }
  getBatch() {
    this.api.post('list/batch', { type: 'school-batch', id: localStorage.getItem('org_id') }).subscribe(
      data => {
        this.response = false;
        this.batches = data;
        this.batches = this.batches.response;
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };



  getItemById(id) {
    this.response = true;
    let send = { type: 'class', id: id };
    this.api.get(send).subscribe(
      data => {
        this.item = data;
        this.registerForm = this.formBuilder.group({
          name: [this.item.class.name, Validators.required],
          batch_id: [this.item.class.batch_id, Validators.required],
          medium: [this.item.class.medium, Validators.required],
          total_section: [this.item.class.total_section, Validators.required],
          subjects: [this.item.subjects.map(String), Validators.required],
          start_date: [this.item.class.program_start_date, Validators.required],
          end_date: [this.item.class.program_start_date, Validators.required],
          tsection: this.formBuilder.array([])

        });

      //   for(let i=0;i<this.item.class.total_section;i++){
      //   var phone = this.formBuilder.group({ 
      //     sections: [this.item && this.item.class.sections[i] ? this.item.class.sections[i].section : '', Validators.required],
      //     students: [this.item && this.item.class.sections[i] ? this.item.class.sections[i].student : 0, Validators.required],
      //   })
      //   this.phoneForms.push(phone);
      // }

        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        }, 200);
        this.response = false;
      },
      err => {
        console.error(err);
      },
      () => this.response = false
    )

  }

  addNoStudent(){

    
    var tot = this.item ? this.item.class.total_section : this.total_section;
    var phone = this.formBuilder.group({ 
          sections: ['', Validators.required],
          students: ['', Validators.required],
        })
        this.phoneForms.push(phone);
        tot++;

        this.total_section = tot;
        console.log("this.total_section=",this.total_section);

  }


  substractNoStudent(i){
    //if(this.total_section>0){
    this.total_section--;
    this.phoneForms.removeAt(i);
    let name = $("#sections_"+i).val();
    this.deleteSectionItem(name)
    // debugger;

  //}

  }

  deleteSectionItem(name){
    this.response=true;
    let item = {name:name,type:'school-class-section',school_user_id:localStorage.getItem('org_id')};
    this.api.delete(item).subscribe(
      data=>{
        this.response=false;
      },err=>{
        this.response = false;
        console.log(err);
      }
    )

  };


}
