import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-penalty',
  templateUrl: './add-penalty.component.html',
  styleUrls: ['./add-penalty.component.css']
})
export class AddPenaltyComponent implements OnInit {
  [x: string]: Object;
  penaltyForm: FormGroup;
  submitted = false;
  response = false;
  error = '';
  item;
  penalty_id;
  condictions = [
    {
      name: 'On Booking Amt',
      value: 'BOOKING_AMOUNT'

    },

    {
      name: 'On EMI Payment',
      value: 'EMI_PAYMENT'

    },

    {
      name: 'On Cancel booking',
      value: 'CANCEL_BOOKING'

    },

    {
      name: 'Other Charges',
      value: 'OTHER_CHARGES'

    }

  ]


  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {



  }

  ngOnInit() {

    this.penalty_id = this.route.snapshot.paramMap.get('penaltyid')

    console.log('id=====', this.penalty_id);

    if (this.penalty_id) {
      this.getPenaltyById();
    }



    this.penaltyForm = this.formBuilder.group({
      penalty_condition: ['', Validators.required],
      penalty_head: ['', Validators.required],
      value: ['', Validators.required],
      type: ['', Validators.required],
      // basis: ['', Validators.required]
    })
  }


  get p() { return this.penaltyForm.controls }

  onSubmit() {

    this.submitted = true;

    if (this.penaltyForm.invalid) {
      return;
    }

    let request = this.penaltyForm.value;
    // request.school_user_id = localStorage.getItem('school_user_id');
    request.school_user_id = 1;


    this.api.post('add-penalty', request).subscribe(
      data => {
        if (data.code == 200) {
          this.submitted = false;
          this.penaltyForm.reset();
          Swal.fire('Success', 'Created successfully', 'success');
        } else {
          Swal.fire('Error', "Somethibg is wrong", 'warning');

        }
      }, err => {
        this.response = false;
        this.error = "Server error found";
        Swal.fire('Error', this.error, 'warning');

      }, () => {
        this.response = false;
      }

    )

  }



  getPenaltyById() {

    this.api.getItem('get-penalty/' + this.penalty_id).subscribe(

      data => {
        this.item = data;
        this.item = this.item.result;

        this.penaltyForm = this.formBuilder.group({
          penalty_condition: [this.item.penalty_condition, Validators.required],
          penalty_head: [this.item.penalty_head, Validators.required],
          value: [this.item.value, Validators.required],
          type: [this.item.type, Validators.required],
          // basis: [this.item.basis, Validators.required]
        })

        console.log('getting data by id', this.item)
      }

    )

  }

  onUpdate() {

    this.submitted = true;

    if (this.penaltyForm.invalid) {
      return;
    }

    let request = this.penaltyForm.value;
    // request.school_user_id = localStorage.getItem('school_user_id');
    request.school_user_id = 1;
    request.penalty_id = this.penalty_id;



    this.api.post('update-penalty', request).subscribe(
      data => {
        if (data.code == 200) {
          if (this.penalty_id) {
            Swal.fire('Success', 'Updated successfully', 'success');

          }
        }

      }, err => {
        this.response = false;
        this.error = "Server error found";
        Swal.fire('Error', this.error, 'warning');

      }, () => {
        this.response = false;
      }

    )

  }


}



