import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-customer-comission',
  templateUrl: './customer-comission.component.html',
  styleUrls: ['./customer-comission.component.css']
})
export class CustomerComissionComponent implements OnInit {

  items;
  response = true;
  records;
  c_id;

  constructor(private api: ApiService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getLists();

    console.log("sss=", this.route.snapshot.queryParams.c_id);
    this.c_id = this.route.snapshot.queryParams.c_id;
  }


  getLists() {
    this.api.getItem('customer/members?c_id=' + this.route.snapshot.queryParams.c_id).subscribe(
      data => {
        this.response = false;
        this.items = data;
        this.records = Object.values(this.items.records)
        console.log("records", this.records);
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };

}
