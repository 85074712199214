import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharingServiceService {

  constructor() { }
  private data:any = undefined;
  private fromTitle:any="Create New Project"

  setData(data:any){
      this.data = data;
  }

  getData():any{
      return this.data;
  }

  formTitleSet(title:any){
    this.fromTitle=title;
  }
  formTitleGet():any{
    return this.fromTitle;
  }
}
