import { Component, OnInit } from '@angular/core';
import { ApiService} from '../../..///api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../modal/confirmation-dialog.service';


@Component({
  selector: 'app-session-list',
  templateUrl: './session-list.component.html',
  styleUrls: ['./session-list.component.css']
})
export class SessionListComponent implements OnInit {
  items;
  response = true;
  pageOfItems: Array<any>;


  constructor(private api:ApiService,public router:Router,private confirmationDialogService: ConfirmationDialogService) {





   }

  ngOnInit() {
    this.getLists();
    this.api.isLogged();
  }
  

  viewItem(item){
    // this.router.navigateByUrl('/session/add', { state: item });

  }

  getLists(){
    this.api.post('list/session',{type:'school-session',school_user_id: localStorage.getItem('school_user_id')}).subscribe(
      data=>{
        this.response = false;
        this.items = data;
        this.items = this.items.response;
      },
      err =>{
        this.response = false;

        console.error(err);
      },
      ()=>console.log('loading completed')
    )
  };

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }


  deleteItem(id){
    this.response=true;

    let item = {id:id,type:'session'};
    this.api.delete(item).subscribe(
      data=>{
        this.response=false;
        this.pageOfItems.splice(this.pageOfItems.findIndex(function(i){
          return i.id == id;
      }), 1);
      },err=>{
        this.response = false;
        console.log(err);
      }
    )

  };

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);
    }

    })
  }

}
