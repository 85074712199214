import { Component, OnInit } from '@angular/core';
import { ApiService} from '../../../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../../modal/confirmation-dialog.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  items;
  response = true;
  pageOfItems: Array<any>;
  hideme=[];

  constructor(private api:ApiService,public router:Router,private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
    this.getLists();
    this.api.isLogged();
  }

  viewItem(item){
    // this.router.navigateByUrl('/session/add', { state: item });

  }

  getLists(){
    this.api.post('list/class',{type:'school-class',id:localStorage.getItem('org_id')}).subscribe(
      data=>{
        this.response = false;
        this.items = data;
        this.items = this.items.response;
      },
      err =>{
        this.response = false;
      },
      ()=>console.log('loading completed')
    )
  };

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }


  deleteItem(id, type){
    this.response=true;
    let item = {id:id,type};
    this.api.delete(item).subscribe(
      data=>{
        this.response=false;
        this.getLists();
      },err=>{
        this.response = false;
        console.log(err);
      }
    )

  };
  
  public openConfirmationDialog(id, type) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id, type);
    }

    })
  }
}
