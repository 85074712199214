import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.css']
})
export class ChangeComponent implements OnInit {
  @ViewChild('myform', { static: false }) myform: NgForm;
  
  id:number;
  items:any;
  reqSuccess;
  error;
  response = false;
  registerForm: FormGroup;
  submitted = false;
  status:any;
  reqResponse;
  reqResponseError;
  constructor(private api:ApiService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.items = {
      old_password:"",
      password:"",
      password_confirmation:""
    }
  }

  
  submitItem(request){
    
    this.reqResponseError = false;
    this.reqSuccess = false;
    this.response = true;
    this.submitted = true;

    request.user_id = localStorage.getItem('user_id');
    this.api.add('change-password',request).subscribe(
      data=>{
        this.reqResponse = data;
        this.response =false;
        if(this.reqResponse.status=='success'){
          this.reqSuccess = "Password change successfully";
          this.submitted = false;
          this.myform.resetForm();
          this.ngOnInit();
        }else{
          console.log(this.reqResponse);
          this.reqResponseError = this.reqResponse.message;
        }
      },
      err =>{
        this.response = false;
        if(err.status == 422){
          console.log(err);
          this.reqResponseError = err.error.errors.password[0];
        }else{
          this.reqResponseError = err.statusText;
          
        }
      },
      ()=>console.log('loading completed')
    )
  }

}
