import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../modal/confirmation-dialog.service';
import { NgbModal, ModalDismissReasons, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import autoTable from 'jspdf-autotable'
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  @ViewChild('dp', { static: false }) dp: NgbDatepicker;
  @ViewChild('content', { static: false }) content: ElementRef;
  response = true;
  pageOfItems: Array<any>;
  closeResult = '';
  registerForm: FormGroup;
  searchForm: FormGroup;
  submitted = false;
  error;
  success: string;
  dropdownSettings: IDropdownSettings = {};
  items: any;
  id: number;
  parentProperty;
  enquiry_id: number;
  hideme = [];
  selectAllRec = false;
  selectAllData = false;
  hideFilter = false;
  totalCount = 0;
  p: number = 1;
  school_users = [];
  SEARCH_TIMER: any = '';
  user: any = {
    "type": ''
  };
  pdfAndExcelItems: any;
  leadPriority: number = 0
  productService: any = [];
  leadSource: number = 0
  assignUsers: number = 0
  fromDate: any
  toDate: any
  isAdditionalFilter: boolean = false;
  status: any
  onePageItem: number = 10;
  @ViewChild("refresh", { static: false }) refresh: ElementRef;
  page = {
    limit: 10,
    count: 0,
    offset: 0,
  };
  public pageLimitOptions = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];
  constructor(private formBuilder: FormBuilder, private api: ApiService, public router: Router, private confirmationDialogService: ConfirmationDialogService, private modalService: NgbModal) { }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      services: [''],
      user: [''],

      To: [null],
      From: [null],
      priority: [''],
      source: ['']

    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.getPermission();
    this.api.isLogged();

    this.registerForm = this.formBuilder.group({
      remark: ['', Validators.required],
      comment: ['', Validators.required],
      follow_up_date: ['', Validators.required],
    });
    this.getTeamMember();
    this.getItems();
  }

  get f() {
    return this.searchForm.controls;
  }

  getTeamMember() {
    this.api.post('organization/list', { type: 'organizationuser', organization_id: localStorage.getItem('user_id') }).subscribe(
      data => {
        this.school_users = data.response;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  getPermission() {
    let data = { type: 'user', id: localStorage.getItem('user_id') }
    this.api.post('organization/list', data).subscribe(
      (result) => {
        this.user = result.response;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  }
  perPageItems = 10;
  data: [];
  getLists(extra = {}) {
    var filter = { type: 'lead', id: localStorage.getItem('user_id') };
    filter = { ...filter, ...extra };
    this.response = true;

    this.api.post('organization/list', filter).subscribe(
      data => {
        this.response = false;
        this.p = extra["page_number"];
        this.totalCount = data.count;
        data.response.forEach(element => {
          //this.status=element.status
          this.deal_priority.map(x => {
            if (x.id == Number(element.deal_prority)) {
              element.priority = x.name;
            }
          })
        });
        data.response.forEach(element => {
          this.school_users.map(x => {
            if (x.id == Number(element.assign_to_id)) {
              element.assign_to_name = x.user_name;
            }
          })
        });
        this.items = data.response;
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };

  onChangePage(pageOfItems) {
    let limit;
    if (this.onePageItem) {
      console.log('100', this.onePageItem)
      limit = this.onePageItem;
      this.perPageItems = this.onePageItem;
    }
    else {
      this.onePageItem = 0;
      limit = 10;
    }
    console.log('Limit', limit)
    this.getLists({
      "search": this.searchValue,
      "page_number": pageOfItems,
      "limit": limit,
      "isAdditionalFilter": this.isAdditionalFilter,
      "toDate": this.toDate,
      "fromDate": this.fromDate,
      "leadPriority": this.leadPriority,
      "productService": this.productService,
      "leadSource": this.leadSource,
      "assignUser": this.assignUsers,
      "status": this.filterBy
    });
  }

  filterBy = "Fresh Lead";
  filterLeadChange(value) {
    let limit;
    if (this.onePageItem) {
      console.log('100', this.onePageItem)
      limit = this.onePageItem;
      this.perPageItems = this.onePageItem;
    }
    else {
      this.onePageItem = 0;
      this.perPageItems = 10;
      limit = 10;
    }
    this.filterBy = value;
    if (value != '') {
      this.getLists({
        "search": this.searchValue,
        "status": value,
        "page_number": 1,
        "limit": limit,
        "isAdditionalFilter": this.isAdditionalFilter,
        "toDate": this.toDate,
        "fromDate": this.fromDate,
        "leadPriority": this.leadPriority,
        "productService": this.productService,
        "leadSource": this.leadSource,
        "assignUser": this.assignUsers
      });
    } else {
      this.getLists({
        "search": this.searchValue, "page_number": 1,
        "limit": limit,
        "isAdditionalFilter": this.isAdditionalFilter,
        "toDate": this.toDate,
        "fromDate": this.fromDate,
        "leadPriority": this.leadPriority,
        "productService": this.productService,
        "leadSource": this.leadSource,
        "assignUser": this.assignUsers,
        "status": this.filterBy
      });
    }
  }
  searchValue = "";
  filterSearch(value) {
    if (this.SEARCH_TIMER != '') {
      clearTimeout(this.SEARCH_TIMER);
    }
    let limit;
    if (this.onePageItem) {
      console.log('100', this.onePageItem)
      limit = this.onePageItem;
      this.perPageItems = this.onePageItem;
    }
    else {
      this.onePageItem = 0;
      this.perPageItems = 10;
      limit = 10;
    }
    this.SEARCH_TIMER = setTimeout(() => {
      if (value != '') {
        this.searchValue = value;
        this.getLists({
          "search": this.searchValue,
          "page_number": 1,
          "limit": limit,
          "isAdditionalFilter": this.isAdditionalFilter,
          "toDate": this.toDate,
          "fromDate": this.fromDate,
          "leadPriority": this.leadPriority,
          "productService": this.productService,
          "leadSource": this.leadSource,
          "assignUser": this.assignUsers,
          "status": this.filterBy

        });
      } else {
        this.searchValue = "";
        this.getLists({
          "status": this.filterBy,
          "search": this.searchValue,
          "page_number": 1,
          "limit": limit
        });
      }
    }, 1000)
  }

  selectAll(value) {
    if (value) {
      this.assignLead = true;
      this.checkedGridItems = this.items;
    }
    else {
      this.assignLead = false;
      this.checkedGridItems = [];
    }
    this.selectAllRec = value;
  }
  showHideFilter() {
    this.hideFilter = !this.hideFilter;
  }

  deleteItem(id) {
    this.response = true;
    let item = { id: id, type: 'lead' };

    this.api.delete('organization/delete', item).subscribe(
      data => {
        if (data == "success") {
          this.ngOnInit()
          this.response = false;
        }

        //   this.pageOfItems.splice(this.pageOfItems.findIndex(function(i){
        //     return i.id == id;
        // }), 1);

      }, err => {
        this.response = false;
        console.log(err);
      }
    )

  };

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
      .then((confirmed) => {
        console.log('User confirmed:', confirmed)
        if (confirmed) {
          this.deleteItem(id);
        }
      })
  }

  public openFollowUpDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
      .then((confirmed) => {
        console.log('User confirmed:', confirmed)
        if (confirmed) {
          this.deleteItem(id);
        }

      })
  }

  fieldTypes;
  product_service;
  deal_priority;
  deal_source;
  getItems() {
    this.api.post('organization/list', { type: 'field-by-keys', 'user_id': localStorage.getItem('user_id'), keys: ['gender', 'deal_priority', 'deal_status', 'deal_source', 'product_service', 'enquiry_source', 'enquiry_status'] }).subscribe(
      data => {
        this.fieldTypes = data.response;
        this.product_service = this.fieldTypes.product_service;
        this.deal_priority = this.fieldTypes.deal_priority;
        this.deal_source = this.fieldTypes.deal_source;
        this.getLists({
          "status": this.filterBy,
          "search": this.searchValue,
          "page_number": 1,
          "limit": 10
        });
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };
  open(content, id) {
    this.parentProperty = id;
    this.enquiry_id = id;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  onLimitChange(value) {

    this.page.limit = value;
    this.onePageItem = value;
    this.getLists({ "page_number": 1, "limit": value, "status": this.filterBy });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }




  onFileChanged(event) {
    var request = {
      user_id: localStorage.getItem('user_id')
    };
    const requestData = new FormData();
    var postData = JSON.stringify(request);

    requestData.append('file', event.target.files[0], event.target.files[0].name);
    $('#lead_xlsx').val('');
    requestData.append("postData", postData);
    this.api.addLeadExcel(requestData).subscribe(
      data => {
        this.ngOnInit();
        Swal.fire('Success', 'File uploaded successfully', 'success');
      },
      err => {
        this.response = false;
        Swal.fire('error', 'Something went wrong', 'error');
      },
      () => {
        this.response = false;
      }
    )
  }
  isFilter: boolean = true;
  onSubmit(actionType) {
    this.productService = [];
    this.isFilter = !this.isFilter;
    if (actionType == "filter") {
      var datePipe = new DatePipe('en-US');
      let additionalFilter = this.searchForm.value;
      if (additionalFilter.services) {
        additionalFilter.services.map(x => {
          this.productService.push(x.id);
        })
      }

      this.isAdditionalFilter = true;
      if (additionalFilter.To) {
        this.toDate = datePipe.transform(new Date(additionalFilter.To["year"], additionalFilter.To["month"] - 1, additionalFilter.To["day"]), 'yyyy-MM-dd')
      }
      if (additionalFilter.From) {
        this.fromDate = datePipe.transform(new Date(additionalFilter.From["year"], additionalFilter.From["month"] - 1, additionalFilter.From["day"]), 'yyyy-MM-dd');

      }
      this.leadPriority = Number(additionalFilter.priority);
      this.leadSource = Number(additionalFilter.source);
      this.assignUsers = Number(additionalFilter.user);
      console.log("this.fromDate", this.fromDate)
      console.log("this.toDate", this.toDate)
      let limit;
      if (this.onePageItem) {
        limit = this.onePageItem;
        this.perPageItems = this.onePageItem;
      }
      else {
        this.onePageItem = 0;
        limit = 10;
      }
      this.getLists({
        "search": this.searchValue,
        "page_number": 1,
        "limit": limit,
        "isAdditionalFilter": this.isAdditionalFilter,
        "toDate": this.toDate,
        "fromDate": this.fromDate,
        "leadPriority": this.leadPriority,
        "productService": this.productService,
        "leadSource": this.leadSource,
        "assignUser": this.assignUsers,
        "status": this.filterBy
      });

    }
    else if (actionType == "refresh") {
      this.isAdditionalFilter = false;
      this.ngOnInit();
    }
  }
  assignLead: boolean = false;
  leadIds: any = [];
  assignUser() {
    this.leadIds = [];
    if (this.checkedGridItems.length > 0 && this.userId) {
      this.checkedGridItems.map(x => {
        this.leadIds.push(x.id);
      })
      let request = {
        "userId": this.userId,
        "lead_id": this.leadIds
      }
      request = { ...request };
      this.response = true;
      this.api.assignLead(request).subscribe(
        (data) => {
          this.response = false;
          this.assignLead = false;
          this.getLists({
            "status": this.filterBy,
            "page_number": 1,
            "limit": 10
          });
          if (data.code == 200) {
            Swal.fire('Success', 'Assigned successfully', 'success');
            this.selectAllRec = false;
            this.selectAllData = false;
            this.refresh.nativeElement.click();
            this.ngOnInit()
          }
          this.checkedGridItems = [];
          this.userId = "";
          this.selectAllRec = false;
          this.selectAllData = false;

        }, err => {
          console.log(err);
          this.response = false;
          this.assignLead = false;
          this.checkedGridItems = [];
          this.userId = "";
          this.selectAllRec = false;
          this.selectAllData = false;
          Swal.fire('OOps', 'Server error found', 'error');

        }, () => {
          this.response = false;
        }
      )
    }
  }
  userId: string = "";
  onUserChange(user) {
    this.userId = user;
  }
  checkedGridItems: any = [];
  checkValue(gridItem, isChecked) {

    if (isChecked) {
      this.checkedGridItems.push(gridItem);
      this.assignLead = true
    }
    else {
      const index = this.checkedGridItems.indexOf(gridItem);
      this.assignLead = false
      if (index > -1) {
        this.checkedGridItems.splice(index, 1);
      }
    }
  }

  ExportFile(exportType) {
    this.response = true;
    this.getDataForPdfAndExcel({
      "search": this.searchValue,
      "page_number": 1,
      "limit": 10000,
      "isAdditionalFilter": this.isAdditionalFilter,
      "toDate": this.toDate,
      "fromDate": this.fromDate,
      "leadPriority": this.leadPriority,
      "productService": this.productService,
      "leadSource": this.leadSource,
      "assignUser": this.assignUsers,
      "status": this.filterBy
    }, exportType);
  }
  getDataForPdfAndExcel(extra = {}, exportType) {
    var filter = { type: 'lead', id: localStorage.getItem('user_id') };
    filter = { ...filter, ...extra };
    this.api.post('organization/list', filter).subscribe(
      data => {
        data.response.forEach(element => {
          this.deal_priority.map(x => {
            if (x.id == Number(element.deal_prority)) {
              element.priority = x.name;
            }
          })

        });
        data.response.forEach(element => {
          this.school_users.map(x => {
            if (x.id == Number(element.assign_to_id)) {
              element.assign_to_name = x.user_name;
            }
          })

        });

        this.pdfAndExcelItems = data.response;

        if (exportType == "pdf") {

          setTimeout(() => {
            this.SavePDF();
            this.response = false;
          }, 10000)

        }
        else if (exportType == "excel") {
          setTimeout(() => {
            this.ExportTOExcel();
            this.response = false;
          }, 10000)

        }

      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };
  public SavePDF(): void {
    let doc = new jsPDF();
    autoTable(doc, { html: '#leadData' })
    doc.save('ManageLeads.pdf');
  }
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.content.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ManageLeads.xlsx');
  }
}
