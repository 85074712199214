import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DataTableModule } from 'ng-angular8-datatable';
import { ArchwizardModule } from 'angular-archwizard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { JwPaginationComponent } from 'jw-angular-pagination';
import { ConfirmationDialogService } from './modal/confirmation-dialog.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgBootstrapDatetimeAngularModule } from "ng-bootstrap-datetime-angular";
import { Select2Module } from 'ng2-select2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './page/login/login.component';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { HeaderComponent } from './page/header/header.component';
import { ListComponent } from './page/sms-plan/list/list.component';
import { AddComponent } from './page/sms-plan/add/add.component';
import { SmsRequestComponent } from './page/sms-request/sms-request.component';
import { SmsReportComponent } from './page/sms-report/sms-report.component';
import { SchoolListComponent } from './page/school/school-list/school-list.component';
import { SchoolAddComponent } from './page/school/school-add/school-add.component';
import { StateAddComponent } from './page/state/state-add/state-add.component';
import { StateListComponent } from './page/state/state-list/state-list.component';
import { ModuleListComponent } from './page/module/module-list/module-list.component';
import { ModuleAddComponent } from './page/module/module-add/module-add.component';
import { ModuleEditComponent } from './page/module/module-edit/module-edit.component';
import { SchoolEditComponent } from './page/school/school-edit/school-edit.component';
import { ConfirmationDialogComponent } from './modal/confirmation-dialog/confirmation-dialog.component';
import { UserAddComponent } from './page/user/user-add/user-add.component';
import { UserListComponent } from './page/user/user-list/user-list.component';
import { SessionAddComponent } from './page/school/session-add/session-add.component';
import { SessionListComponent } from './page/school/session-list/session-list.component';
import { AddComponent as BatchAdd } from './page/school/batch/add/add.component';
import { ListComponent as BatchList } from './page/school/batch/list/list.component';
import { AddComponent as SubjectAdd } from './page/school/subject/add/add.component';
import { ListComponent as SubjectList } from './page/school/subject/list/list.component';
import { AddComponent as CategoryAdd } from './page/school/category/add/add.component';
import { ListComponent as CategoryList } from './page/school/category/list/list.component';
import { FormDialogComponent } from './modal/form-dialog/form-dialog.component';
import { AddComponent as ClassAdd } from './page/school/class/add/add.component';
import { ListComponent as ClassList } from './page/school/class/list/list.component';
import { AddComponent as FeeAdd } from './page/school/fee/add/add.component';
import { ListComponent as FeeList } from './page/school/fee/list/list.component';
import { AddComponent as FeeAssignAdd } from './page/school/feeassign/add/add.component';
import { ListComponent as FeeAssignList } from './page/school/feeassign/list/list.component';
import { AddComponent as FineAdd } from './page/school/fine/add/add.component';
import { ListComponent as FineList } from './page/school/fine/list/list.component';
import { AddComponent as EnrollmentAdd } from './page/student/enrollment/add/add.component';
import { ListComponent as EnrollmentList } from './page/student/enrollment/list/list.component';
import { AddComponent as EnrollmentSettingAdd } from './page/student/enrollment-setting/add/add.component';
import { AddComponent as FieldAdd } from './page/field/add/add.component';
import { ListComponent as FieldList } from './page/field/list/list.component';
import { AddComponent as AssignDesignationAdd } from './page/hr/assign-designation/add/add.component';
import { ListComponent as AssignDesignationList } from './page/hr/assign-designation/list/list.component';
import { AddComponent as MasterAdd } from './page/hr/master/add/add.component';
import { ListComponent as MasterList } from './page/hr/master/list/list.component';
import { AddComponent as GridAdd } from './page/hr/grid/add/add.component';
import { ListComponent as GridList } from './page/hr/grid/list/list.component';
import { AddComponent as EmployeeAdd } from './page/employee/add/add.component';
import { ListComponent as EmployeeList } from './page/employee/list/list.component';
import { ListComponent as EmployeeSetting } from './page/employee/setting/list/list.component';
import { FileUploadComponent } from './page/hr/file-upload/file-upload.component';
import { FileUploadComponent as EmployeeFileUpload } from './page/hr/file-upload/file-upload.component';
import { AddComponent as StudentFileUpload } from './page/student/file-upload/add/add.component';
import { ListComponent as AssignClassTeacherList } from './page/hr/class-teacher/list/list.component';
import { AddComponent as AssignClassTeacherAdd } from './page/hr/class-teacher/add/add.component';
import { ListComponent as AssignClassSubjectList } from './page/hr/class-subject/list/list.component';
import { AddComponent as AssignClassSubjectAdd } from './page/hr/class-subject/add/add.component';
import { ListComponent as LeadList } from './page/lead/list/list.component';
import { AddComponent as LeadAdd } from './page/lead/add/add.component';
import { EnquiryLogComponent } from './page/lead/enquiry-log/enquiry-log.component';
import { ListComponent as PartyList } from './page/party/master/list/list.component';
import { AddComponent as PartyAdd } from './page/party/master/add/add.component';
import { ListComponent as TransportList } from './page/party/transport/list/list.component';
import { AddComponent as TransportAdd } from './page/party/transport/add/add.component';
import { ListComponent as PickDropList } from './page/party/pickup-drop/list/list.component';
import { AddComponent as PickDropAdd } from './page/party/pickup-drop/add/add.component';
import { ListComponent as AssignPickDropList } from './page/party/assign-pick-drop/list/list.component';
import { AddComponent as AssignPickDropAdd } from './page/party/assign-pick-drop/add/add.component';
import { ListComponent as FeeCollectionList } from './page/fee/collection/list/list.component';
import { AddComponent as FeeCollectionAdd } from './page/fee/collection/add/add.component';
import { CustomPipePipe } from './pipe/custom-pipe.pipe';
import { CollectionsComponent } from './page/fee/collections/collections.component';
import { ListComponent as RegistrationList } from './page/student/registration/list/list.component';
import { AddComponent as RegistrationAdd } from './page/student/registration/add/add.component';
import { ChangeComponent } from './page/password/change/change.component';
import { ResetComponent } from './page/password/reset/reset.component';
import { NewFeeComponent } from './page/school/new-fee/new-fee.component';
import { NewFeeListComponent } from './page/school/new-fee-list/new-fee-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { AddPenaltyComponent } from './page/realstate/penalty/add-penalty/add-penalty.component';
import { AddchargesComponent } from './page/realstate/charges/addcharges/addcharges.component';
import { ListChargesComponent } from './page/realstate/list-charges/list-charges.component';
import { ListPenaltyComponent } from './page/realstate/list-penalty/list-penalty.component';
import { AddBookingComponent } from './page/realstate/booking/add/add.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ListBookingComponent } from './page/realstate/booking/list-booking/list-booking.component';
import { ProjectManageComponent } from './page/realstate/project-manage/project-manage.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddProjectManageComponent } from './page/realstate/add-project-manage/add-project-manage.component';
import { RealstateModule } from './page/realstate/realstate/realstate.module';
// import { ProjectPaymentListComponent } from './page/realstate/project-payment/project-payment-list/project-payment-list.component';
import { LeadModule } from './page/lead/lead.module';
import { CustomerPaymentReportComponent } from './page/realstate/realstate/customer-payment-report/customer-payment-report.component';
import { CustomerComissionComponent } from './page/realstate/customer-comission/customer-comission.component';
import { EditBookingComponent } from './page/realstate/booking/edit-booking/edit-booking.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    ListComponent,
    AddComponent,
    SmsRequestComponent,
    SmsReportComponent,
    SchoolAddComponent,
    SchoolListComponent,
    StateAddComponent,
    StateListComponent,
    ModuleListComponent,
    ModuleAddComponent,
    ModuleEditComponent,
    SchoolEditComponent,
    JwPaginationComponent,
    ConfirmationDialogComponent,
    UserAddComponent,
    UserListComponent,
    SessionAddComponent,
    SessionListComponent,
    BatchAdd,
    BatchList,
    SubjectAdd,
    SubjectList,
    CategoryAdd,
    CategoryList,
    FormDialogComponent,
    ClassAdd,
    ClassList,
    FeeAdd,
    FeeList,
    FeeAssignAdd,
    FeeAssignList,
    FineAdd,
    FineList,
    EnrollmentAdd,
    EnrollmentList,
    EnrollmentSettingAdd,
    FieldAdd,
    FieldList,
    AssignDesignationAdd,
    AssignDesignationList,
    MasterAdd,
    MasterList,
    GridAdd,
    GridList,
    EmployeeList,
    EmployeeAdd,
    EmployeeSetting,
    FileUploadComponent,
    EmployeeFileUpload,
    StudentFileUpload,
    AssignClassTeacherList,
    AssignClassTeacherAdd,
    AssignClassSubjectList,
    AssignClassSubjectAdd,
    LeadAdd,
    LeadList,
    EnquiryLogComponent,
    PartyAdd,
    PartyList,
    TransportList,
    TransportAdd,
    PickDropList,
    PickDropAdd,
    AssignPickDropAdd,
    AssignPickDropList,
    FeeCollectionList,
    FeeCollectionAdd,
    CustomPipePipe,
    CollectionsComponent,
    RegistrationList,
    RegistrationAdd,
    ChangeComponent,
    ResetComponent,
    NewFeeComponent,
    NewFeeListComponent,
    AddBookingComponent,
    AddPenaltyComponent,
    AddchargesComponent,
    ListChargesComponent,
    ListPenaltyComponent,
    ListBookingComponent,
    ProjectManageComponent,
    AddProjectManageComponent,
    CustomerPaymentReportComponent,
    CustomerComissionComponent,
    EditBookingComponent,
    // ProjectPaymentListComponent
  ],
  imports: [
    BrowserModule,
    Select2Module,
    AppRoutingModule,
    DataTableModule,
    ArchwizardModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    FilterPipeModule,
    FilterPipeModule,
    MatStepperModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    NgxPaginationModule,
    NgBootstrapDatetimeAngularModule,
    NgxDatatableModule,
    RealstateModule,
    LeadModule
  ],

  providers: [ConfirmationDialogService],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationDialogComponent, FormDialogComponent]
})
export class AppModule { }
