import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadRoutingModule } from './lead-routing.module';
import { LeadComponent } from './lead.component';
import { AttendanceListComponent } from './lead/attendance-list/attendance-list.component';
import { AddAttendanceComponent } from './lead/add-attendance/add-attendance.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Select2Module } from 'ng2-select2';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { MatButtonModule, MatInputModule, MatListModule, MatStepperModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgBootstrapDatetimeAngularModule } from 'ng-bootstrap-datetime-angular';
import { OutAttendanceComponent } from './lead/out-attendance/out-attendance.component';

@NgModule({
  declarations: [LeadComponent, AttendanceListComponent, AddAttendanceComponent, OutAttendanceComponent],
  imports: [
    CommonModule,
    LeadRoutingModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    Select2Module,
    FilterPipeModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    NgxPaginationModule,
    NgBootstrapDatetimeAngularModule
  ]
})
export class LeadModule { }
