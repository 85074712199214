import { Component, OnInit } from '@angular/core';
import { ApiService} from '../../../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../../modal/confirmation-dialog.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  items;
  response = true;
  pageOfItems: Array<any>;
  hideme=[];
  hideFilter = false;
  p: number = 1;
  totalCount=0;
  searchForm: FormGroup;
  page = {
    limit: 10,
    count: 0,
    offset: 0,
  };
  searchValue="";
  public pageLimitOptions = [
    {value: 10},
    {value: 25},
    {value: 50},
    {value: 100},
  ];
  constructor(private formBuilder: FormBuilder,private api:ApiService,public router:Router,private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      services:[''],
      user:[''],
      
      To: [null],
      From: [null],
      priority:[''],
      source:['']
      
    });
  
    this.getLists();
    this.api.isLogged();
  }

  showHideFilter(){
    this.hideFilter = !this.hideFilter;
  }

  studentProgram(item){
    var name = [];
    // if(item.batch){
    //   name.push("Batch: "+item.batch.name);
    // }
    // if(item.medium){
    //   name.push("Medium: "+item.medium);
    // }
    if(item.school_class.name){
      name.push("Class: "+item.school_class.name);
    }
    if(item.school_section.name){
      name.push("Session: "+item.school_section.name);
    }
    return name;
  }
  viewItem(item){
    // this.router.navigateByUrl('/session/add', { state: item });

  }

  // isFilter:boolean=true;
  // onSubmit(actionType) {
  //   this.productService=[];
  //   this.isFilter=!this.isFilter;
  //   if(actionType=="filter"){
  //     var datePipe = new DatePipe('en-US');
  //     let additionalFilter = this.searchForm.value;
  //     if(additionalFilter.services){
  //       additionalFilter.services.map(x=>{
  //         this.productService.push(x.id);
  //       })
  //     }
      
  //     this.isAdditionalFilter=true;
  //     if(additionalFilter.To){
  //       this.toDate = datePipe.transform(new Date(additionalFilter.To["year"], additionalFilter.To["month"]-1, additionalFilter.To["day"]),'yyyy-MM-dd')
  //     }
  //     if(additionalFilter.From){
  //       this.fromDate= datePipe.transform(new Date(additionalFilter.From["year"], additionalFilter.From["month"]-1, additionalFilter.From["day"]),'yyyy-MM-dd');

  //     }
  //     this.leadPriority=Number(additionalFilter.priority);
  //     this.leadSource=Number(additionalFilter.source);
  //     this.assignUsers= Number(additionalFilter.user);
  //     console.log("this.fromDate",this.fromDate)
  //     console.log("this.toDate",this.toDate)
      
  //     this.getLists({
  //       "search":this.searchValue,
  //       "page_number": 1,
  //        "limit":10,
  //        "isAdditionalFilter":this.isAdditionalFilter,
  //        "toDate":this.toDate,
  //        "fromDate":this.fromDate,
  //        "leadPriority": this.leadPriority,
  //        "productService":this.productService,
  //        "leadSource":this.leadSource,
  //        "assignUser":this.assignUsers,
  //        "status":this.filterBy
  //      });

  //      }
  //      else if(actionType=="refresh"){
  //       this.isAdditionalFilter=false;
  //        this.ngOnInit();
  //      }
  // }

  onChangePage(pageOfItems) {
    this.getLists({
      "search":this.searchValue,
      "page_number": pageOfItems,
      "limit":10,
      // "isAdditionalFilter":this.isAdditionalFilter,
      // "toDate":this.toDate,
      // "fromDate":this.fromDate
    });
  }

  perPageItems=10;
  data:[];
  getLists(extra={}){
    var filter = {type:'student-registration',id:localStorage.getItem('org_id')};
    filter = {...filter, ...extra};
    this.response = true;
    this.api.post('list/student-registration', filter).subscribe(
      data=>{
        this.response = false;
        this.p = extra["page_number"];
        this.totalCount = data.count;

        this.items = data.response;
      },
      err =>{
        this.response = false;
      },
      ()=>console.log('loading completed')
    )
  };

  deleteItem(id){
    this.response=true;
    let item = {id:id,type:'student-admission-registration'};
    this.api.delete(item).subscribe(
      data=>{
        this.response=false;
        this.pageOfItems.splice(this.pageOfItems.findIndex(function(i){
          return i.id == id;
      }), 1);
      },err=>{
        this.response = false;
        console.log(err);
      }
    )

  };

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);
    }

    })
  }


}
