import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'
declare var $: any;
import * as moment from 'moment';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  id: number;
  item;
  fieldTypes;
  routes;
  pick_drops;
  months = [];
  hideme = [];
  selected_month_records:any = [];

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      route: ['', Validators.required],
      pickup: ['', Validators.required],
      mode_months: ['', Validators.required],
      status: [true],
    });
    setTimeout(() => {
      $('.selectpicker').selectpicker('refresh');
    }, 200);

    this.getFiledByKeys();
    this.selected_month_records = []
    this.months = [];
    for(let i=1; i<=12;i++){
      this.months.push(i);
    }
    this.id = this.route.snapshot.params['id'];
  }

  get f() { return this.registerForm.controls; }

  changeMonthSelection() {
    const MAIN = this
    this.selected_month_records = [];
    this.registerForm.controls['mode_months'].value.forEach((value)=>{
      let dates = []
      var start_date = moment().startOf('year');
      for (let i = 0; i < parseInt(value); i++){
        dates.push({
            name:"",
            one_side:"0",
            both_side:"0",
            date: start_date.format('YYYY-MM-DD')
          });
        start_date = moment(start_date).add((value <=6 ? 12/value : 1), 'months');
      }
      MAIN.selected_month_records.push({
        'name':value+" times in a year",
        "months":value,
        'dates':dates
      })
    })
  }
  

  updateDateAmount(record_index, index, type, value) {
    var MAIN = this;
    var date = MAIN.selected_month_records[record_index].dates[index];
    if(type == "D"){
      date['date'] = value
    }else if(type == "OS"){
      date['one_side'] = value
    }else if(type == "BS"){
      date['both_side'] = value
    }else if(type == "N"){
      date['name'] = value
    }
    MAIN.selected_month_records[record_index].dates[index] = date
  }

  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.response = true;
    let request = this.registerForm.value;
    request.type = 'add-pickup-drop';
    request.school_user_id = localStorage.getItem('org_id');
    request.user_id = localStorage.getItem('user_id');
    request.id = this.id;
    request.dateprice = this.selected_month_records;

    this.api.save('add', request).subscribe(
      data => {
        if (data.id != undefined) {
          if(this.id){
            Swal.fire('Success', 'Updated successfully', 'success');
          }else{
            this.submitted = false;
            this.registerForm.reset();
            this.ngOnInit();
            Swal.fire('Success', 'Created successfully', 'success');
          }
        }   
      }, err => {
        this.response = false;
        this.error = "Server error found";
      }, ()=>{
        this.response = false;
      }

    )

  }

  getItemById(id) {
    let send = { type: 'pick-drop', id: id };
    const MAIN = this;
    this.api.get(send).subscribe(
      async (data) => {
        MAIN.item = data;
        
        let pickin_ids =[]
        let mode_months =[]
        await MAIN.item.pickin.forEach(async (p)=>{
          pickin_ids.push(p.pickup_id);
        });
        MAIN.item.pickin_ids = pickin_ids;

        await MAIN.item.mode.forEach(async (m)=>{
          var info = {
            'name':m.months+" times in a year",
            "months":m.months,
            dates:[]
          }

          await m.date_price.forEach((dp)=>{
            info.dates.push({
              'name':dp.name,
              'one_side':dp.oneway_price,
              'both_side':dp.bothside_price,
              'date':dp.date
            });
          });

          mode_months.push(m.months);
          MAIN.selected_month_records.push(info)
        });

        MAIN.registerForm = MAIN.formBuilder.group({
          route: [MAIN.item.route.id, Validators.required],
          pickup: [pickin_ids.map(String), Validators.required],
          mode_months: [mode_months.map(String), Validators.required],
          status: [MAIN.item.status, Validators.required]
        });

        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        }, 200);
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }


  getFiledByKeys() {
    this.response = true;
    let send = { type: 'field-by-keys', keys:['route','pick_drop'],user_id:localStorage.getItem('user_id') };
    this.api.post('list/fields',send).subscribe(
      (data) => {
        this.response = false;
        this.fieldTypes = data.response;
        this.pick_drops = data.response.pick_drop;
        this.routes = data.response.route;
        if (this.id) {
          this.getItemById(this.id);
        }
        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        }, 200);
      },
      err => {
        this.response = false;
        console.error(err);
      },
      () => console.log('loading completed')
    )
  
  }

}
