import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/api.service';
import { SharingServiceService } from 'src/app/services/sharing-service.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-add-project-manage',
  templateUrl: './add-project-manage.component.html',
  styleUrls: ['./add-project-manage.component.css']
})
export class AddProjectManageComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private api: ApiService, private sharingService: SharingServiceService, private router: Router) { }
  isLinear = true;
  countries: any;
  projectFormGroup: FormGroup;
  sellerFormGroup: FormGroup;
  properties: any[] = []
  projects: any[] = [];
  filterStates = [];
  genders = [{ id: 1, title: "Male" }, { id: 2, title: "Female" }];
  units = [{ id: 1, title: "ACRE" }, { id: 2, title: "BIGHA" }, { id: 3, title: "BISWA" }, { id: 4, title: "SQ. METER" }, { id: 5, title: "SQ. FEET" }, { id: 6, title: "SQ. YARDS" }]
  data: any;
  response: boolean = false;
  numberPattern = "^[-+]?[0-9]*\\.?[0-9]*$"
  aadharNoPattern = "^[0-9]*$"
  projectLists: any = [];
  states;
  item;
  newProjectsList: any = [];
  formTitle;



  ngOnInit() {

    this.projectFormGroup = this.formBuilder.group({
      project_name: ['', Validators.required],
      project_type: ['', Validators.required],
      property_size: ['', Validators.required],
      property_size_type: [''],
      projected_unit: ['', Validators.required],
      projected_area: [''],
      // measurement: [''],
      purchaseType: ['', Validators.required],
      property_description: [''],
      aggrement_date: [''],
      gata_no: [''],
      khata_no: [''],
      final_amount: ['', Validators.required],
      property_valuation: [''],
      country_id: [''],
      state_id: [''],
      city: [''],
      tehshil: [''],
      street: [''],
      town_village: [''],
      user_Id: [''],
      owner_id: [''],
      id: ['']
    });
    this.formTitle = this.sharingService.formTitleGet()
    // this.getProjectList();
    this.getProperty();
    this.getCountry();
    this.getState();
    this.data = this.sharingService.getData();
    if (this.data) {
      setTimeout(x => { this.setEditData(), 1000 });
    }
    this.sellerFormGroup = this.formBuilder.group({
      seller: this.formBuilder.array([
      ], Validators.required),

    });
    if (!this.data) {
      this.seller().push(this.newSeller())
    }

  }
  setEditData() {
    console.log('asdfasd')
    this.projectFormGroup = this.formBuilder.group({
      project_name: [this.data.project_name, Validators.required],
      project_type: [this.data.project_type, Validators.required],
      property_size: [this.data.property_size, Validators.required],
      property_size_type: [this.data.property_size_type],
      projected_unit: [this.data.projected_unit, Validators.required],
      projected_area: [this.data.projected_area],
      // measurement: [this.data.measurement, Validators.required],
      purchaseType: [this.data.purchaseType],
      property_description: [this.data.property_description],
      aggrement_date: [moment(this.data.aggrement_date).format('yyyy-MM-DDThh:mm')],
      gata_no: [this.data.gata_no],
      khata_no: [this.data.khata_no],
      final_amount: [this.data.final_amount],
      property_valuation: [this.data.property_valuation],
      country_id: [this.data.country_id],
      state_id: [this.data.state_id],
      city: [this.data.city],
      tehshil: [this.data.tehshil],
      street: [this.data.street],
      town_village: [this.data.town_village],
      user_Id: [this.data.user_Id]
    });
    if (this.data.owners.length > 0) {
      setTimeout(y => {
        this.data.owners.map(x => {
          console.log('owners.data', x);
          this.seller().push(this.setSeller(x));
        }), 1000
      })
    }
    else {
      this.seller().push(this.setSeller());
    }

  }
  setSeller(seller?): FormGroup {
    return this.formBuilder.group({
      title: [seller.title ? seller.title : '', Validators.required],
      seller_name: [seller.seller_name ? seller.seller_name : '', Validators.required],
      mobile_number: [seller.mobile_number ? seller.mobile_number:''],
      pan: [seller.pan ? seller.pan : ''],
      adhar: [seller.adhar ? seller.adhar : ''],
      gender: [seller.gender ? seller.gender : ''],
      profession: [seller.profession ? seller.profession : ''],
      seller_amount: [seller.seller_amount ? seller.seller_amount : ''],
      property_size: [seller.property_size ? seller.property_size : ''],
      country_id: [seller.country_id ? seller.country_id : ''],
      state_id: [seller.state_id ? seller.state_id : ''],
      city: [seller.city ? seller.city : ''],
      town_village: [seller.town_village ? seller.town_village : ''],
      street: [seller.street ? seller.street : ''],
      address: [seller.address ? seller.address : ''],
      owner_id: [seller.id ? seller.id : ''],
      id: [seller.id ? seller.id : '']
    })

  }
  seller(): FormArray {
    return this.sellerFormGroup.get("seller") as FormArray
  }
  newSeller(): FormGroup {
    return this.formBuilder.group({
      title: [''],
      seller_name: ['', Validators.required],
      mobile_number: [''],
      pan: [''],
      adhar: [''],
      gender: [''],
      profession: '',
      seller_amount: ['', Validators.required],
      property_size: ['', Validators.required],
      country_id: [''],
      state_id: [''],
      city: [''],
      town_village: [''],
      street: '',
      address: [''],
    })
  }
  get f() { return this.projectFormGroup.controls; }
  get p() { return this.sellerFormGroup.get("seller") as FormArray; }
  submitted: boolean = false;
  onSubmit() {
    this.submitted = true;
  }

  // getProjectList(extra={}){
  //   let filter = {user_id:localStorage.getItem('user_id') };
  //   this.response = true;

  //   this.api.post('project-list', filter).subscribe(
  //     data=>{
  //       this.response = false;
  //       this.projectLists=data.result.list
  //       this.getProperty()
  //     },
  //     err =>{
  //       this.response = false;
  //     },
  //     ()=>console.log('loading completed')
  //   )
  // };

  getProperty() {
    this.api.post('organization/list', { type: "field", user_id: localStorage.getItem('user_id') }).subscribe(resp => {
      if (resp.response.length > 0) {
        console.log('withoutProperties', resp.response);
        this.properties = resp.response.filter(ele => {
          if (ele.field_type) {
            return ele.field_type.name.toLowerCase() == "property type"
          }
        });
        console.log('afterFilter', this.properties)

        // this.projects=resp.response.filter(ele1=>{
        //   if(ele1.field_type){
        //     return ele1.field_type.name.toLowerCase()=="project";
        //   }
        //  });
        //  if(this.data){
        //   this.projectLists.forEach(e => {
        //     this.projects.forEach((d,i)=> {
        //       if(d.id==e.project){
        //        return this.projects
        //       }
        //     })
        //   })
        //  }
        //  else{
        //   this.projectLists.forEach(e => {
        //     this.projects.forEach((d,i)=> {
        //       if(d.id==e.project){
        //        return this.projects.splice(i,1)
        //       }
        //     })
        //   })
        //  }  
      }
    })
  }

  onChange(country_d) {
    let newStates = [];
    this.states.forEach(function (value) {
      if (value.country_id == country_d) {
        newStates.push(value);
      }
    });
    this.filterStates = newStates;
  }

  getCountry() {
    this.api.post('list', { type: "country" }).subscribe(
      data => {
        this.countries = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  getState() {
    this.api.post('list', { type: "state" }).subscribe(
      data => {
        this.states = data;
        if (this.data) {
          this.onChange(this.data);
        }
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };


  getItems() {
    this.api.post('organization/list', { type: 'field-by-keys', 'user_id': localStorage.getItem('user_id'), keys: ['gender'] }).subscribe(
      data => {
        //  this.genders = data.gender;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };
  sellerSubmitted: boolean = false;

  sellerSubmit(seller) {
    console.log('asdfasdfasd');
    this.response = true;
    this.sellerSubmitted = true;
    if (seller.invalid) {
      this.response = false;
      return;
    }

    let request = this.projectFormGroup.value;
    request.property_size_type = request.projected_unit;
    request.projected_area = request.property_size;
    request.property_status = "Agreement";
    request.user_id = localStorage.getItem('user_id');
    let isEdit = false;
    this.response = true;
    if (this.data) {
      request.project_id = this.data.id;
      isEdit = true;
    }
    console.log("projectRequestBody", request);

    this.api.addProject(request, isEdit).subscribe(
      (data) => {
        if (data.code == 200) {
          this.projectFormGroup.reset();
          this.submitted = false;
          seller.value.seller.forEach((element, index) => {
            let sellerEdit = false;
            let sellerRequest = element;

            sellerRequest.user_id = localStorage.getItem('user_id');
            sellerRequest.project_id = data.result.id;
            if (element.id) {
              sellerEdit = true;
            }
            if (isEdit && element.id) {
              console.log('projectId', this.data.id)
              sellerRequest.project_id = this.data.id;
            }
            console.log("sellerRequestBody", sellerRequest);
            this.api.addProjectOwner(sellerRequest, sellerEdit).subscribe(
              (res) => {
                if (res.code == 200) {
                  if ((index + 1) == this.sellerFormGroup.value.seller.length) {
                    this.response = false;
                    this.sellerFormGroup.reset();
                    this.sellerSubmitted = false;
                    if (isEdit) {
                      Swal.fire('Success', 'Updated successfully', 'success');
                    }
                    else {
                      Swal.fire('Success', 'Created successfully', 'success');
                    }
                    this.router.navigateByUrl('/realstate/projectmanage');
                  }

                }
                else {
                  this.response = false;
                  this.sellerFormGroup.reset();
                  this.sellerSubmitted = false;
                }
              }
            )
          });
        }
      }, err => {
        this.response = false;
        Swal.fire('OOps', 'Server error found', 'error');
      }, () => {
        this.response = false;
      })
  }
  removeSeller(i: number) {
    this.seller().removeAt(i);
  }
  addSeller() {
    this.seller().push(this.newSeller());
  }
  selectionChange() {

  }
}
