import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ApiService } from '../../../../api.service';
import { ConfirmationDialogService } from 'src/app/modal/confirmation-dialog.service';
import { SharingServiceService } from 'src/app/services/sharing-service.service';
import * as moment from 'moment';

@Component({
  selector: 'app-customer-payment-report',
  templateUrl: './customer-payment-report.component.html',
  styleUrls: ['./customer-payment-report.component.css']
})
export class CustomerPaymentReportComponent implements OnInit {
  page = {
    limit: 10,
    count: 0,
    offset: 0,
  };
  public pageLimitOptions = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];
  interests = [
    {
      title: 'Interest Rate(Per Annum)',
      key: 'interest'
    },
    // {
    //   title: 'Reduced base',
    //   key: 'reduced'
    // },
    // {
    //   title: 'Flat Rate base',
    //   key: 'flat'
    // }
  ];
  hideFilter = false;
  customerPaymentForm
  paymentTypes = [];
  paymentModes = [];
  paymentType;
  response: boolean = false;
  error: string;
  custId: any;
  totalCost: any;
  pageOfItems: Array<any>;
  EMILists: any;
  prevReceiveAmount;
  preDueAmount;
  totalReceivedAmount = 0;
  bookingId;
  totalCount = 0;
  pageNumber: number = 1;
  items: any;
  addPayment: any = 'Payment';
  paymentList: any;
  EMIListView: boolean = false;
  bookingType: any;
  paymentReport: any;
  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute, private router: Router, private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {


    this.bookingId = this.route.snapshot.queryParamMap.get('bookingId');
    this.custId = this.route.snapshot.queryParamMap.get('customerId');
    this.bookingType = this.route.snapshot.queryParamMap.get('bookingType').toLowerCase()
    // this.totalCost = this.route.snapshot.queryParamMap.get('totalCost');
    // this.prevReceiveAmount = this.route.snapshot.queryParamMap.get('prevReceived');
    // this.preDueAmount = this.route.snapshot.queryParamMap.get('prevDue');
    console.log('bookingType', this.bookingType);

    this.singleBookingPaymentCount()


    if (this.bookingType == 'token') {
      this.paymentTypes = [
        {
          id: 2,
          name: 'Booking',
        },
        {
          id: 3,
          name: 'EMI',
        },
        {
          id: 4,
          name: 'Pre Payment',
        }]
    }
    else {
      this.paymentTypes = [
        {
          id: 3,
          name: 'EMI',
        },
        {
          id: 4,
          name: 'Pre Payment',
        }]
    }

    this.fields();
    this.customerPaymentForm = this.formBuilder.group({
      slipNumber: [''],
      paymentType: ['', Validators.required],
      currentReceivingAmount: ['', Validators.required],
      bookingTokenEmiDate: ['', Validators.required],
      remark: [''],
      paymentMode: ['', Validators.required],
      message: [''],
      totalReceivedAmount: [''],
      currentDueAmount: [''],
      netCost: [''],
      emi_mode: [''],
      interestRate: [''],
      interest_type: [''],
      installment: [''],
      emi_date: [''],
      clearing_date: ['']
    });

  }

  showHideFilter() {
    this.hideFilter = !this.hideFilter;
  }

  get p() { return this.customerPaymentForm.controls; }

  fields() {
    // this.api.post('booking-fields' + "?id=" + localStorage.getItem('user_id'),null)
    this.api.post('organization/list', { type: "field", user_id: localStorage.getItem('user_id') }).subscribe(resp => {
      if (resp.response.length) {
        resp.response.forEach((field) => {
          if (field.field_type_id == 25) {
            this.paymentModes.push({ 'id': field.field_type_id, 'name': field.name })
          }
        })
      }
    })
  }

  paymentTabsData(tabName) {
    if (tabName == 'Payment') {
      this.paymentList = null;
      this.addPayment = tabName;
      this.EMIListView = false;
    }
    else if (tabName == 'PaymentReport') {
      this.addPayment = null;
      this.EMIListView = false;
      this.getlist({
        "page_number": 1,
        "limit": 10
      });
      this.paymentList = 'PaymentReport';
    }
    else if (tabName == 'EMIDetails') {
      this.paymentList = null;
      this.addPayment = null;
      this.EMIListView = true;
      this.getEmiList({
        "page_number": 1,
        "limit": 10
      });
    }
  }

  onSubmit() {
    this.response = true;
    if (this.customerPaymentForm.invalid) {
      this.response = false;
      return;
    }
    let request = this.customerPaymentForm.value;
    request.customerId = this.custId;
    request.schoolUserId = localStorage.getItem('school_user_id');
    request.bookingId = this.bookingId;
    this.response = false;
    this.api.save('add-customer-payment', request).subscribe(
      data => {
        if (data.code == 200) {
          this.singleBookingPaymentCount()
          Swal.fire({ 'title': 'Success', 'html': 'Customer Payment Added Successfully' });
          // this.router.navigateByUrl('/realstate/booking/list');
          window.open(this.api.apiURL + "customer/payment/generate-report?payment_id=" + data.result.id, "_blank");
        }
        else if (data.code == 422) {
          this.response = false;
          Swal.fire('Error', "Check the all mendatory field", 'warning');
        }
      }, err => {
        this.response = false;
        this.error = "Server error found";
        Swal.fire('Error', this.error, 'warning');
      }, () => {
        this.response = false;
      }
    )
    this.customerPaymentForm.reset();
  }

  changeBookingType(paymentType) {
    this.paymentType = paymentType
  }

  getlist(extra = {}) {
    this.response = true;
    let filter = { schoolUserId: localStorage.getItem('school_user_id'), bookingId: this.bookingId }
    filter = { ...filter, ...extra };
    this.api.post('single-customer-payment-list', filter).subscribe(resp => {
      this.response = false;
      this.items = resp.result;
      this.response = false;

    },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  }

  singleBookingPaymentCount() {
    this.response = true;
    this.api.post('single-booking-payment-count', { bookingId: this.bookingId }).subscribe(resp => {
      this.response = false;

      this.totalReceivedAmount = parseInt(resp.result.received_amount);
      this.preDueAmount = resp.result.due_amount;
      this.customerPaymentForm.patchValue({ 'totalReceivedAmount': this.totalReceivedAmount });
      this.customerPaymentForm.patchValue({ 'netCost': resp.result.netcost })
      this.customerPaymentForm.patchValue({ 'currentDueAmount': this.preDueAmount })

    },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  }

  getEmiList(extra = {}) {
    let filter = { customerId: this.custId, schoolUserId: localStorage.getItem('school_user_id') };
    filter = { ...filter, ...extra };
    // console.log('filter',filter);
    // console.log('extra',extra);
    this.api.post('customer-emiList', filter).subscribe(resp => {
      this.response = false;
      console.log('EMiLIST', resp.result.emiLists);
      if (resp.code == 200) {
        this.pageNumber = extra['page_number'];
        this.totalCount = resp.result.totalEMIs;
        console.log('Emi Details', resp.result.emiLists);
        this.EMILists = resp.result.emiLists;
      }
    },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  }

  deleteData(id, reason) {
    this.response = true;
    let item = { id: id, reason: reason }
    this.api.deleteData('delete-customer-payment', item).subscribe(
      (resp) => {
        Swal.fire('Success', 'Custmer payment deleted successfully!', 'success');
        this.ngOnInit()
        this.response = false;
      }, err => {
        this.response = false;
        console.log(err);
      }
    )
  };

  public openConfirmationDialog(id) {
    const Toast = Swal.mixin({
      input: 'textarea',
      inputPlaceholder: 'Reason...',
      inputAttributes: {
        'aria-label': 'Reason'
      },
      showCancelButton: true
    })
    Toast.fire('Please confirm...', 'Do you really want to delete... ?', 'info').then((result) => {
      if (result.isConfirmed) {
        this.deleteData(id, result.value);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your file record is safe :)', 'error')
      }
    })
  }

  updateReceivedAndDueAmount(currentPayingAmount) {
    debugger;

    if (currentPayingAmount) {

      this.customerPaymentForm.patchValue({ 'totalReceivedAmount': this.totalReceivedAmount + parseInt(currentPayingAmount) });
      this.customerPaymentForm.patchValue({ 'currentDueAmount': parseInt(this.preDueAmount) - parseInt(currentPayingAmount) });
    }
    // else {

    //   debugger;
    //   this.customerPaymentForm.patchValue({ 'totalReceivedAmount': this.prevReceiveAmount });
    //   this.customerPaymentForm.patchValue({ 'currentDueAmount': this.preDueAmount });
    // }
  }

  onChangePage(pageOfItems) {
    this.getEmiList({
      // "search":this.searchValue,
      "page_number": pageOfItems,
      "limit": 10
      // "isAdditionalFilter":this.isAdditionalFilter,
      // "toDate":this.toDate,
      // "fromDate":this.fromDate,
      // "leadPriority": this.leadPriority,
      // "productService":this.productService,
      // "leadSource":this.leadSource,
      // "assignUser":this.assignUsers,
      // "status":this.filterBy
    });
  }
  customerPaymentListingChange(pageOfItems) {
    this.getlist({
      // "search":this.searchValue,
      "page_number": pageOfItems,
      "limit": 10
      // "isAdditionalFilter":this.isAdditionalFilter,
      // "toDate":this.toDate,
      // "fromDate":this.fromDate,
      // "leadPriority": this.leadPriority,
      // "productService":this.productService,
      // "leadSource":this.leadSource,
      // "assignUser":this.assignUsers,
      // "status":this.filterBy
    });
  }



  generatePaymentReport(bookingType, amount, payment_id) {
    this.response = true;
    this.api.getPdf('customer/payment/generate-report?payment_id=' + payment_id).subscribe((data: any) => {

      const blob = new Blob([data], { type: 'application/pdf' });
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = payment_id + ".pdf";
      link.click();
      this.response = false;


    });
  }


  setClearingDate() {
    let paymentFormControls = this.customerPaymentForm.value;
    let emiStartDate = paymentFormControls.emi_date;
    let emiTenure = paymentFormControls.emi_mode;
    let emiClearingDate = moment(emiStartDate).add(emiTenure, 'M').format('DD-MM-YYYY');
    console.log('emiClearingDate', emiClearingDate);
    this.customerPaymentForm.patchValue({ 'clearing_date': emiClearingDate });
  }


  calcuRateOfInterest(value) {
    if (value == 'interest') {
      let paymentFormGroup = this.customerPaymentForm.value;
      console.log(this.totalCost);
      let reduceAmount = paymentFormGroup.currentReceivingAmount;
      let emaiMode = paymentFormGroup.emi_mode;
      let interestRate = paymentFormGroup.interestRate;
      let currentDuesAmount = Number(paymentFormGroup.currentDueAmount)
      let calInterestAmount = currentDuesAmount * Number(interestRate) / 100;
      let emiAmount = (currentDuesAmount + calInterestAmount) / Number(emaiMode);

      this.customerPaymentForm.patchValue({ "installment": emiAmount.toFixed(2) })
    }
  }

}
