import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  id: number;
  item;
  records;
  classes=[];
  sections;
  class_room=[];
  employees=[];


  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.getFieldType();
    if (this.id) {
      this.getItemById(this.id);

    }

    this.registerForm = this.formBuilder.group({
      class_id: ['', Validators.required],
      section_id: ['',Validators.required],
      class_room: ['',Validators.required],
      employee_id: ['',Validators.required],
      status: [true],
    });
  }

  get f() { return this.registerForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    let request = this.registerForm.value;
    request.type = 'add-assign-class-teacher';
    request.school_user_id = localStorage.getItem('org_id');
    request.id = this.id;

    this.api.save('add', request).subscribe(
      data => {
        if (data == 'success') {
          if (this.id) {
            Swal.fire('Success', 'Updated successfully', 'success');

          } else {
            this.registerForm.reset();
            Swal.fire('Success', 'Created successfully', 'success');
          }
        }
      }, err => {
        this.response = false;
        //this.error = "Server error found";
        if(err.error=='duplicate'){
          Swal.fire('Oops', 'This class already assigned to this achool', 'error');

        }


      }

    )

  }

  getItemById(id) {
    let send = { type: 'assign-class-teacher', id: id };
    this.api.get(send).subscribe(
      data => {
        this.item = data;
        this.classChange(this.item.school_class_id);
        this.registerForm = this.formBuilder.group({
          class_id: [this.item.school_class_id, Validators.required],
          section_id: [this.item.school_class_section_id,Validators.required],
          class_room: [this.item.class_room,Validators.required],
          employee_id: [this.item.employee_id,Validators.required],
          status: [this.item.status ? true : false],
        });
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }


  getFieldType() {
    this.api.post('list/assignTeacher', { type: 'assign-group-teacher','school_user_id':localStorage.getItem('org_id'),'update':this.id ? this.id : 0 }).subscribe(
      data => {
        this.response = false;
        this.records = data;
        this.records = this.records.response;
        this.classes = this.records.class;
        this.employees = this.records.employees;
        this.class_room = this.records.class_rooms;
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };

  classChange(id){

//    alert(id);
     let sec;
    this.classes.forEach(function(value){
      if(value.id==id){
        sec = value.sections;
      }
    });
    this.sections = sec;
  }

}
