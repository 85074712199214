import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from '../../../modal/confirmation-dialog.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  type;
  items:any;
  schools;
  success:string='';
  error:string='';
  response = false;
  resp:any
  pageOfItems: Array<any>;
  smsplan: Array<any>;
  plans: Array<any>;
  routeType= false;


  constructor(private api: ApiService, private route: ActivatedRoute,private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
    this.items = {
      sms_plan_id:0,
      school_id:0,
      status:0,
      price:0
    }
    this.getItems();
  }


  
  changeQuantity(param) {
  }
  getItems() {
    this.api.post('organization/list',{type:'organizationuser',organization_id:localStorage.getItem('user_id')}).subscribe(
      data => {

        this.items = data;
        this.items = this.items.response;

      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  submitItem(form) {
  }
  deleteItem(id) {
    this.response = true;

    let item = { id: id, type: 'organizationuser' };
    this.api.delete('organization/delete',item).subscribe(
      data => {
        this.response = false;
        this.getItems();
      }, err => {
        this.response = false;
        console.log(err);
      }
    )

  }

  statusChange(event, user_id){
    this.api.post('organization/user/status', {value: event.target.checked, user_id} ).subscribe(
      data=>{
      },
      err =>{
        console.error(err);
      }
    )
  };


  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);

    }

    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

}
