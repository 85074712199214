import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ApiService } from '../../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../modal/confirmation-dialog.service';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.css']
})
export class CollectionsComponent implements OnInit {
  @Input() childProperty;
  items;
  response = true;
  pageOfItems: Array<any>;
  other;
  hideme = [];
  student;
  date;
  request = {from:'',to:''};

  constructor(private api: ApiService, public router: Router, private confirmationDialogService: ConfirmationDialogService) { }


  ngOnInit() {
    this.api.isLogged();
    if (!this.childProperty) {
      this.getLists(null);
    }

  }

  search(req){
    console.log("item=",req);
    this.getLists(req);

  }

  getLists(req) {
    this.api.post('list/fee-transactions', 
    { type: 'student-fee-transactions',
    id: localStorage.getItem('org_id'),
    from:req ? req.from : null,
    to:req ? req.to :null
   }).subscribe(
      data => {
        this.response = false;
        this.items = data;
        this.date = this.items.response.date;
        this.items = this.items.response.collection;
        this.request = {from:this.date.from,to:this.date.to};
 

      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;

  }


  deleteItem(id) {
    this.response = true;
    let item = { id: id, type: 'student-fee-collection' };
    this.api.delete(item).subscribe(
      data => {
        this.response = false;
        this.pageOfItems.splice(this.pageOfItems.findIndex(function (i) {
          return i.id == id;
        }), 1);
      }, err => {
        this.response = false;
        console.log(err);
      }
    )

  };

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
      .then((confirmed) => {
        console.log('User confirmed:', confirmed)
        if (confirmed) {
          this.deleteItem(id);
        }

      })
  }


}
