import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from '../../modal/confirmation-dialog.service';


@Component({
  selector: 'app-sms-request',
  templateUrl: './sms-request.component.html',
  styleUrls: ['./sms-request.component.css']
})
export class SmsRequestComponent implements OnInit {

  type;
  items;
  schools;
  error:string='';
  response = false;
  submitted = false;
  success;
  routeType;
  request;
  plans;
  smsplan

  // current page of items
  pageOfItems: Array<any>;


  constructor(private api: ApiService, private route: ActivatedRoute,private confirmationDialogService: ConfirmationDialogService) {

    this.request = {status:true,school_id:'',quantity:'',price:''};

   }

  ngOnInit() {
    //this.getItems();
    this.api.isLogged();
    this. getSchool();
    this.routeType = this.route.snapshot.params['add'];

    if(this.routeType){
      this. getSchool();

    }


  }


  deleteItem(id) {
    this.response = true;

    let item = { id: id, type: 'smsrequest' };
    this.api.delete(item).subscribe(
      data => {
        this.response = false;
       // this.getItems();
      }, err => {
        this.response = false;
        console.log(err);
      }
    )

  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);

    }

    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  itemAction(action) {
    action.type = "smsrequest";
    this.api.action(action).subscribe(data => {
    var id = action.id
    var index = this.pageOfItems.findIndex(item => item.id === id);
    if(data){
      this.pageOfItems.splice(index, 1, data);

    }

     // this.getItems();

    }, err => {
      this.response = false;
      console.log(err);

    })
  };


  getSchool(){
    this.api.getschools().subscribe(
      data=>{
        this.schools = data;
      },
      err =>{
        console.error(err);
      },
      ()=>console.log('loading completed')
    )
  };


  submitItem(sendData){

    this.success='';
    this.error='';
    this.response = true;
    this.submitted = true;
    sendData.user_id = localStorage.getItem('user_id');
  
      this.api.add('sms-request/store',sendData).subscribe(
        data=>{
          this.response =false;
          if(data=='success'){
            this.success="New item created successfully";
          }
  
        },
        err =>{
          this.response = false;
          console.log('error getting',err.error);
          this.error = "Server error found";
        },
        ()=>console.log('loading completed')
      )
    }


    changeQuantity(school_id){
      console.log(this.smsplan.quantity);
      this.request = {price:this.smsplan.price, quantity:this.smsplan.quantity, sms_plan_id:this.smsplan.id, status:true,school_id:school_id};
    }





}
