import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2'
declare var $: any;


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit, OnChanges {

  registerForm: FormGroup;
  searchForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  id: number;
  item;
  fieldTypes;
  parentProperty;
  search_text;
  search_type;
  security;
  fee_months: any = [];
  transport_month: any = [];
  classFee: number = 0;
  transportFees: number = 0;
  search: any = { search_type: '' };
  student_detail;



  constructor(private formBuilder: FormBuilder, private api: ApiService, private router: Router, private route: ActivatedRoute) {

  }



  ngOnChanges() {

  }

  ngOnInit() {

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      // do your task for before route
      return false;
    }

    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);
    }

    this.registerForm = this.formBuilder.group({
      payment_date: ['', Validators.required],
      pay_mode: ['', Validators.required],
      cheque_no: [''],
      bank_name: [''],
      transaction_id: [''],
      remark: [''],
      check_clear_date: [''],
      class_fees: this.formBuilder.array([]),
      transport_fees: this.formBuilder.array([])

    });



  }
  // get fsearch() { return this.searchForm.controls; }

  onSearch() {
    if (!this.search.search_type) {
      this.search_type = true;
    } else {
      this.search_type = false;
    }
    if (!this.search.search_text) {
      this.search_text = true;
    } else {
      this.search_text = false;
    }

    if (this.search.search_text && this.search.search_type) {
      this.search.type = 'search-student-fee-collection',
        this.search.id = localStorage.getItem('org_id');

      this.api.post('list/fee-collection', this.search).subscribe(
        data => {
          this.response = false;
          this.items = data;
          this.items = this.items.response;
          this.parentProperty = this.items;
        },
        err => {
          this.response = false;
        },
        () => console.log('loading completed')
      )
    }

  }

  get f() { return this.registerForm.controls; }

  onSubmit() {


    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    let request = this.registerForm.value;
    request.type = 'add-fee-collection';
    request.school_user_id = localStorage.getItem('org_id');
    request.student_id = this.id;
    request.user_id = localStorage.getItem('user_id');
    request.session_from = this.item.session_from;
    request.session_to = this.item.session_to
    request.pay_class_fee = $("#pay_class_fee").val();
    request.pay_amount = $("#pay_amount").val();
    request.pay_discount = $("#pay_discount").val();
    request.pay_total = $("#pay_total").val();
    request.pay_transport_fees = $("#pay_transport_fees").val();
    request.security_fee = $("#security_fee").val();
    request.admission_fee = $("#admission_fee").val();
    request.extra_fee = $("#extra_fee").val();

    this.api.save('add', request).subscribe(
      data => {

        if (data == 'success') {
          if (this.id) {
            Swal.fire('Success', 'Updated successfully', 'success');
            this.getItemById(this.id);
            this.registerForm.reset();
            $('#fee_collection')[0].reset();

          }
          else {
            this.registerForm.reset();
            $('#fee_collection')[0].reset();
            Swal.fire('Success', 'Created successfully', 'success');
          }
        }

      }, err => {
        this.response = false;
        this.error = "Server error found";
        Swal.fire('OOps', 'Server error found', 'error');
      }

    )

  }




  getFieldType() {
    this.api.post('list/field-type', { type: 'field-type' }).subscribe(
      data => {
        this.response = false;
        this.fieldTypes = data;
        this.fieldTypes = this.fieldTypes.response;
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };



  getItemById(id) {
    this.response = true;
    let send = { type: 'student-fee-collection', id: id };
    this.api.get(send).subscribe(
      data => {

        this.item = data;
        this.fee_months = this.item.fee_month;
        this.student_detail = this.item.student_detail;
        this.transport_month = this.item.transport_fee_month
        this.item = this.item.fee_detail;
        this.registerForm = this.formBuilder.group({
          payment_date: [this.item.current_date, Validators.required],
          pay_mode: ['', Validators.required],
          cheque_no: [''],
          bank_name: [''],
          transaction_id: [''],
          remark: [''],
          check_clear_date: [''],
          class_fees: this.formBuilder.array([]),
          transport_fees: this.formBuilder.array([])

        });
        this.response = false;
      },
      err => {
        debugger;
        this.response = false;
        if(err.error.message=='fee_not_asign'){
          Swal.fire('OOps', 'Fee not assign yet, please assign fee first', 'error');


        }

        console.error(err);
      },
      () => console.log('loading completed')
    )

  }

  onChange(fee, month, year, isChecked: boolean) {
    let FormArray = <FormArray>this.registerForm.controls.class_fees;
    let feeMonths = { month: month, year: year, fee: fee };

    if (isChecked) {
      FormArray.push(new FormControl(feeMonths));
      this.classFee += parseInt(fee);
    }
    else {
      let index = FormArray.controls.findIndex(x => x.value == month)
      FormArray.removeAt(index);
      this.classFee -= fee;
    }

  }


  onTransportChange(fee, month, year, isChecked: boolean) {
    let FormArray = <FormArray>this.registerForm.controls.transport_fees;
    let feeMonths = { month: month, year: year, fee: fee };

    if (isChecked) {
      FormArray.push(new FormControl(feeMonths));
      this.transportFees += parseInt(fee);
    }
    else {
      let index = FormArray.controls.findIndex(x => x.value == month)
      FormArray.removeAt(index);
      this.transportFees -= fee;
    }

    console.log('transport_fees=', this.transportFees);
  }



}
