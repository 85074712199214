import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../../modal/confirmation-dialog.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  items;
  response = true;
  pageOfItems: Array<any>;
  hideme =[];
  hidemeMode =[];
  constructor(private api:ApiService,public router:Router,private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
    this.getLists();
    this.api.isLogged();
  }


  getLists(){
    const MAIN = this;
    this.api.post('list/field',{type:'pick-drop',id:localStorage.getItem('org_id')}).subscribe(
      async (data)=>{
        this.response = false;
        this.items = data;
        this.items = this.items.response;
        await this.items.forEach(async (item)=>{
          var string= [];
          await item.pickin.forEach((p)=>{
            string.push(p.pick.name);
          });
          item.pickin_name = string.join(', ');
        });
      },
      err =>{
        this.response = false;
      },
      ()=>console.log('loading completed')
    )
  };

  onChangePage(pageOfItems) {
    this.pageOfItems = pageOfItems;
  }


  deleteItem(id){
    this.response=true;
    let item = {id:id,type:'pick-drop'};
    this.api.delete(item).subscribe(
      data=>{
        this.response=false;
        this.pageOfItems.splice(this.pageOfItems.findIndex(function(i){
          return i.id == id;
      }), 1);
      },err=>{
        this.response = false;
        console.log(err);
      }
    )

  };

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);
    }

    })
  }

}
