import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';

@Component({
  selector: 'app-cheque-report',
  templateUrl: './cheque-report.component.html',
  styleUrls: ['./cheque-report.component.css']
})
export class ChequeReportComponent implements OnInit {
  response: any;
  items: any;
  cheque_payment_id = false;
  bookingTypeStatus = [];
  searchForm
  submitted;
  loader = false;

  constructor(private api: ApiService, private formBuilder: FormBuilder,) { }

  ngOnInit() {
    this.getlist({
      "page_number": 1,
      "limit": 10
    });

    this.searchForm = this.formBuilder.group({
      from: ['', Validators.required],
      to: ['', Validators.required],
    });
  }


  get s() { return this.searchForm.controls; }


  getlist(extra = {}) {
    this.loader = true
    let filter = { schoolUserId: localStorage.getItem('school_user_id') }
    filter = { ...filter, ...extra };
    this.api.post('payment-list-byPaymentMode', filter).subscribe(resp => {
      this.response = false;
      this.items = resp.result;
      this.loader = false

    },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  }

  onSearch() {
    this.submitted = true;
    if (this.searchForm.invalid) {
      return;
    }
    this.getlist(this.searchForm.value)

  }

  generatePaymentReport(payment_id) {
    this.response = true;
    this.api.getPdf('customer/payment/generate-report?payment_id=' + payment_id).subscribe((data: any) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = payment_id + ".pdf";
      link.click();
      this.response = false;
    });
  }

  getChequePaymentId(id) {
    this.cheque_payment_id = id;

  }

  emittedDataByChild(item) {
    this.getlist();
  }

  bookingType(type) {
    this.bookingTypeStatus = type;
    this.getlist({ status: type })
    console.log("type", type);
  }

}
