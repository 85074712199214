import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../../modal/confirmation-dialog.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  items:any = [];
  response = true;
  pageOfItems: Array<any>;
  search='concession';
  hideme=[];

  constructor(private api: ApiService, public router: Router, private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
    this.getLists();
    this.api.isLogged();
  }

  viewItem(item) {
    // this.router.navigateByUrl('/session/add', { state: item });

  }

  getLists() {
    this.api.post('list/fine', { type: 'fine', id: localStorage.getItem('org_id') }).subscribe(
      data => {
        this.response = false;
        this.items = data;
        this.items = this.items.response;

        let fee = [];
        let concession = [];
        let classes = [];
        let subjects = [];
        //let concession = [];
        let datas = []
        this.items.forEach(function (data, index) {
          if(data.fine_type.key=='fee'){
            fee.push(data);
          }
          if(data.fine_type.key=='concession'){
            concession.push(data);
          }
          if(data.fine_type.key=='class'){
            classes.push(data);
          }
          if(data.fine_type.key=='subject'){
            subjects.push(data);
          }
        });

        datas['fee']= fee;
        datas['concession']= concession;
        datas['classes']= classes;
        datas['subjects']= subjects;
        this.items = datas;
        //console.log("concession",this.items.concession.concession_name);
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }


  deleteItem(id) {
    this.response = true;
    let item = { id: id, type: 'fine' };
    this.api.delete(item).subscribe(
      data => {
        this.response = false;
        this.pageOfItems.splice(this.pageOfItems.findIndex(function (i) {
          return i.id == id;
        }), 1);
      }, err => {
        this.response = false;
        console.log(err);
      }
    )

  };

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
      .then((confirmed) => {
        console.log('User confirmed:', confirmed)
        if (confirmed) {
          this.deleteItem(id);
        }

      })
  }



}
