import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ApiService } from '../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'



declare var $: any;
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  items: any = {};
  item:any = {};
  success: string;
  religions;
  pan:any;
  aadhaar:any;
  caseFiles:any;
  address:any;
  state:any;
  country:any;
  zip:any;
  samePermanent:any;
  permanent_address:any;
  family_type:any;
  same_permanent:any;
  city:any;
  permanent_country:any;
  permanent_state:any;
  permanent_city:any;
  documentPhoto:any;
  roll_no:any;
  controls:any;
  designation:any;
  siblingStatus:any;
  casts;
  spoken_languages;
  nationalities;
  blood_groups;
  marritals;
  lives_withs;
  handicappeds;
  parent_statuses;
  departments;
  designations;
  employment_types = [];
  reference_vias = [];
  grids = [];
  id:number=null;
  apiResponse;
  duplicateRoll:string;
  countries:any =[];
  states;
  filterStates=[];
  fieldTypes;
  academics = [];
  studentFile: File;
  motherFile: File;
  fatherFile: File;
  gaurdianFile: File;
  imagUrl;
  document:any ={};
  concessions:any = [];
  subjects:any=[];
  headings=[]

  //urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route:ActivatedRoute) {

  }

  ngOnInit() {



    this.imagUrl = this.api.imageUrl;
    this.getDepartDesig();
    this.getLists();
    this.getCountry();
    this.getFieldByType();
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);


    }
    this.getState();

    this.registerForm = this.formBuilder.group({
      department: ['', Validators.required],
      designation: ['', [Validators.required]],
      role: ['', Validators.required],
      grade: ['', Validators.required],
      enrollment: ['', Validators.required],
      reference: [''],
      pan: [''],
      aadhaar: [''],
      esi: [''],
      pf: [''],
      employment_code: [''],
      employee_code: [''],
      first_name: ['', Validators.required],
      middle_name: [''],
      last_name: ['',Validators.required],
      uid: [''],
      doa: [''],
      dob: [''],
      birth_place: [''],
      religion_id: [''],
      cast_id: [''],
      contact_no: [''],
      email: [''],
      blood_group: [''],
      language: [''],
      marrital: [''],
      handicapped: [''],
      nationality: [''],
      domicile_state: [''],
      physical_fitness: [''],
      case_file: ['no'],
      case_detail: [''],
      sibling_student_id:[''],
     // address_details:this.formBuilder.group({
        address: [''],
        zip: [''],
        country: [''],
        state: [''],
        city: [''],
        same_permanent: [''],
        permanent_address: [''],
        permanent_zip: [''],
        permanent_country: [''],
        permanent_state: [''],
        permanent_city: [''],
      // }),
      // family_details:this.formBuilder.group({
        family_type: [''],
        father_name: [''],
        father_occupation: [''],
        father_mobile: [''],
        father_email: [''],
        father_contact: [''],
        father_income: [''],
        mother_name: [''],
        mother_occupation: [''],
        mother_mobile: [''],
        mother_email: [''],
        mother_contact: [''],
        gaurdian_name: [''],
        gaurdian_relation: [''],
        gaurdian_mobile: [''],
        gaurdian_email: [''],
        gaurdian_contact: [''],
      // }),
      // additional_details:this.formBuilder.group({
      visa_no: [''],
      visa_till_date: [''],
      passport_no: [''],
      visa_country: [''],
      tofel_score: [''],
      ielts: [''],
      facebook: [''],
      twitter: [''],
      skype: [''],
      whatsapp: [''],
      account_no: [''],
      ifsc: [''],
      branch: [''],
      bank: [''],
      swift_code: [''],
      micr_code: [''],
     // }),
     // document_details:this.formBuilder.group({
      fphoto:[''],
      document_type: [''],
      document: [''],
      linktodrive: this.formBuilder.array([this.initLink('','','','','')]),

    });

  }
  

  initLink(name,degree,start_date,end_date,grade) {
   return this.formBuilder.group({
        academic_name: [name],
        academic_degree: [degree],
        academic_start_date: [start_date],
        academic_end_date: [end_date],
        academic_grade: [grade]
      });
     
  }
  

  addLink() {
    const control = <FormArray>this.registerForm.controls['linktodrive'];
      control.push(this.initLink('','','','',''));

  }

  removeLink(i: number) {
    const control = <FormArray>this.registerForm.controls['linktodrive'];
    control.removeAt(i);
  }


  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.duplicateRoll="";
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    let request = this.registerForm.value;
    request.id = this.id;
    request.school_user_id = localStorage.getItem('org_id');
   var postData = JSON.stringify(request);

    const requestData = new FormData();

    

    if(this.studentFile){
      requestData.append('studentphoto', this.studentFile, this.studentFile.name);
     }
     
    if(this.motherFile){
      requestData.append('motherphoto', this.motherFile, this.motherFile.name);
     }
     if(this.fatherFile){
      requestData.append('fatherphoto', this.fatherFile, this.fatherFile.name);
     }

     if(this.gaurdianFile){
      requestData.append('gaurdianphoto', this.gaurdianFile, this.gaurdianFile.name);
     }
     requestData.append("type",'employee-registration');
     requestData.append("postData",postData);
    //request.type = 'student-registration';

    this.api.save('add', requestData).subscribe(
      data => {
        this.apiResponse =data;
        if (data == 'success') {
          if (this.id) {
            this.getItemById(this.id);
            Swal.fire('', 'Updated successfully','success');

          }else{
            //this.registerForm.reset();
            //this.success = "Student registration created successfully";
            Swal.fire('', 'Employee registration created successfully','success');

          }
          setTimeout(() => {
            this.success = null;
          }, 5000);
        }
      }, err => {
        this.response = false;
        if(err.error.roll_no){
          this.duplicateRoll= "The roll no has already been taken.";
          Swal.fire('Oops...',this.duplicateRoll, 'error')


        }else{
        //this.error = "Server error found";
        Swal.fire('Oops...','Server error found', 'error')

        }

      }

    )

  }

  getLists() {
    this.api.post('list/feesAssign', { type: 'feesAssign', id: localStorage.getItem('org_id') }).subscribe(
      data => {
        this.response = false;
        this.items = data;
        this.items = this.items.response;
      },
      err => {
        this.response = false;
      },
      () => console.log('')
    )
  };






  getCountry() {
    this.api.post('list', {type:"country"}).subscribe(
      data => {
        this.countries = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  
  getState() {
    this.api.post('list', {type:"state"}).subscribe(
      data => {
        this.states = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  onChange(country_d) {
    let newStates = [];
    this.states.forEach(function (value) {
      if(value.country_id==country_d){
        newStates.push(value);
      }
  });
  this.filterStates = newStates;
  
  this.countries.forEach(element => {
    if(element.id==country_d){
  }


  });

}


getFieldByType(){
  this.api.post('list/employee-field-by-type',{type:'employee-field-by-type',id:localStorage.getItem('org_id'),grid:true}).subscribe(
    data=>{
      this.fieldTypes = data;
      this.fieldTypes = this.fieldTypes.response;
      this.headings = this.fieldTypes.headings;

      this.religions = this.fieldTypes.religion;
      this.casts = this.fieldTypes.cast;
      this.spoken_languages = this.fieldTypes.spoken_languages;
      this.employment_types = this.fieldTypes.employment_type;
      this.reference_vias = this.fieldTypes.reference_via;
      this.grids = this.fieldTypes.grid;
      this.nationalities = this.fieldTypes.nationality;
      this.blood_groups = this.fieldTypes.blood_group;
      this.marritals = this.fieldTypes.marrital;
      this.lives_withs = this.fieldTypes.lives_with;
      this.handicappeds = this.fieldTypes.handicapped;

      

    },
    err =>{
      this.response = false;
    },
    ()=>console.log('loading completed')
  )
};


getArrayControls() {
    return <FormArray>this.registerForm.controls['linktodrive'];
}
getItemById(id) {
  let send = { type: 'employee', id: id, school_user_id: localStorage.getItem('org_id')};
  this.api.get(send).subscribe(
    data => {

      this.item = data;
     
      this.item = this.item;
      //debugger;
      
      this.academics = this.item.academics;

      let family = this.item.other ? JSON.parse(this.item.other.family) : null;
      let additional = this.item.other ? JSON.parse(this.item.other.additional) : null;
      this.document = this.item.other ? JSON.parse(this.item.other.photos) : '';

      let subject_ids = [];
      if(this.item.student_subjects){

      this.item.student_subjects.forEach(element => 
        subject_ids.push(element.subject_id.toString())
      );
    }


      this.registerForm = this.formBuilder.group({
        department: [this.item.designation, Validators.required],
        designation: [this.item.designation, [Validators.required]],
        role: [this.item.role, Validators.required],
        grade: [this.item.grade, Validators.required],
        enrollment: [this.item.enrollment, Validators.required],
        reference: [this.item.reference],
        pan: [this.item.pan],
        aadhaar: [this.item.aadhaar],
        esi: [this.item.esi],
        pf: [this.item.pf],
        employment_code: [this.item.employment_code],
        employee_code: [this.item.employee_code],
        first_name: [this.item.detail ? this.item.detail.first_name: '', Validators.required],
        middle_name: [this.item.detail ? this.item.detail.middle_name : ''],
        last_name: [this.item.detail ? this.item.detail.last_name : '',Validators.required],
        uid: [this.item.detail ? this.item.detail.uid : ''],
        doa: [this.item.detail ? this.item.detail.uid : ''],
        dob: [this.item.detail ? this.item.detail.dob : ''],
        birth_place: [this.item.detail ? this.item.detail.birth_place : ''],
        religion_id: [this.item.detail ? this.item.detail.religion_id : ''],
        cast_id: [this.item.detail ? this.item.detail.cast_id :''],
        contact_no: [this.item.detail ? this.item.detail.contact_no :''],
        email: [this.item.detail ? this.item.detail.email : ''],
        blood_group: [this.item.detail ? this.item.detail.blood_group: ''],
        language: [this.item.detail && this.item.detail.language ? this.item.detail.language.split(',') : null],
        marrital: [this.item.detail ? this.item.detail.marrital : ''],
        handicapped: [this.item.detail ? this.item.detail.handicapped : ''],
        nationality: [this.item.detail ? this.item.detail.nationality : ''],
        domicile_state: [this.item.detail ? this.item.detail.domicile_state : ''],
        physical_fitness: [this.item.detail? this.item.detail.physical_fitness: ''],
        case_file: [this.item.detail && this.item.detail.case_file ? this.item.detail.case_file : 'no'],
        case_detail: [this.item.detail ? this.item.detail.case_detail : ''],
        sibling_student_id: [this.item.detail ? this.item.detail.sibling_student_id : ''],
        address: [this.item.address ? this.item.address.address : ''],
        zip: [this.item.address ? this.item.address.zip : ''],
        country: [this.item.address ? this.item.address.country : ''],
        state: [this.item.address ? this.item.address.state : ''],
        city: [this.item.address ? this.item.address.city : ''],
        same_permanent: [this.item.address ? this.item.address.same_permanent : ''],
        permanent_address: [this.item.address ? this.item.address.permanent_address : ''],
        permanent_zip: [this.item.address ? this.item.address.permanent_zip : ''],
        permanent_country: [this.item.address ? this.item.address.permanent_country : ''],
        permanent_state: [this.item.address ? this.item.address.permanent_state : ''],
        permanent_city: [this.item.address ? this.item.address.permanent_city : ''],
        family_type: [family ? family.family_type : ''],
        father_name: [family ? family.father_name : ''],
        father_occupation: [family ? family.father_occupation : ''],
        father_mobile: [family ? family.father_mobile : ''],
        father_email: [family ? family.father_email : ''],
        father_contact: [family ? family.father_mobile : ''],
        father_income: [family ? family.father_income : ''],
        mother_name: [family ? family.mother_name : ''],
        mother_occupation: [family ? family.mother_occupation : ''],
        mother_mobile: [family ? family.mother_mobile : ''],
        mother_email: [family ? family.mother_email : ''],
        mother_contact: [family ? family.mother_contact : ''],
        gaurdian_name: [family ? family.gaurdian_name : ''],
        gaurdian_relation: [family ? family.gaurdian_relation : ''],
        gaurdian_mobile: [family ? family.gaurdian_mobile : ''],
        gaurdian_email: [family ? family.gaurdian_email : ''],
        gaurdian_contact: [family ? family.gaurdian_contact : ''],
        visa_no: [additional ? additional.visa_no : ''],
        visa_till_date: [additional ? additional.visa_till_date: ''],
        passport_no: [additional ? additional.passport_no : ''],
        visa_country: [additional ? additional.visa_country : ''],
        tofel_score: [additional ? additional.tofel_score : ''],
        ielts: [additional ? additional.ielts : ''],
        facebook: [additional ? additional.facebook : ''],
        twitter: [additional ? additional.twitter : ''],
        skype: [additional ? additional.skype : ''],
        whatsapp: [additional ? additional.whatsapp : ''],
        account_no: [additional ? additional.account_no : ''],
        ifsc: [additional ? additional.ifsc : ''],
        branch: [additional ? additional.branch : ''],
        bank: [additional ? additional.bank : ''],
        swift_code: [additional ? additional.swift_code : ''],
        micr_code: [additional ? additional.micr_code : ''],
        document_type: [additional ? additional.document_type : ''],
        document: [additional ? additional.document : ''],
        linktodrive: this.formBuilder.array([this.initLink('','','','','')])
  
      })
      
      //remove default first academic
      const control1 = <FormArray>this.registerForm.controls['linktodrive'];
      control1.removeAt(0);
      //remove default first academic end

      const control = <FormArray>this.registerForm.controls['linktodrive'];
      this.academics.forEach(x => {
        control.push(this.initLink(x.name,x.degree,x.start_date,x.end_date,x.grade));

      })
      setTimeout(()=>{
        this.onChange(this.item.address ? this.item.address.country : '');
      },500)
    },
    err => {
      console.error(err);
    },
    () => console.log('')
  )

}


studentPhoto(event) {
  this.studentFile = event.target.files[0];
}

motherPhoto(event) {
  this.motherFile = event.target.files[0];
}
fatherPhoto(event) {
  this.fatherFile = event.target.files[0];
}
gaurdianPhoto(event) {
  this.gaurdianFile = event.target.files[0];
}


getClassDataByName(class_id){
  this.api.post('list/class-databy-name',
  {type:'data-by-classid',
  classes:'class',
  subjectByCategory:'yes',
  school_user_id:localStorage.getItem('org_id'),
  school_class_id:class_id}).subscribe(
    data=>{
      this.items = data;
      this.concessions = this.items.response.concession;
      this.subjects= this.items.response.subjects;
      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh');
      });
    },
    err =>{
      this.response = false;
    },
    ()=>console.log('loading completed')
  )
};

getDepartDesig(){
  this.api.post('list/list',{type:'assigned-designation',school_user_id:localStorage.getItem('org_id')}).subscribe(
    data=>{
      this.response = false;
      this.departments = data;
      this.departments = this.departments.response;
      // debugger;
    },
    err =>{
      this.response = false;
    },
    ()=>console.log('loading completed')
  )
};

clickDepartment(){

  let id = <FormArray>this.registerForm.controls['department'].value;
 let d;
  this.departments.forEach(function (value) {
    if(value.record_id==id){
      d = value.designation;
    }
});
    this.designations = d;

}



}
