import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LeadComponent } from './lead.component';
import { AddAttendanceComponent } from './lead/add-attendance/add-attendance.component';
import { AttendanceListComponent } from './lead/attendance-list/attendance-list.component';
import { OutAttendanceComponent } from './lead/out-attendance/out-attendance.component';

const routes: Routes = [
  { path: 'attendance-list', component: AttendanceListComponent },
  { path: 'add-attendance', component: AddAttendanceComponent },
  { path: 'out-attendance', component: OutAttendanceComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadRoutingModule { }
