import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../api.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-session-add',
  templateUrl: './session-add.component.html',
  styleUrls: ['./session-add.component.css']
})
export class SessionAddComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  id: number;
  item;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];

    if (this.id) {
      this.getItemById(this.id);
    }
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      start_date: ['2020-03-18', Validators.required],
      end_date: ['', [Validators.required]],
      status: ['1']
    });
    setTimeout(()=>{
    }, 3000)
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.response = true;
    let request = this.registerForm.value;
    request.type = 'session';
    request.id = this.id;
    request.school_user_id = localStorage.getItem('school_user_id');
    this.api.save('add', request).subscribe(
      data => {
        if (data.id != undefined) {
          if(this.id){
            this.success = "Updated successfully";
          }else{
            this.submitted = false;
            this.registerForm.reset();
            this.ngOnInit();
            this.success = "New session created successfully";
          }
        }
      }, err => {
        this.response = false;
        this.error = "Server error found";
      },
      ()=>{
        this.response = false;
      }

    )

  }


  getItemById(id) {
    let send = { type: 'session', id: id };
    this.api.get(send).subscribe(
      data => {
        this.item = data;
        this.registerForm = this.formBuilder.group({
          name: [this.item.name, Validators.required],
          start_date: [this.item.start_date, Validators.required],
          end_date: [this.item.end_date, [Validators.required]],
          status: [this.item.status]
        });

      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }

}
