import { Component, OnInit, KeyValueChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'
import * as moment from 'moment';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  id: number;
  item;
  filterStates
  states;
  countries;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getCountry();
    this.getState();
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);
    }


    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: [''],
      mobile: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      address: [''],
      country_id: ['', Validators.required],
      state_id: ['', Validators.required],
      city: [''],
      location: [''],
      contact_person: ['', Validators.required],
      // party_code: ['', Validators.required],
      cin: [''],
      gst: [''],
      gst_catagory: [''],
      pan: [''],
      pf: [''],
      esi: [''],
      website: [''],
      as_on_date: [''],
      to_pay: [''],
      to_receive: [''],
      status: [true],
    });

  }

  get f() { return this.registerForm.controls; }

  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.response = true;
    let request = this.registerForm.value;
    request.type = 'add-party';
    request.school_user_id = localStorage.getItem('org_id');
    request.user_id = localStorage.getItem('user_id');
    request.id = this.id;

    this.api.save('add', request).subscribe(
      data => {
        if (data.id != undefined) {
          if (this.id) {
            Swal.fire('Success', 'Updated successfully', 'success');
          } else {
            this.submitted = false;
            this.registerForm.reset();
            this.ngOnInit();
            Swal.fire('Success', 'Created successfully', 'success');
          }
        }
      }, err => {
        this.response = false;
        Swal.fire('OOps', 'Server error found', 'error');
      }, () => {
        this.response = false;
      }
    )
  }

  getItemById(id) {
    this.response = true;
    let send = { type: 'party', id: id };
    this.api.get(send).subscribe(
      data => {
        this.item = data;
        this.registerForm = this.formBuilder.group({
          name: [this.item.organization_name, Validators.required],
          email: [this.item.email],
          mobile: [this.item.mobile_no, Validators.required],
          address: [this.item.location],
          country_id: [this.item.country_id],
          state_id: [this.item.state_id],
          city: [this.item.city],
          location: [this.item.location],
          contact_person: [this.item.party_name],
          // party_code: [this.item.party_code, Validators.required],
          cin: [this.item.cin_no],
          gst_catagory: [this.item.gst_catagory],
          gst: [this.item.gst_no],
          pan: [this.item.pan],
          pf: [this.item.pf],
          esi: [this.item.esi],
          as_on_date: [moment(this.item.as_on_date).format('YYYY-MM-DD')],
          to_pay: [this.item.to_pay],
          to_receive: [this.item.to_receive],
          website: [this.item.website],
          status: [this.item.status ? true : false]
        });
        this.response = false;



      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  }

  getCountry() {
    this.api.post('list', { type: "country" }).subscribe(
      data => {
        this.countries = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };


  getState() {
    this.api.post('list', { type: "state" }).subscribe(
      data => {
        this.states = data;
        if (this.id) {
          this.onChange(this.item.country_id);
        }
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  onChange(country_id: KeyValueChanges<number, any>) {
    let newStates = [];
    if (!this.states) {
      return false;
    }
    this.states.forEach(function (value) {
      if (value.country_id == country_id) {
        newStates.push(value);
      }
    });
    this.filterStates = newStates;

  }



}
