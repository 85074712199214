import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  response;
  error;
  success: string;
  items;
  id: number;
  isGSTApply;
  session;
  tutionFeeArray = [];

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {




  }

  ngOnInit() {

    this.session = JSON.parse(localStorage.getItem('session'));

    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);

    }

    this.registerForm = this.formBuilder.group({
      name: ["", Validators.required],
      amount: ['', Validators.required],
      mode: ['', Validators.required],
      gst: [''],
      gst_type: [''],
      fee_type_id: ['', Validators.required],
      isGSTApply: [''],
      tutionfeeBox: this.formBuilder.array([])


    });

  }

  modeClick(event) {

    var feeMode = [];
    var value;

    if (event == 1) {
      value = 1;
      console.log(value);
    }

    else if (event == 2) {
      value = this.session.session_years;
    }
    else if (event == 3) {
      value = this.session.session_years / this.session.session_months;
    }

    else if (event == 4) {
      value = this.session.session_months / 3;
    }
    else if (event == 5) {
      value = this.session.session_months;
    }

    for (var i = 0; i < value; i++) feeMode.push(i + 1);
    this.tutionFeeArray = feeMode;

  }



  get phoneForms() {
    return this.registerForm.get('tutionFeeArray') as FormArray
  }

  addGST() {
    this.isGSTApply = this.registerForm.controls['isGSTApply'].value;

  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    //alert(this.status);
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.response = true;

    let request = this.registerForm.value;
    request.type = 'fee';
    request.id = this.id;
    request.school_user_id = localStorage.getItem('org_id');
    this.api.save('add', request).subscribe(
      data => {
        if (data.id != undefined) {
          if(this.id){
            this.success = "Updated successfully";
          }else{
            this.submitted = false;
            this.registerForm.reset();
            this.ngOnInit();
            this.success = "New fee created successfully";
          }
        }
      }, err => {
        this.response = false;
        this.error = "Server error found";
      },
      ()=>{
        this.response = false;
      }
    )

  }


  getItemById(id) {
    let send = { type: 'fee', id: id };
    this.api.get(send).subscribe(
      data => {
        this.items = data;
        this.registerForm = this.formBuilder.group({
          name: [this.items ? this.items.name : '', Validators.required],
          amount: [this.items ? this.items.amount : '', Validators.required],
          mode: [this.items ? this.items.fee_mode_id : '', Validators.required],
          gst: [this.items ? this.items.gst : ''],
          fee_type_id: [this.items ? this.items.fee_type_id : '', Validators.required],
          isGSTApply: [this.items.gst ? true : false],
          gst_type: [this.items ? this.items.gst_type : ''],
          tutionfeeBox: this.formBuilder.array([]),

        });
        this.isGSTApply = this.items.gst ? true : false;

        for(let i=0;i< 2 ;i++){
          var phone = this.formBuilder.group({ 
            to: [this.items && this.items.class.sections[i] ? this.items.class.sections[i].section : '', Validators.required],
            deposit_date: [this.items && this.items.class.sections[i] ? this.items.class.sections[i].student : 0, Validators.required],
            amount: [this.items && this.items.class.sections[i] ? this.items.class.sections[i].student : 0, Validators.required],
          })
          this.tutionFeeArray.push(phone);
        }

      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }


}
