import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ApiService } from './../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  response;
  apiResponse;
  error;
  fieldTypes;
  items: any = {};
  item:any = {};
  success: string;
  batches = [];
  classes = [];
  sections = [];
  fee;
  sessions;
  grids = [];
  fileData;
  id:number;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route:ActivatedRoute) { }

  ngOnInit() {
    this.getLists();
    this.getSessionLists();
    this.getFiledByKeys();
    this.getRegistrationFeeId(33);

    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);
    }




    this.registerForm = this.formBuilder.group({
      session: ["", Validators.required],
      mode: ["", Validators.required],
      name: ["", Validators.required],
      father_name: ["", Validators.required],
      mother_name: ["", Validators.required],
      gender: ["", Validators.required],
      contact_number: ["", Validators.required],
      address: ["", Validators.required],
      batch_id: ["", Validators.required],
      medium: ["", Validators.required],
      class_id: ['', Validators.required],
      section_id: ['', Validators.required],
      amount: ['', Validators.required],
      cheque_no: [''],
      check_clear_date: [''],
      bank_name: [''],
      transaction_id: [''],
    });

  }


get f() { return this.registerForm.controls; }

onSubmit() {

  this.submitted = true;
  if (this.registerForm.invalid) {
    return;
  }

  let request = this.registerForm.value;
  request.school_user_id = localStorage.getItem('org_id');
  request.type = "student-admission-registration";
  request.id = this.id;

  this.api.save('add', request).subscribe(
    data => {
      this.apiResponse =data;

      if (data == 'success') {
        if (this.id) {
          this.getItemById(this.id);
          Swal.fire('', 'Updated successfully','success');

        }else{
          Swal.fire('', 'Student registration created successfully','success');

        }

      }

    }, err => {
      this.response = false;
      // /this.error = "Server error found";
      Swal.fire('Oops...',"Server error found", 'error')


    }

  )

}

getItemById(id) {
  let send = { type: 'student-admission-registration', id: id, school_user_id: localStorage.getItem('org_id')};
  this.api.get(send).subscribe(
    data => {
      this.item = data;
      this.item = this.item;
      this.clickMedium(this.item.medium);

      this.registerForm = this.formBuilder.group({
        session: [this.item.session, Validators.required],
        mode: [this.item.mode, Validators.required],
        name: [this.item.name, Validators.required],
        father_name: [this.item.father_name, Validators.required],
        mother_name: [this.item.mother_name, Validators.required],
        gender: [this.item.gender, Validators.required],
        contact_number: [this.item.contact_number, Validators.required],
        address: [this.item.address, Validators.required],
        batch_id: [this.item.batch_id, Validators.required],
        medium: [this.item.medium, Validators.required],
        class_id: [this.item.school_class_id, Validators.required],
        section_id: [this.item.section_id, Validators.required],
        amount: [this.item.amount, Validators.required],
        cheque_no: [this.item.cheque_no],
        check_clear_date: [this.item.check_clear_date],
        bank_name: [this.item.bank_name],
        transaction_id: [this.item.transaction_id],
      });

    },
    err => {
      console.error(err);
    },
    () => console.log('')
  )

}



getLists() {
  this.api.post('list/feesAssign', { type: 'feesAssign', id: localStorage.getItem('org_id') }).subscribe(
    data => {
      this.response = false;
      this.items = data;
      this.items = this.items.response;
      this.batches = this.items.batches;
    },
    err => {
      this.response = false;
    },
    () => console.log('')
  )
};

clickMedium(m) {
  debugger;
  let medium = m ? m : this.registerForm.controls['medium'].value;
  let arrayClass = [];
  if(this.items.classes){

  this.items.classes.filter(function (itm) {
    if (itm.medium == medium) {
      arrayClass.push(itm);
    }
  });
}
this.classes = arrayClass;

}

clickClass() {
  let class_id = this.registerForm.controls['class_id'].value;
  //this.getClassDataByName(class_id);
  let arraySection = [];
  if(this.items.sections){
  this.items.sections.filter(function (itm) {
    if (itm.school_class_id == class_id) {
      arraySection.push(itm);
    }
  });
}
this.sections = arraySection;

}


getSessionLists(){
  this.api.post('list/session',{type:'school-session',id:localStorage.getItem('org_id')}).subscribe(
    data=>{
      this.response = false;
      this.sessions = data;
      this.sessions = this.sessions.response;
    },
    err =>{
      this.response = false;

      console.error(err);
    },
    ()=>console.log('loading completed')
  )
};

getFiledByKeys() {
  this.response = true;
  let send = { type: 'field-by-keys', keys:['gender'] };
  this.api.post('list/fields',send).subscribe(
    data => {
      this.fieldTypes = data;
      this.fieldTypes = this.fieldTypes.response;
    },
    err => {
      console.error(err);
    },
    () => console.log('loading completed')
  )

}


getRegistrationFeeId(id) {
  this.response = true;
  let send = { type: 'fee', id: id };
  this.api.get(send).subscribe(
    data => {
      this.fee = data;
      this.fee = this.fee.amount;

    },
    err => {
      console.error(err);
    },
    () => console.log('loading completed')
  )

}

}
