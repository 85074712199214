import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import Swal from 'sweetalert2';
import { ConfirmationDialogService } from '../../../modal/confirmation-dialog.service';

@Component({
  selector: 'app-list-penalty',
  templateUrl: './list-penalty.component.html',
  styleUrls: ['./list-penalty.component.css']
})
export class ListPenaltyComponent implements OnInit {
  pageOfItems: any
  response: boolean;
  error: string;

  constructor(private api: ApiService, private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {

    this.penaltylist()

  }



  penaltylist() {
    this.api.post('penalty-list', { 'school_user_id': 1 }).subscribe(
      data => {

        this.pageOfItems = data.result.list

        console.log('hello', this.pageOfItems)
      }

    );

  }

  deleteItem(id) {

    this.api.post('delete-penalty', { penalty_id: id }).subscribe(
      data => {
        if (data.code == 200) {
          Swal.fire('Success', 'Item deleted successfully', 'success');
          this.penaltylist();
        }

      }, err => {
        this.response = false;
        this.error = "Server error found";
        Swal.fire('Error', this.error, 'warning');

      }, () => {
        this.response = false;
      }

    )

  }


  public openConfirmationDialog(id, type) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
      .then((confirmed) => {
        console.log('User confirmed:', confirmed)
        if (confirmed) {
          this.deleteItem(id,);
        }

      })
  }

}
