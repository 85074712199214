import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RealstateRoutingModule } from './realstate-routing.module';
import { RealstateComponent } from './realstate.component';
import { PropertyEvaluationComponent } from './property-evaluation/property-evaluation.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddPropertyEvaluationComponent } from './add-property-evaluation/add-property-evaluation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Select2Module } from 'ng2-select2';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { MatButtonModule, MatInputModule, MatListModule, MatStepperModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgBootstrapDatetimeAngularModule } from 'ng-bootstrap-datetime-angular';
import { ProjectPaymentListComponent } from './project-payment/project-payment-list/project-payment-list.component';
import { AadPrjectPaymentComponent } from './project-payment/aad-prject-payment/aad-prject-payment.component';
import { PaymentReportListingComponent } from './payment-report-listing/payment-report-listing.component';
import { ChequeReportComponent } from './cheque-report/cheque-report.component';
import { ListComponent } from './level/list/list.component';
import { CreateComponent } from './level/create/create.component';
import { MemberTreeComponent } from './member-tree/member-tree.component';
import { ChangeChequeStatusComponent } from 'src/app/modal/change-cheque-status/change-cheque-status.component';



@NgModule({
  declarations: [
    RealstateComponent,
    PropertyEvaluationComponent,
    AddPropertyEvaluationComponent,
    ProjectPaymentListComponent,
    AadPrjectPaymentComponent,
    PaymentReportListingComponent, ChequeReportComponent, ListComponent, CreateComponent, MemberTreeComponent,
    ChangeChequeStatusComponent
  ],
  imports: [
    CommonModule,
    RealstateRoutingModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    Select2Module,
    FilterPipeModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    NgxPaginationModule,
    NgBootstrapDatetimeAngularModule,
  ],
})
export class RealstateModule { }
