import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  response;
  error='';
  success: string;
  items: any = {};
  id: number;
  isGSTApply;
  medium:any;
  classType:any;
  sectionType:any;
  feetype:any;
  fee_type:any;
  fee_records:any;
  finetype:any;
  batches;
  classes = [];
  sections = [];
  feeTypes: any = [];
  fineTypes: any = [];
  selectedSection;
  selectedFeeType;
  selectedFineType
  hideme=[];

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getLists();
    this.fee_records = [];
    this.error='';

    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);

    }
    this.selectedFeeType = ''
    this.selectedFineType =''
     
    this.registerForm = this.formBuilder.group({
      batch: ["", Validators.required],
      class_id: [[], [Validators.required]],
      medium: ['', [Validators.required]],
      section: ['', Validators.required],
      fee_type: ['', [Validators.required]],
      fine_type: ['']
    });


   


  }
  showDates(i) {
    this.hideme[i]= !this.hideme[i];
  }
  addGST() {
    this.isGSTApply = this.registerForm.controls['isGSTApply'].value;

  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }


  clickMedium() {
    let medium = this.registerForm.controls['medium'].value;
    let arrayClass = [];
    this.items.classes.filter(function (itm) {
      if (itm.medium == medium) {
        arrayClass.push(itm);
      }
    });
    this.classes = arrayClass;

  }

  clickClass() {
    let class_id = this.registerForm.controls['class_id'].value;
    let arraySection = [];
    this.items.sections.filter(function (itm) {
      if (itm.school_class_id == class_id) {
        arraySection.push(itm);
      }
    });
    this.sections = arraySection;
    setTimeout(() => {
      $('.selectpicker').selectpicker('refresh');
    });

  }

  clickFeeType(e) {
    const MAIN = this
    var date_slap = {
      1:{
        record:1,
        months:0
      },
      2:{
        record:1,
        months:0
      },
      3:{
        record:2,
        months:6
      },
      4:{
        record:4,
        months:4
      },
      5:{
        record:12,
        months:1
      }
    };
    this.fee_records = [];
    MAIN.feeTypes.forEach((ft)=>{
      ft.fees.forEach(async (f)=>{
        if(MAIN.fee_type.includes(f.id.toString())){
          let dates = []
          var selected_class = await MAIN.items.classes.find(x=> x.id == this.registerForm.controls['class_id'].value);
          console.log(selected_class, selected_class.program_start_date)
          var start_date = moment(selected_class.program_start_date);

          for (let i = 0; i < date_slap[f.fee_mode_id].record; i++){
            dates.push({
                amount:f.amount,
                date: start_date.format('YYYY-MM-DD')
              });
            start_date = moment(start_date).add(date_slap[f.fee_mode_id].months,'months');
          }
          MAIN.fee_records.push({
            'id':f.id,
            'ft_name':ft.name,
            'amount':f.amount,
            'mode':f.fee_mode.name,
            'name':f.name,
            'dates':dates
          })
        }
      })
    })

    setTimeout(() => {
      this.selectedFeeType = $(".fee-type button").attr("title");
    })
  }
  
  updateDateAmount(fee_record_index, date_index, type, value) {
    var MAIN = this;
    var date = MAIN.fee_records[fee_record_index].dates[date_index];
    if(type == "D"){
      date['date'] = value
    }else if(type == "A"){
      date['amount'] = value
    }
    MAIN.fee_records[fee_record_index].dates[date_index] = date
  }

  clickFineType() {
    setTimeout(() => {
      this.selectedFineType = $(".fine-type button").attr("title");
    })
  }


  onSubmit() {
    //alert(this.status);
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.response = true;
    let request = this.registerForm.value;
    request.type = 'fee-assign';
    request.id = this.id;
    request.fee_records = this.fee_records;
    request.school_user_id = localStorage.getItem('org_id');
    this.api.save('add', request).subscribe(
      data => {
        if (data.id != undefined) {
          if(this.id){
            this.success = "Updated successfully";
          }else{
            this.submitted = false;
            this.registerForm.reset();
            this.ngOnInit();
            this.success = "New fine created successfully";
          }
        }
      }, err => {
        this.response = false;
        this.error = "Server error found";
      },()=>{
        this.response = false;
      }

    )

  }


  getItemById(id) {
    let send = { type: 'fee-assign', id: id };
    this.api.get(send).subscribe(
      async (data) => {
        this.items = data;
        var school_section_id = [];
        var fine_id = [];

        await this.items.sections.forEach((s)=>{
          school_section_id.push(s.school_section_id)
        })
        await this.items.fines.forEach((fi)=>{
          fine_id.push(fi.fine_id)
        })
        console.log(fine_id, school_section_id);

        this.registerForm = this.formBuilder.group({
          batch: [this.items.batch_id, Validators.required],
          class_id: [this.items.school_class_id, Validators.required],
          medium: [this.items.medium, [Validators.required]],
          section: [school_section_id, Validators.required],
          fine_type: [fine_id]
          // fee_type: [this.items.fee_type_id.split(','), [Validators.required]],
        });

      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }



  getLists() {
    this.api.post('list/feesAssign', { type: 'feesAssign', id: localStorage.getItem('org_id') }).subscribe(
      data => {
        this.response = false;
        this.items = data;
        this.items = this.items.response;
        this.batches = this.items.batches;
        if(this.id) {
          this.classes = this.items.classes;
          this.sections = this.items.sections;  
          this.clickMedium()
          this.clickClass()
        }
  

        this.feeTypes = this.items.fee_types;
        this.fineTypes = this.items.fine_types;
        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        });

      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };


  


}
