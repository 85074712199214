import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'
declare var $: any;


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  id: number;
  item;
  records;
  classes=[];
  subjects=[];
  employees=[];


  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.getFieldType();
    if (this.id) {
      this.getItemById(this.id);

    }

    this.registerForm = this.formBuilder.group({
      class_id: ['', Validators.required],
      subject_id: ['',Validators.required],
      employee_id: ['',Validators.required],
      status: [true],
    });
  }

  get f() { return this.registerForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    let request = this.registerForm.value;
    request.type = 'add-assign-class-subject';
    request.school_user_id = localStorage.getItem('org_id');
    request.id = this.id;

    this.api.save('add', request).subscribe(
      data => {
        if (data == 'success') {
          if (this.id) {
            Swal.fire('Success', 'Updated successfully', 'success');

          } else {
            this.registerForm.reset();
            Swal.fire('Success', 'Created successfully', 'success');
          }
        }
      }, err => {
        this.response = false;
        //this.error = "Server error found";
        if(err.error=='duplicate'){
          Swal.fire('Oops', 'This class already assigned to this achool', 'error');

        }


      }

    )

  }

  getItemById(id) {
    let send = { type: 'assign-teacher-class-subject', id: id };
    this.api.get(send).subscribe(
      data => {
        this.item = data;
        this.registerForm = this.formBuilder.group({
          class_id: [this.item.class_id.split(","), Validators.required],
          subject_id: [this.item.subject_id.split(","),Validators.required],
          employee_id: [this.item.employee_id,Validators.required],
          status: [this.item.status ? true : false],
        });
        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        });
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }


  getFieldType() {
    this.api.post('list/group', { type: 'add-assign-teacher-class-subject','school_user_id':localStorage.getItem('org_id')}).subscribe(
      data => {
        this.response = false;
        this.records = data;
        this.records = this.records.response;
        this.classes = this.records.class;
        this.employees = this.records.employees;
        this.subjects = this.records.subject;

        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        });
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };


}
