import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'


declare var $: any;
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  id: number;
  item;
  fieldTypes;
  departments = [];
  designations = [];

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getdepartmentAndDesignation();
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);

    }


    this.registerForm = this.formBuilder.group({
      department_id: ['', Validators.required],
      status: [''],
      designation_id: ['', Validators.required]
    });

  }

  get f() { return this.registerForm.controls; }

  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    let request = this.registerForm.value;
    request.type = 'assign-designation';
    request.school_user_id = localStorage.getItem('org_id');
    request.id = this.id;

    this.api.save('add', request).subscribe(
      data => {
        if (data == 'success') {
          if (this.id) {
            Swal.fire('Success', 'Updated successfully', 'success');

          }
          else {
            this.registerForm.reset();
            Swal.fire('Success', 'Created successfully', 'success');
          }
        } else if(data == 'duplicate'){
          this.registerForm.reset();
          Swal.fire('OOps', 'Duplicate entry for this department please edit for assign designation', 'error');
        }
      }, err => {
        this.response = false;
        if (err.error== 'duplicate') {
          Swal.fire('OOps', 'Duplicate entry for this department please edit for assign designation', 'error');
        } else {
          Swal.fire('OOps', 'Server error found', 'error');

        }


      }

    )

  }

  getItemById(id) {
    this.response = true;
    let send = { type: 'assigned-designation', id: id };
    this.api.get(send).subscribe(
      data => {
        this.item = data;
        debugger;
        this.registerForm = this.formBuilder.group({
          department_id: [this.item.department_id, Validators.required],
          status: [this.item.status],
          designation_id: [this.item  && this.item.designation_id ? this.item.designation_id.split(",") : null, Validators.required]
        });
        this.response = false;



      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }


  getdepartmentAndDesignation() {
    this.api.post('list/get', { type: 'department-designation', school_user_id: localStorage.getItem('org_id') }).subscribe(
      data => {
        this.fieldTypes = data;
        this.fieldTypes = this.fieldTypes.response;
        this.designations = this.fieldTypes.designations;
        this.departments = this.fieldTypes.departments;
        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        });
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };

}
