import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../..///api.service';
import { ConfirmationDialogService } from '../../../modal/confirmation-dialog.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-new-fee-list',
  templateUrl: './new-fee-list.component.html',
  styleUrls: ['./new-fee-list.component.css']
})

export class NewFeeListComponent implements OnInit {
  items: any =  [];
  response;
  type;
  hideme= [];
  hidemeDetails= [];
  hidemeExtra= [];
  private sub: any;
  constructor(private api:ApiService, private route:ActivatedRoute, private confirmationDialogService: ConfirmationDialogService) { 
   
  }

  ngOnInit() {
    this. getSchool();
  }

  deleteRecord(id){
    const Toast = Swal.mixin({
      input: 'textarea',
      inputPlaceholder: 'Reason...',
      inputAttributes: {
        'aria-label': 'Reason'
      },
      showCancelButton: true
    })
    Toast.fire('Please confirm...','Do you really want to delete... ?','info').then((result)=>{
      if (result.isConfirmed) {
        this.response=true;
        let item = {id:id, reason:result.value};
        this.api.delete('student/fee/delete', item).subscribe(
          (data)=>{
            if(data.code == 200){
              Swal.fire('Deleted!','Record deleted successfully.','success')
              this.ngOnInit()
            }else{

            }
            this.response=false;
          },err=>{
            this.response = false;
            console.log(err);
          }, ()=>{
            this.response = false;
          }
        )
      

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        Swal.fire('Cancelled','Your file record is safe :)','error')

      }
    }) 
  }
  getSchool(){
    let item = {id:localStorage.getItem('user_id'), type:'school'};
    this.response = true;

    this.api.post('student/fee/datatable',item).subscribe(
      (data)=>{
        if(data.code == 200){
          this.items = data.result;
        }
      },
      err =>{
        console.error(err);
      },
      ()=>{
        this.response = false;
      }
    )
  };

  generateReport(id){
    this.response = true;
    this.api.post('student/fee/generate-report', {
      id
    }).subscribe(
      (data)=>{
        if(data.code == 200){
          window.open(data.result.url, '_blank'); 
        }
      },
      err =>{
        this.response = false
        console.error(err);
      },
      ()=>{
        this.response = false;
      }
    )
  }
}

