import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {


  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  country: any;
  state: any;
  pan: any;
  aadhar: any;
  caseFiles: any;
  id: number;
  item;
  fieldTypes;
  countries: any = [];
  states;
  filterStates = [];

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getCountry();
    this.getState();

    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      //this.getState();
      this.getItemById(this.id);
    }
    this.id = this.route.snapshot.params['id'];

    this.registerForm = this.formBuilder.group({
      type_id: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      employee: ['', Validators.required],
      employer: ['', Validators.required],
      status: [true],
    });

  }

  get f() { return this.registerForm.controls; }

  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.response = true;
    let request = this.registerForm.value;
    request.type = 'pf-esi';
    request.school_user_id = localStorage.getItem('org_id');
    request.id = this.id;

    this.api.save('add', request).subscribe(
      data => {
         
        if (data.id != undefined) {
          if(this.id){
            Swal.fire('Success', 'Updated successfully', 'success');
          }else{
            this.submitted = false;
            this.registerForm.reset();
            this.ngOnInit();
            Swal.fire('Success', 'Created successfully', 'success');
          }
        }
      }, err => {
        if (err == 'duplicate') {
          Swal.fire('OOps', 'Duplicate entry for this department please edit for assign designation', 'error');
        } else {
          Swal.fire('OOps', 'Server error found', 'error');
        }
      },()=>{
        this.response = false;
      }

    )

  }

  getItemById(id) {
    let send = { type: 'pf-esi', id: id };
    this.api.get(send).subscribe(
      data => {
        this.item = data;
        this.registerForm = this.formBuilder.group({
          type_id: [this.item.type, Validators.required],
          country: [this.item.country_id, Validators.required],
          state:   [this.item.state_id, Validators.required],
          employee:[this.item.employee, Validators.required],
          employer: [this.item.employer, Validators.required],
          status: [this.item.status ? true :false],
        });

        setTimeout(() => {
          this.onChange(this.item.country_id);
 
        }, 500);


      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }



  getCountry() {
    this.api.post('list', {type:"country"}).subscribe(
      data => {
        this.countries = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  getState() {
    this.api.post('list', {type:"state"}).subscribe(
      data => {
        this.states = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };





  onChange(country_d) {
    let newStates = [];
    this.states.forEach(function (value) {
      if (value.country_id == country_d) {
        newStates.push(value);
      }
    });
    this.filterStates = newStates;

    this.countries.forEach(element => {
      if (element.id == country_d) {
      }


    });

  }




}
