import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddPropertyEvaluationComponent } from './add-property-evaluation/add-property-evaluation.component';
import { AadPrjectPaymentComponent } from './project-payment/aad-prject-payment/aad-prject-payment.component';
import { ProjectPaymentListComponent } from './project-payment/project-payment-list/project-payment-list.component';
import { PropertyEvaluationComponent } from './property-evaluation/property-evaluation.component';

import { RealstateComponent } from './realstate.component';
import { PaymentReportListingComponent } from './payment-report-listing/payment-report-listing.component'
import { ChequeReportComponent } from './cheque-report/cheque-report.component';
import { ListComponent } from './level/list/list.component';
import { CreateComponent } from './level/create/create.component';
import { MemberTreeComponent } from './member-tree/member-tree.component';
import { CustomerComissionComponent } from '../customer-comission/customer-comission.component';


const routes: Routes = [{ path: '', component: RealstateComponent },
{ path: 'property_evaluation', component: PropertyEvaluationComponent },
{ path: 'add_property_evaluation', component: AddPropertyEvaluationComponent },
{ path: 'project-payment/project-payment-list', component: ProjectPaymentListComponent },
{ path: 'project-payment/add-project-payment', component: AadPrjectPaymentComponent },
{ path: 'payment-report-list', component: PaymentReportListingComponent },
{ path: 'cheque-report-list', component: ChequeReportComponent },
{ path: 'level/list', component: ListComponent },
{ path: 'level/create', component: CreateComponent },
{ path: 'member-list', component: MemberTreeComponent },

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RealstateRoutingModule { }
