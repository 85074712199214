import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ApiService } from '../../../../api.service';

@Component({
  selector: 'app-addcharges',
  templateUrl: './addcharges.component.html',
  styleUrls: ['./addcharges.component.css']
})
export class AddchargesComponent implements OnInit {
  chargesForm: FormGroup;
  submitted = false
  id;
  error;
  response = false;
  item;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) { }

  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('chargeid')
    console.log('charge-id', this.id);
    this.chargesForm = this.formBuilder.group({
      charges_condition: ['', Validators.required],
      charges_head: ['', Validators.required],
      type: ['', Validators.required],
      // basis: ['', Validators.required],
      amount: ['', Validators.required]

    });

    this.getItemById()

  }

  get p() { return this.chargesForm.controls }

  onSubmit() {

    this.submitted = true;
    if (this.chargesForm.invalid) {
      return;
    }

    let request = this.chargesForm.value;
    request.school_user_id = localStorage.getItem('school_user_id');
    // request.school_user_id = 1;


    this.api.save('add-other-charge', request).subscribe(
      data => {
        if (data.code == 200) {
          if (this.id) {
            Swal.fire('Success', 'Updated successfully', 'success');
          } else {
            this.submitted = false;
            this.chargesForm.reset();
            Swal.fire('Success', 'Created successfully', 'success');
          }
        } else {
          Swal.fire('Error', "Somethibg is wrong", 'warning');

        }
      }, err => {
        this.response = false;
        this.error = "Server error found";
        Swal.fire('Error', this.error, 'warning');

      }, () => {
        this.response = false;
      }

    )

  }


  getItemById() {

    this.api.getItem('other-charge-by-id/' + this.id).subscribe(
      data => {
        this.item = data
        this.item = this.item.result

        this.chargesForm = this.formBuilder.group({
          charges_condition: [this.item.charges_condition],
          charges_head: [this.item.charges_head],
          type: [this.item.type],
          // basis: [this.item.basis],
          amount: [this.item.amount]

        });

      }

    )

    console.log('hello', this.item)
  }

  onUpdate() {

    this.submitted = true;
    if (this.chargesForm.invalid) {
      return;
    }

    let request = this.chargesForm.value;
    // request.school_user_id = localStorage.getItem('school_user_id');
    request.school_user_id = 1;

    request.other_charge_id = this.id;


    this.api.save('update-other-charge', request).subscribe(
      data => {
        if (data.code == 200) {

          Swal.fire('Success', 'Updated successfully', 'success');

        }
      }, err => {
        this.response = false;
        this.error = "Server error found";
        Swal.fire('Error', this.error, 'warning');

      }, () => {
        this.response = false;
      }

    )

  }




}



