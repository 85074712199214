import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../..///api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-module-add',
  templateUrl: './module-add.component.html',
  styleUrls: ['./module-add.component.css']
})
export class ModuleAddComponent implements OnInit {
  id:number;
  items;
  success:string='';
  error:string='';
  response = false;
  registerForm: FormGroup;
  submitted = false;
  constructor(private api:ApiService, private formBuilder: FormBuilder, private route:ActivatedRoute) {
   }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      price: ['', Validators.required],
      status: ['1', Validators.required]
  })


  }

  get f() { return this.registerForm.controls; }



submitItem(item){

  if (this.registerForm.invalid) {
    return;
}
console.log(this.registerForm.controls);


  this.success='';
  this.error='';
  this.response = true;
  this.submitted = true;

  let data ={name:this.registerForm.value.name, price:this.registerForm.value.price, status:this.registerForm.value.status};

   
    this.api.addModule(data).subscribe(
      data=>{
        this.response =false;
        if(data=='success'){
          this.success="New item created successfully";
        }

      },
      err =>{
        this.response = false;
        console.log('error getting',err.error);
        this.error = "Server error found";
      },
      ()=>console.log('loading completed')
    )
  }

}
