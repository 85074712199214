import { Component, OnInit,Input  } from '@angular/core';
import { ApiService } from '../../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../modal/confirmation-dialog.service';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-enquiry-log',
  templateUrl: './enquiry-log.component.html',
  styleUrls: ['./enquiry-log.component.css']
})

export class EnquiryLogComponent implements OnInit {
  @Input() enquiry_id;

  items;
  response = true;
  pageOfItems: Array<any>;
  closeResult = '';
  registerForm: FormGroup;


  constructor(private formBuilder: FormBuilder,private api:ApiService,public router:Router,private confirmationDialogService: ConfirmationDialogService, private modalService: NgbModal) { }

  ngOnInit() {
    this.api.isLogged();
    this.getLists(this.enquiry_id);
    // if(this.childProperty){
    //   this.getLists();

    // }
    this.registerForm = this.formBuilder.group({
      remark: ['', Validators.required],
      discussion : ['', Validators.required],
      follow_up_date: ['', Validators.required],
    });
  }


  getLists(id){
    this.response = true;
    this.api.post('follow-up-list',{type:'visitor-enquiry-log',id:localStorage.getItem('org_id'),lead_id:id}).subscribe(
      (data)=>{
        this.response = false;
        this.items = data.result;
      },
      err =>{
        this.response = false;
      },
      ()=>console.log('loading completed')
    )
  };

  onChangePage(pageOfItems) {
    this.pageOfItems = pageOfItems;
  }


  deleteItem(id){
    this.response=true;
    let item = {id:id,type:'followup-lead'};
    this.api.delete(item).subscribe(
      (data)=>{
        this.response=false;
        this.getLists(this.enquiry_id);
      },err=>{
        this.response = false;
        console.log(err);
      }
    )

  };

  public openDiscussionConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);
    }

    })
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  
  onSubmit() {
    // this.response = true;

    //let enquiry_id = this.getEnquiryId(3);
    let request = this.registerForm.value;
    request.follow_up_date = request.follow_up_date.split('T')
    request.follow_up_date = request.follow_up_date[0]+' '+request.follow_up_date[1]+':00';
    // request.type = 'add-enquiry-log';
    // request.school_user_id = localStorage.getItem('org_id');
    // request.user_id = localStorage.getItem('user_id');
    request.lead_id = this.enquiry_id;
    request.entry_date = new Date()
    // request.id = this.id;

    this.api.save('add-follow-up', request).subscribe(
      (data) => {
        this.response = false;
        if (data.code == 200) {
          // if (this.id) {
          //   this.success = "Updated successfully";
          // } else {
            this.registerForm.reset();
            this.ngOnInit();
            Swal.fire('Success', 'Created successfully', 'success');
            // this.success = "New field created successfully";
          // }
        }
      }, err => {
        this.response = false;
      }

    )

  }



}