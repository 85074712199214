import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { SharingServiceService } from 'src/app/services/sharing-service.service';
import Swal from 'sweetalert2';
import { ConfirmationDialogService } from 'src/app/modal/confirmation-dialog.service';


@Component({
  selector: 'app-project-manage',
  templateUrl: './project-manage.component.html',
  styleUrls: ['./project-manage.component.css']
})
export class ProjectManageComponent implements OnInit {
  page = {
    limit: 10,
    count: 0,
    offset: 0,
  };
  public pageLimitOptions = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];
  units = [{ id: 1, title: "ACRE" }, { id: 2, title: "BIGHA" }, { id: 3, title: "BISWA" }, { id: 4, title: "SQ. METER" }, { id: 5, title: "SQ. FEET" }, { id: 6, title: "SQ. YARDS" }]
  constructor(private api: ApiService, private confirmationDialogService: ConfirmationDialogService, private router: Router, private sharingService: SharingServiceService) { }
  ngOnInit() {
    this.getPropertyType();

  }
  response: boolean = false;
  rows: any;
  properties: any[] = [];
  projects: any;
  getPropertyType() {
    this.api.post('organization/list', { type: "field", user_id: localStorage.getItem('user_id') }).subscribe(resp => {
      console.log("dataResponse", resp.response);
      if (resp.response.length > 0) {
        this.properties = resp.response.filter(ele => {
          if (ele.field_type) {
            return ele.field_type.name.toLowerCase() == "property type"
          }
        });
        this.getProperty({ "page_number": 1, "limit": this.page.limit });
      }
    })
  }
  getProperty(extra = {}) {
    this.api.post('project-list', { type: "field", user_id: localStorage.getItem('user_id') }).subscribe(async (resp) => {
      if (resp.result.list.length > 0) {
        //  = resp.result.list;
        let p = extra["limit"];
        let pageNumber = extra["page_number"];
        this.page.count = resp.result.total;
        this.rows = resp.result.list.map((element, index) => {

          // element.projectType = element.project_type.name
          if (element.owners.length) {
            debugger
            element.owners.forEach((projectPayment) => {
              if (element.id == projectPayment.project_id) {
                element.projectReceivedAmount = 0;
                // element.projectReceivedAmount += Number(element.project_payment.amount);
                element.projectDuesAmount = Number(element.final_amount) - Number(element.project_total_received_amount)
              }
            })

          }
          element.no = p * (pageNumber - 1) + index + 1
          return element;
        });
      }
    })
  }


  onDetailToggle(event) { }
  @ViewChild('myTable', { static: true }) table: any;
  sellerDetails: any;

  toggleExpandRow(row) {
    console.log('row.owners', row);
    this.sellerDetails = row.owners.map((owner) => {
      if (row.project_payment && row.project_payment.length) {
        console.log('If', row.project_payment.seller_id)
        row.project_payment.forEach((projectPayment) => {
          if (owner.id == projectPayment.seller_id) {
            owner.sallerPaidAmount = projectPayment.total_received_amount;
            owner.sallerDuesAmount = Number(owner.seller_amount) - Number(projectPayment.total_received_amount);
          }
        })
        // console.log('Condition', owner.id == row.project_payment.seller_id)

      }
      return owner;
    })
    console.log('sallerDetails', this.sellerDetails)
    var _this = this;
    _this.table.rowDetail.toggleExpandRow(row);
  }
  onLimitChange(value) {
    this.page.limit = value;
    this.getProperty({ "page_number": 1, "limit": value });
  }
  setPage(pageInfo) {
    this.getProperty({ "page_number": (pageInfo.offset + 1), "limit": 10 })
  }
  addNewForm() {
    this.sharingService.setData(undefined);
    this.sharingService.formTitleSet("Create New Project")

    this.router.navigateByUrl('/realstate/addprojectmanage');
  }
  editData(data) {
    this.sharingService.setData(data);
    this.sharingService.formTitleSet("Edit Project")
    this.router.navigateByUrl('/realstate/addprojectmanage');
  }

  deleteData(id, reason) {
    this.response = true;
    let item = { id: id, reason: reason };
    this.api.deleteData('delete-project', item).subscribe(
      (resp) => {
        Swal.fire('Success', 'Project deleted successfully!', 'success');
        this.ngOnInit()
        this.response = false;
      }, err => {
        this.response = false;
        console.log(err);
      }
    )

  };
  openConfirmationDialog(data) {
    const Toast = Swal.mixin({
      input: 'textarea',
      inputPlaceholder: 'Reason...',
      inputAttributes: {
        'aria-label': 'Reason'
      },
      showCancelButton: true
    })
    Toast.fire('Please confirm...', 'Do you really want to delete... ?', 'info').then((result) => {
      if (result.isConfirmed) {
        this.deleteData(data.id, result.value);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your file record is safe :', 'error')
      }
    })
  }
}
