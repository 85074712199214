import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';

declare var $:any;

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  subjects: any = [];
  fine_type_id;
  item;
  id:number;
  butDisabled: boolean = null;
  subject_id;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);
      this.butDisabled = this.id ? true : null;

    }

  }

  ngOnInit() {
    this.getSubjects();
    this.registerForm = this.formBuilder.group({
      fine_type_id: ['', Validators.required],
      perday_amount: [''],
      monthly_amount: [''],
      concession_type: [''],
      concession_name: [''],
      percentage: [''],
      percentage_amount: [''],
      admission_concession: [''],
      tution_concession: [''],
      subject_id: ['']
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.response = true;
    let request = this.registerForm.value;
    request.type = 'fine';
    request.school_user_id = localStorage.getItem('org_id');
    request.id = this.id;
    this.api.save('add', request).subscribe(
      data => {
        if (data.id != undefined) {
          if(this.id){
            this.success = "Updated successfully";
          }else{
            this.submitted = false;
            this.registerForm.reset();
            this.ngOnInit();
            this.success = "New fine created successfully";
          }
        }
      }, err => {
        this.response = false;
        this.error = "Server error found";
      },
      ()=>{
        this.response = false;
      }

    )

  }


  // get subjects
  getSubjects() {
    this.api.post('list/subject', { type: 'subject', id: localStorage.getItem('org_id') }).subscribe(
      data => {
        this.response = false;
        this.subjects = data;
        this.subjects = this.subjects.response;
        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        },2000);
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };

  //get record by id to edit

  getItemById(id) {
    let send = { type: 'fine', id: id };
    this.api.get(send).subscribe(
      data => {
        this.item = data;
        this.fine_type_id = this.item.fine_type_id;
        let subject_ids = this.item.subject_id ? this.item.subject_id.split(',') : null;
        this.registerForm = this.formBuilder.group({
          fine_type_id: [ this.fine_type_id,Validators.required],
          perday_amount: [this.item ? this.item.perday_amount : ''],
          monthly_amount: [this.item ? this.item.monthly_amount : ''],
          concession_type: [this.item ? this.item.concession_type : ''],
          concession_name: [this.item ? this.item.concession_name : ''],
          percentage: [this.item ? this.item.percentage : ''],
          percentage_amount: [this.item ? this.item.percentage_amount : ''],
          admission_concession: [this.item ? this.item.admission_concession : ''],
          tution_concession: [this.item ? this.item.tution_concession : ''],
          subject_id: [this.item ? subject_ids : '']
        });

      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }

  // selectSubject(){
  //   var subjects  = [];
  //   debugger
  //   if(subjects.indexOf(this.subject_id) !== -1){
  //   }else{
  //     subjects.push(this.subject_id);

  //   }

  //   console.log("subjects4=",subjects);
  // }


}
