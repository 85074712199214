import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ApiService } from './../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  response;
  apiResponse;
  error;
  fieldTypes;
  items: any = {};
  item:any = {};
  success: string;
  batches = [];
  classes = [];
  sections = [];
  employment_types = [];
  reference_vias = [];
  grids = [];
  appUrl = '';
  fileData;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route:ActivatedRoute) { }

  ngOnInit() {
    this.appUrl = this.api.appUrl;

    this.getLists();
    this.registerForm = this.formBuilder.group({
      batch_id: ["", Validators.required],
      medium: ["", Validators.required],
      class_id: ['', Validators.required],
      section_id: ['', Validators.required],
      enrollment_type: ['', Validators.required],
      enrollment_via: ['', Validators.required],
      file: ['', Validators.required],
    });

  }


get f() { return this.registerForm.controls; }

onSubmit() {

  this.submitted = true;
  if (this.registerForm.invalid) {
    return;
  }

  this.response = true;
  let request = this.registerForm.value;
  request.school_user_id = localStorage.getItem('org_id');
  request.type = "student";

  var postData = JSON.stringify(request);


  const requestData = new FormData();
  if(this.fileData){
    requestData.append('file_data', this.fileData, this.fileData.name);
   }

   requestData.append('type', "student");
   requestData.append("postData",postData);


  this.api.addWithoutJson('import', requestData).subscribe(
    (data) => {
      this.response = false;
      if(data.code == 200){
        Swal.fire('Success',"Total " + data.total + " data imported successfully", 'success')
        this.submitted = false;
        this.registerForm.reset();
        this.ngOnInit();
      }else if(data.code == 202){
        Swal.fire('Oops...',"Aadhar number already exists", 'error')
      }else{
        Swal.fire('Oops...',"Something went wrong", 'error')
      }

    }, err => {
      this.response = false;
      // /this.error = "Server error found";
      Swal.fire('Oops...',"Server error found", 'error')
    }, ()=>{
      this.response = false;
    }

  )

}

uploadData(event) {
  this.fileData = event.target.files[0];
}


getLists() {
  this.api.post('list/feesAssign', { type: 'feesAssign', id: localStorage.getItem('org_id') }).subscribe(
    data => {
      this.response = false;
      this.items = data;
      this.items = this.items.response;
      this.batches = this.items.batches;
    },
    err => {
      this.response = false;
    },
    () => console.log('')
  )
};

clickMedium() {
  let medium = this.registerForm.controls['medium'].value;
  let arrayClass = [];
  if(this.items.classes){

  this.items.classes.filter(function (itm) {
    if (itm.medium == medium) {
      arrayClass.push(itm);
    }
  });
}
this.classes = arrayClass;

}

clickClass() {
  let class_id = this.registerForm.controls['class_id'].value;
  //this.getClassDataByName(class_id);
  let arraySection = [];
  if(this.items.sections){
  this.items.sections.filter(function (itm) {
    if (itm.school_class_id == class_id) {
      arraySection.push(itm);
    }
  });
}
this.sections = arraySection;

console.log("sections=",this.sections);
}

}
