import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../..///api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {
  id:number;
  items:any;
  success:string='';
  error:string='';
  response = false;
  registerForm: FormGroup;
  submitted = false;
  status:any;
  
  

  constructor(private api:ApiService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
   }

  ngOnInit() {
    this.items = {
      quantity:0,
      price:0,
      status:0
    };
  
    this.id = this.route.snapshot.params['id'];
    if(this.id){
      this.getItemById(this.id);
      console.log("id=",this.id);

    }

  }

  getItemById(id){
    let send = {type:'smsplan',id:id};
    this.api.get(send).subscribe(
      data=>{
        this.items = data;
        if(this.items){
          this.status= true;

        }
        console.log(this.items);
      },
      err =>{
        console.error(err);
      },
      ()=>console.log('loading completed')
    )
  };



  submitItem(request){

    this.success='';
    this.error='';
    this.response = true;
    this.submitted = true;
  
      this.api.add('sms-plan/store',request).subscribe(
        data=>{
          this.response =false;
          if(data=='success'){
            this.success= this.id ? "Item updated successfully" : "New item created successfully";
          }
  
        },
        err =>{
          this.response = false;
          console.log('error getting',err.error);
          this.error = "Server error found";
        },
        ()=>console.log('loading completed')
      )
    }

}
