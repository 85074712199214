import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { SharingServiceService } from 'src/app/services/sharing-service.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-project-payment-list',
  templateUrl: './project-payment-list.component.html',
  styleUrls: ['./project-payment-list.component.css']
}) @Component({
  selector: 'app-project-payment-list',
  templateUrl: './project-payment-list.component.html',
  styleUrls: ['./project-payment-list.component.css']
})
export class ProjectPaymentListComponent implements OnInit {
  page = {
    limit: 10,
    count: 0,
    offset: 0,
  };
  public pageLimitOptions = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];
  units = [{ id: 1, title: "ACRE" }, { id: 2, title: "BIGHA" }, { id: 3, title: "BISWA" }, { id: 4, title: "SQ. METER" }, { id: 5, title: "SQ. FEET" }, { id: 6, title: "SQ. YARDS" }]
  constructor(private api: ApiService, private router: Router, private sharingService: SharingServiceService) { }
  ngOnInit() {
    this.getPaymentList({ "page_number": 1, "limit": 10 })

  }
  response: boolean = false;
  rows: any;
  properties: any;
  projects: any;

  getPaymentList(extra = {}) {
    let filter = { user_id: localStorage.getItem('user_id') };
    filter = { ...filter, ...extra };
    this.response = true;

    this.api.post('payment-list', filter).subscribe(
      data => {
        this.response = false;
        let p = extra["limit"];
        let pageNumber = extra["page_number"];
        this.page.count = data.result.total;
        data.result.list.forEach((element, index) => {
          return element.no = p * (pageNumber - 1) + index + 1;
        })
        this.rows = data.result.list;
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };

  onDetailToggle(event) { }
  // expanded: any = {id: 1, name: 'Dany', gender: 'Male', age: 30 };
  @ViewChild('myTable', { static: true }) table: any;
  sellerDetails: any;
  toggleExpandRow(row, rowIndex, expanded) {
    this.sellerDetails = row.owners;
    var _this = this;
    _this.table.rowDetail.toggleExpandRow(row);
  }
  onLimitChange(value) {
    this.page.limit = value;
    this.getPaymentList({ "page_number": 1, "limit": value });
  }

  setPage(pageInfo) {
    this.getPaymentList({ "page_number": (pageInfo.offset + 1), "limit": 10 });
    // this.serverResultsService.getResults(this.page).subscribe(pagedData => {
    // this.offset=1;
    // this.limit= 10;
    // this.rows = this.rows;
    // });
  }
  filterSearch(value) {

  }
  addNewForm() {
    this.sharingService.setData(undefined);
    this.sharingService.formTitleSet("Create New Project")
    this.router.navigateByUrl('/realstate/project-payment/add-project-payment');
  }
  // editData(data) {
  //   this.sharingService.setData(data);
  //   this.sharingService.formTitleSet("Edit Project")
  //   this.router.navigateByUrl('/realstate/addprojectmanage');
  // }

  deleteData(id, reason) {
    this.response = true;
    let item = { id, reason };
    this.api.deleteData('delete-project-payment', item).subscribe(
      (resp) => {
        Swal.fire('Success', 'Payment deleted successfully!', 'success');
        this.ngOnInit()
        this.response = false;
      }, err => {
        this.response = false;
        console.log(err);
      }
    )
  };
  public openConfirmationDialog(id) {
    const Toast = Swal.mixin({
      input: 'textarea',
      inputPlaceholder: 'Reason...',
      inputAttributes: {
        'aria-label': 'Reason'
      },
      showCancelButton: true
    })
    Toast.fire('Please confirm...', 'Do you really want to delete... ?', 'info').then((result) => {
      if (result.isConfirmed) {
        this.deleteData(id, result.value);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your file record is safe :)', 'error')
      }
    })
  }

}
