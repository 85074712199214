import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parse'
})
export class CustomPipePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let parse = JSON.parse(value);
    return parse;
  }

}
