import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../..///api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-school-edit',
  templateUrl: './school-edit.component.html',
  styleUrls: ['./school-edit.component.css']
})



export class SchoolEditComponent implements OnInit {

  item;
  success: string = '';
  error: string = '';
  response = false;
  registerForm: FormGroup;
  submitted = false;
  modules;
  tempArr: any = [];
  isChecked;
  sessions;
  id: number;
  detail: any;
  module_detail: any = [''];
  countries: any = [];
  states;
  filterStates: any;
  selectedFile: File;
  logo: string;


  constructor(private api: ApiService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    this.item = {};


  }


  ngOnInit() {
    this.getCountry();
    this.getState();

    this.sessions = this.api.sessions;
    this.id = this.route.snapshot.params['id'];
    // if (this.id) {
    //   this.getItemById(this.id);
    // }
    this.getModules();


    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      address: [''],
      city: [''],
      state: ['', Validators.required],
      country: ['', Validators.required],
      pincode: [''],
      currency: ['', Validators.required],
      number: [''],
      website: [''],
      user_name: ['', Validators.required],
      user_mobile: ['', Validators.required],
      user_email: ['', Validators.required],
      question: ['', Validators.required],
      answer: ['', Validators.required]
    })
  }



  get f() { return this.registerForm.controls; }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }

  submitItem(request) {
    let selectedBox = [];
    $('input[type=checkbox]').each(function () {
      if ($(this).is(":checked")) {
        selectedBox.push({ title: $(this).attr('name'), type: $(this).attr('class') });
      }
      console.log(selectedBox);
    });

    if (this.registerForm.invalid) {
      return;
    }

    this.success = '';
    this.error = '';
    this.response = true;
    this.submitted = true;
    request.modules = this.tempArr;

    const requestData = new FormData();
    var postData = JSON.stringify(request);
    if (this.selectedFile) {
      requestData.append('file', this.selectedFile, this.selectedFile.name);
    }
    requestData.append("postData", postData);
    this.api.addSchool(requestData).subscribe(
      data => {
        if (data == 'success') {
          this.response = false;
          this.getItemById(this.id);
          this.success = "Information updated successfully";
        }
      },
      err => {
        this.response = false;
        this.error = "Server error found";
      },
      () => console.log('loading completed')
    )
  }

  onChange(country_d) {
    let newStates = [];
    this.states.forEach(function (value) {
      if (value.country_id == country_d) {
        newStates.push(value);
      }
    });
    this.filterStates = newStates;
    this.countries.forEach(element => {
      if (element.id == country_d) {
        this.item.currency = element.currency;
      }

    });

  }


  getModules() {
    this.response = true;
    this.api.post('list', { type: 'modules' }).subscribe(
      data => {
        this.modules = data;
        console.log('Getting All modules from db', this.modules);
        this.id = this.route.snapshot.params['id'];
        if (this.id) {
          this.getItemById(this.id);
        }
      },
      err => {

      },
      () => {
        this.response = false;
      }
    )
  };
  // getModules(){
  //   this.api.getModules().subscribe(
  //     data=>{
  //       //this.modules = data;
  //     },
  //     err =>{
  //       console.error(err);
  //     },
  //     ()=>console.log('loading completed')
  //   )
  // };

  // onChangeCategory(event, module: any) { // Use appropriate model type instead of any
  //   //console.log(item.module);    // Old Function 
  //   this.tempArr.push(module.title);
  // }


  async onChangeCategory(event, module: any) { // Use appropriate model type instead of any
    console.log('modules', module.id)
    if (event.target.checked == true) {
      this.tempArr.push({ title: module.title, type: module.key, id: module.id });
    } else {
      var index = await this.tempArr.findIndex(item => item.id == module.id);
      this.tempArr.splice(index, 1);
    }
  }

  getState() {
    this.api.post('list', { type: "state" }).subscribe(
      data => {
        this.states = data;
        this.filterStates = data;;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  getCountry() {
    this.api.post('list', { type: "country" }).subscribe(
      data => {
        this.countries = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  getItemById(id) {
    this.response = true;
    let item = { id: id, type: "organization" };
    this.api.post('edit', item).subscribe(
      async (data) => {
        console.log('data', data)
        this.item = data;
        let detail = this.item.user;
        let getmodule = this.item.modules;
        this.item = this.item.user.school_info;
        await data.user.modules.forEach(async (m) => {
          var check = await this.modules.find((master_m) => m.module_id == master_m.id);
          check.checked = true;
          if (!this.tempArr.find((t) => t.id == check.id)) {
            this.tempArr.push({ title: check.title, type: check.key, id: check.id });
          }
        })
        this.logo = this.api.imageUrl + this.item.logo;
        this.item.user_name = detail.name;
        this.item.user_mobile = detail.mobile;
        this.item.user_email = detail.email;
        this.item.user_password = detail.user_password;
        this.item.answer = detail.answer;
        this.item.question = detail.question;
        this.item.tot_school = detail.tot_school;
        this.response = false;

      },
      err => {
        this.response = false;

      },
      () => console.log('loading completed')

    )
  };



}
