import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { ConfirmationDialogService } from 'src/app/modal/confirmation-dialog.service';
import { Select2OptionData } from 'ng2-select2';
import { FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms';
import Swal from 'sweetalert2'
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-new-fee',
  templateUrl: './new-fee.component.html',
  styleUrls: ['./new-fee.component.css']
})

export class NewFeeComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,private api:ApiService,public router:Router,private confirmationDialogService: ConfirmationDialogService) { }
  
  response = false;
  public classList: Array<Select2OptionData>;
  public batchList: Array<Select2OptionData>;
  public studentList: Array<Select2OptionData>;
  public classOptions: Select2Options;
  public studentOptions: Select2Options;
  public batchOptions: Select2Options;
  rolenumber = '';
  ALL_STUDENT:any = [];
  studentFee:any = [];
  selectedFees:any = [];
  final_fee_transport:any = [];
  hideme=[];
  fineHide=[];
  finalAmount = {
    paid:0,
    discount:0,
    amount:0
  };
  submitted = false;
  studentClassFeeFine:any = {};
  registerForm: FormGroup;

  ngOnInit() {
    this.submitted = false;

    this.ALL_STUDENT = [];
    this.studentFee = [];
    this.selectedFees = [];
    this.studentList = [];
    this.batchList = [];
    this.classList = [];
    this.final_fee_transport = [];
    this.hideme = [];
    this.fineHide = [];
    this.finalAmount = {
      paid:0,
      discount:0,
      amount:0
    };
    this.submitted = false;
    this.studentClassFeeFine = {};

    this.registerForm = this.formBuilder.group({
      batch_id: [""],
      medium: ['hindi'],
      class_id: [''],
      student_id: ['', Validators.required],
      payment_mode: ['cash'],
      payment_date: ['', Validators.required]
    });
    this.getBatchList();


    this.batchOptions = this.studentOptions = this.classOptions = {
      width:"100%"
    }
    this.finalAmountDetail();
  }

  selectBatch = (e)=>{
    this.registerForm.get('batch_id').setValue(e.value);
    this.getClassList();
  }

  selectClass = (e)=>{
    var MAIN = this;
    MAIN.rolenumber = '';

    this.api.post('class/student',{class_id:e.value, school_user_id:localStorage.getItem('org_id')}).subscribe(
      async (data)=>{
        MAIN.ALL_STUDENT = data;
        var final_data = []
        await data.forEach((item)=>{
          final_data.push({
            id:item.id,
            text: item.detail ? item.detail.name : '',
            data: item.roll_no
          })
        })
        MAIN.studentList = final_data;
      },
      err =>{},
      ()=>{
        this.response = false;
      }
    )
  }

  addExtraInFee = async (fee_index)=>{
    var MAIN = this;
    this.hideme[fee_index] = true;

    if(MAIN.selectedFees[fee_index]){
      MAIN.selectedFees[fee_index].extra.push({
        name:'',
        amount:0,
        discount:0,
        paid:0
      });
    }
  };
  
  updateExtraDetail = (fee_index, extra_index, type, value)=>{
    var MAIN = this;
    var extra = MAIN.selectedFees[fee_index].extra[extra_index];
    if(type == "N"){
      extra['name'] = value
    }else if(type == "A"){
      extra['amount'] = parseInt(value)
      extra['paid'] = extra['amount'] - extra['discount']
    }else if(type == "D"){
      extra['discount'] = parseInt(value)
      extra['paid'] = extra['amount'] - extra['discount']
    }else if(type == "P"){
      extra['paid'] = parseInt(value)
    }
    this.refreshFeeSectionPrice(fee_index);
  };

  updateFeeTypeDetail = (fee_index, type, value)=>{
    var MAIN = this;
    var fee = MAIN.selectedFees[fee_index];
    if(type == "D"){
      fee.fee_type_discount = parseInt(value)
      fee.fee_type_paid = parseInt(fee.fee_type_amount) - parseInt(fee.fee_type_discount)
    }else if(type == "P"){
      fee.fee_type_paid = parseInt(value)
    }

    this.refreshFeeSectionPrice(fee_index);
  };

  get f() { return this.registerForm.controls; }

  updateFineDetail = (fee_index, fine_index, type, value)=>{
    var MAIN = this;
    var fine = MAIN.selectedFees[fee_index].fines[fine_index];
    if(type == "D"){
      fine['discount'] = value
      fine['paid'] = fine['amount'] - fine['discount']
    }else if(type == "P"){
      fine['paid'] = value
    }
    this.refreshFeeSectionPrice(fee_index);
  };

  refreshFeeSectionPrice = async (fee_index)=>{
    var MAIN = this;
    var fees = MAIN.selectedFees[fee_index];
    fees.totalAmount = parseInt(fees.fee_type_amount);
    fees.totalDiscount = parseInt(fees.fee_type_discount);
    fees.totalPaid = parseInt(fees.fee_type_paid);

    fees.fines_total_amount = 0;
    fees.fines_total_paid = 0;
    fees.fines_total_discount = 0;
    await fees.fines.forEach((fine)=>{
      fees.fines_total_amount += parseInt(fine.amount);
      fees.fines_total_discount += parseInt(fine.discount);
      fees.fines_total_paid += parseInt(fine.paid);
    });

    fees.totalAmount += fees.fines_total_amount;
    fees.totalDiscount += fees.fines_total_discount;
    fees.totalPaid += fees.fines_total_paid;

    await fees.extra.forEach((extra)=>{
      fees.totalAmount += parseInt(extra.amount);
      fees.totalDiscount += parseInt(extra.discount);
      fees.totalPaid += parseInt(extra.paid);
    });

    MAIN.finalAmountDetail()
  }

  removeExtraFromFee = async (fee_index, extra_index)=>{
    this.selectedFees[fee_index].extra.splice(extra_index, 1);
    this.refreshFeeSectionPrice(fee_index);
  };

  diffYMDHMS = (date1, date2) => {
    let years = date1.diff(date2, 'year');
    date2.add(years, 'years');

    let months = date1.diff(date2, 'months');
    date2.add(months, 'months');

    let days = date1.diff(date2, 'days');
    date2.add(days, 'days');

    let hours = date1.diff(date2, 'hours');
    date2.add(hours, 'hours');

    let minutes = date1.diff(date2, 'minutes');
    date2.add(minutes, 'minutes');

    let seconds = date1.diff(date2, 'seconds');

    return { years, months, days, hours, minutes, seconds};
  }

  selectThisFee = async (event:Event, index)=>{
    const isChecked = (<HTMLInputElement>event.target).checked;
    var MAIN = this;
    if(isChecked){
      const record = MAIN.final_fee_transport[index];
      var fines_total_amount = 0;

      var fines = [];
      await MAIN.studentClassFeeFine.fines.forEach((f)=>{
        if(f.fine.fine_type_id == 2 || f.fine.fine_type_id == 3 || f.fine.fine_type_id == 4){
          var amount = f.fine.perday_amount
          var type = [];
          // if(f.fine.fine_type_id == 2){
            var fee_date = MAIN.diffYMDHMS(moment(), moment(record.date))
            amount = 0;
            if(fee_date.months > 0 ){
              amount = f.fine.monthly_amount * fee_date.months; 
              type.push({
                name: "Month",
                amount: f.fine.monthly_amount,
                quantity: fee_date.months
              });
            }
            if(fee_date.days > 0 ){
              amount += f.fine.perday_amount * fee_date.days; 
              type.push({
                name: "Day",
                amount: f.fine.perday_amount,
                quantity: fee_date.days
              });
            }
          // }
          fines.push({
            name:f.fine.fine_type.name,
            fine_id:f.fine.id,
            fine_type_id:f.fine.fine_type_id,
            type:type,
            amount: amount,
            paid: amount,
            discount: 0
          })
          fines_total_amount += parseInt(amount);
        }
      });

      MAIN.selectedFees.push({
        index_id: index,
        amount_id: record.amount_id,
        more_extra: record.more_extra,
        fee_name: record.type == "F" ? record.amount_detail.name : record.name,
        fee_type: record.type == "F" ? record.detail.fee.fee_type.name  : (record.type == "T" ? "Transport" : (record.type == "PR" ? "Due" : "")),
        type: record.type,
        deposite_date:record.date,
        fines:fines,
        extra:[],
        fee_type_amount: record.amount,
        fee_type_discount: 0,
        fee_type_paid: record.amount,
        fines_total_amount:fines_total_amount,
        fines_total_discount:0,
        fines_total_paid:fines_total_amount,
        totalAmount: record.amount,
        totalDiscount:0,
        fineTypeHide:[],
        totalPaid:record.amount
      });
      this.refreshFeeSectionPrice(MAIN.selectedFees.length - 1);
    }else{

      MAIN.selectedFees = MAIN.selectedFees.filter(x=> x.index_id != index);

      this.finalAmountDetail();
    }

  };

  selectStudent = async (e)=>{
    if(e.value){
      this.rolenumber = e.data[0].data
      this.showSelectedStudentFee(e.value);
    }else{
      this.rolenumber = '';
      this.studentClassFeeFine = {};
    }
  }

  showSelectedStudentFee = async (student_id)=>{
    var MAIN = this;
    MAIN.studentFee = [];
    this.registerForm.get('student_id').setValue(student_id);
    this.response = true;
    this.studentClassFeeFine = {};
    
    this.api.post('student/fee',{
        student_id
      }).subscribe(
      async (data)=>{
        if(data.code == 200){
          MAIN.studentClassFeeFine = data.result;
          this.final_fee_transport = [];
          this.selectedFees = [];
          this.final_fee_transport = MAIN.studentClassFeeFine.final_fee;

          await MAIN.studentClassFeeFine.transport.forEach((t)=>{
            this.final_fee_transport.push(t);
          });

          this.final_fee_transport.sort((a, b) => moment(a.date) > moment(b.date) ? 1 : -1);
          if(MAIN.studentClassFeeFine.final_previous_due){
            this.final_fee_transport.unshift(MAIN.studentClassFeeFine.final_previous_due);
          }
        }
      },
      err =>{},
      ()=>{
        this.response = false;
      }
    )
  }

  finalAmountDetail = async ()=>{
    var MAIN = this
    var amount = 0;
    var discount = 0;
    var paid = 0;
    
    await MAIN.selectedFees.forEach((i)=>{
      paid += parseInt(i.totalPaid);
      discount += parseInt(i.totalDiscount);
      amount += parseInt(i.totalAmount);
    });

    MAIN.finalAmount.paid = paid;
    MAIN.finalAmount.discount = discount;
    MAIN.finalAmount.amount = amount;
  }

  getBatchList = ()=>{
    var MAIN = this
    this.api.post('list/batch', { type: 'school-batch', id: localStorage.getItem('org_id') }).subscribe(
      async (data) => {
        var final_data = [];
        final_data.push({
          id:0,
          text: "Select a batch"
        });
        await data.response.forEach((item)=>{
          final_data.push({
            id:item.id,
            text: item.name
          })
        })
        MAIN.batchList = final_data;
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  }

  saveReport = () => {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.response = true;
    var fees = [];
    var request = this.registerForm.value;
    var data = {
      org_id: localStorage.getItem('org_id'),
      student_id: request.student_id,
      payment_date: request.payment_date,
      payment_mode: request.payment_mode,
      amount: this.finalAmount.amount,
      discount:this.finalAmount.discount,
      paid: this.finalAmount.paid,
      school_id:localStorage.getItem('user_id'),
      fees: []
    };
    for(const sf of this.selectedFees){
      var temp_fees = {
        "deposite_date":sf.deposite_date,
        "amount_id":sf.amount_id,
        "total_amount":sf.totalAmount,
        "total_discount":sf.totalDiscount,
        "total_paid":sf.totalPaid,
        "amount":sf.fee_type_amount,
        "discount":sf.fee_type_discount,
        "paid":sf.fee_type_paid,
        "type":sf.type,
        "fines":[],
        "extras":[]
      };
      var fines=[];
      for(const fine of sf.fines){
        fines.push({
          "id":fine.id,
          "type_id":fine.fine_type_id,
          "amount":fine.amount,
          "discount":fine.discount,
          "paid":fine.paid,
        });
      }
      temp_fees.fines = fines;
      
      var extras=[];
      for(const e of sf.extra){
        extras.push({
          "name":e.name,
          "amount":e.amount,
          "discount":e.discount,
          "paid":e.paid,
        });
      }
      temp_fees.extras = extras;
      fees.push(temp_fees);
    }
    data.fees = fees;

    this.api.post('student/fee/store', data).subscribe(
      (data) => {
        this.response = false;
        this.registerForm.reset();
        this.ngOnInit();
        Swal.fire('Success', 'Student fee added successfully', 'success');
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  }

  getClassList = ()=>{
    var MAIN = this;
    var request = MAIN.registerForm.value;
    if(request.batch_id  > 0){
      this.api.post('list/class',{
        type:'school-class',
        'batch_id':request.batch_id,
        'medium':request.medium,
        id:localStorage.getItem('org_id')}).subscribe(
        async (data)=>{
          var final_data = []
          await data.response.forEach((item)=>{
            final_data.push({
              id:item.id,
              text: item.name
            })
          })
          MAIN.classList = final_data;
        },
        err =>{},
        ()=>{
          this.response = false;
        }
      )
    }
  }
}
