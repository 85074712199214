import { Component, OnInit } from '@angular/core';
import { ApiService } from '../..///api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  pageurl = false;
  loginForm: FormGroup;
  error;
  submitted = false;
  _permissionsView: any;
  response: string;
  isSuccess = false;
  code;
  validateSchoolCode = false;
  item: any;
  _code_resp: any;
  id: number;

  constructor(private router: Router, private api: ApiService, private formBuilder: FormBuilder, public activatedRoute: ActivatedRoute) {


  }

  ngOnInit() {

    if (localStorage.getItem('user_id')) {
      this.router.navigate(['/dashboard'])
    }

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    })
  }
  get f() { return this.loginForm.controls; }


  submitItem() {
    this.isSuccess = true;

    if (this.loginForm.invalid) {
      return;
    }
    this.error = '';
    this.submitted = true;
    let request = this.loginForm.value;

    this.api.verify(request).subscribe(
      data => {
        this._permissionsView = data;
        if (this._permissionsView.token) {
          this.isSuccess = false;
          localStorage.setItem('token', this._permissionsView.token);
          localStorage.setItem('user_id', this._permissionsView.user_id);
          localStorage.setItem('user_name', this._permissionsView.name);
          localStorage.setItem('school_user_id', this._permissionsView.school_user_id);
          localStorage.setItem('org_id', this._permissionsView.school_user_id);
          this.router.navigate(['/dashboard']);
        } else {
          this.isSuccess = false;
          this.response = "Please enter correct login credentials";
        }
      },
      err => {
        console.log(err.error.message)
        this.isSuccess = false;
        this.error = "Server error found";
        this.response = err.error.message;
        setTimeout(() => {
          this.response = '';
        }, 5000);
      },
      () => console.log('loading completed')
    )
  }
}
