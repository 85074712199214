import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ConfirmationDialogService } from 'src/app/modal/confirmation-dialog.service';
import { ConfirmationDialogComponent } from 'src/app/modal/confirmation-dialog/confirmation-dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  items;
  response = true;
  pageOfItems: Array<any>;
  searchForm: FormGroup;
  hideFilter = false;
  bookingType = "booking";
  totalCost: any
  totalCount: any
  pageNumber: number = 1
  page = {
    limit: 10,
    count: 0,
    offset: 0,
  };
  public pageLimitOptions = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];
  constructor(private formBuilder: FormBuilder, private api: ApiService, private confirmationDialogService: ConfirmationDialogService) { }
  ngOnInit() {
    this.getlist({
      "page_number": 1,
      "limit": 10
    });
    this.searchForm = this.formBuilder.group({
      services: [''],
      user: [''],
      To: [null],
      From: [null]
    });
  }
  getlist(extra = {}) {
    let filter = { school_user_id: localStorage.getItem('school_user_id') }
    filter = { ...filter, ...extra }
    this.api.post('emi-level/list', filter).subscribe(resp => {
      this.response = false;
      this.pageNumber = extra['page_number'];
      this.totalCount = resp.result.total;
      this.items = resp.result.list
    },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  }

  public openConfirmationDialog(id) {
    
    const Toast = Swal.mixin({
      input: 'textarea',
      inputPlaceholder: 'Reason...',
      inputAttributes: {
        'aria-label': 'Reason'
      },
      showCancelButton: true
    })
    Toast.fire('Please confirm...','Do you really want to delete... ?','info').then((result)=>{
      if (result.isConfirmed) {
        let item = { id: id, reason:result.value }
        this.response = true;
        this.api.delete('emi-level/delete', item).subscribe(
          data => {
            this.response=false;
            this.ngOnInit()
            this.response = false;
          }, err => {
            this.response = false;
            console.log(err);
          }
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled','Your file record is safe :)','error')
      }
    })
  }

  onChangePage(pageOfItems) {
    this.getlist({
      // "search":this.searchValue,
      "page_number": pageOfItems,
      "limit": 10
      // "isAdditionalFilter":this.isAdditionalFilter,
      // "toDate":this.toDate,
      // "fromDate":this.fromDate,
      // "leadPriority": this.leadPriority,
      // "productService":this.productService,
      // "leadSource":this.leadSource,
      // "assignUser":this.assignUsers,
      // "status":this.filterBy
    });
  }
}