import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { ConfirmationDialogService } from 'src/app/modal/confirmation-dialog.service';
import { SharingServiceService } from 'src/app/services/sharing-service.service';
import Swal from 'sweetalert2'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-property-evaluation',
  templateUrl: './property-evaluation.component.html',
  styleUrls: ['./property-evaluation.component.css']
})
export class PropertyEvaluationComponent implements OnInit {
  plots: any;
  page = {
    limit: 10,
    count: 0,
    offset: 0,
  };
  public pageLimitOptions = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];
  editPlotId: any;
  editPlotForm: FormGroup;
  projectId: any;
  totalArea: Number = 0;
  avlForSale: any;
  editPlotLists:any;
  units = [{ id: 1, title: "ACRE" }, { id: 2, title: "BIGHA" }, { id: 3, title: "BISWA" }, { id: 4, title: "SQ. METER" }, { id: 5, title: "SQ. FEET" }, { id: 6, title: "SQ. YARDS" }]
  constructor(private formBuilder: FormBuilder, private api: ApiService, private confirmationDialogService: ConfirmationDialogService, private router: Router, private sharingService: SharingServiceService) { }
  ngOnInit() {
    // totalAreagetProperty();
    this.getProjectList({ "page_number": 1, "limit": 10 });
    this.editPlotFormInitilizaion();

  }
  response: boolean = false;
  rows: any;
  properties: any;
  projects: any;
  editPlotDetails: any;
  plotLists: any = {};
  public plot_no: string;

  getProperty() {
    this.api.post('organization/list', { type: "field", user_id: localStorage.getItem('user_id') }).subscribe(resp => {
      if (resp.response.length > 0) {
        this.properties = resp.response.filter(ele => {
          if (ele.field_type) {
            return ele.field_type.name.toLowerCase() == "property type"
          }
        });
        this.projects = resp.response.filter(ele => {
          if (ele.field_type) {
            return ele.field_type.name.toLowerCase() == "project";
          }
        });
      }
      this.getProjectList({ "page_number": 1, "limit": 10 });
    })
  }

  editPlotFormInitilizaion() {
    this.editPlotForm = this.formBuilder.group({
      duplicate_plot_no: [0],
      school_user_id: [''],
      project_id: [''],
      eastw: [''],
      norths: [''],
      plot_no: ['', Validators.required],
      area: ['', Validators.required],
      rate: ['']
    });
  }

  getProjectList(extra = {}) {
    var filter = { user_id: localStorage.getItem('user_id') };
    filter = { ...filter, ...extra };
    this.response = true;

    this.api.post('project-list-withPlot', filter).subscribe(
      data => {
        this.response = false;
        let p = extra["limit"];
        let pageNumber = extra["page_number"];
        this.page.count = data.result.total;
        data.result.list.forEach((element, index) => {
          element.no = p * (pageNumber - 1) + index + 1;
          this.plotLists = element.plot
        })
        this.rows = data.result.list;
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };

  onDetailToggle(event) { }

  @ViewChild('myTable', { static: true }) table: any;
  toggleExpandRow(row, rowIndex, expanded) {
    this.plotLists = row.plot;
    var _this = this;
    _this.table.rowDetail.toggleExpandRow(row)
  }
  onLimitChange(value) {

    this.page.limit = value;
    this.getProjectList({ "page_number": 1, "limit": value });
  }
  setPage(pageInfo) {

    this.getProjectList({ "page_number": (pageInfo.offset + 1), "limit": 10 });
    // this.serverResultsService.getResults(this.page).subscribe(pagedData => {
    // this.offset=1;
    // this.limit= 10;
    // this.rows = this.rows;
    // });
  }
  filterSearch(value) {

  }
  addNewForm() {
    this.sharingService.setData(undefined);
    this.router.navigateByUrl('/realstate/add_property_evaluation');
  }
  editData(data) {
    this.sharingService.setData(data);
    this.router.navigateByUrl('/realstate/addprojectmanage');
  }

  deleteData(id, reason) {
    this.response = true;
    let item = { id, reason };
    this.api.deleteData('delete-plot', item).subscribe(
      (resp) => {
        Swal.fire('Success', 'Plot deleted successfully!', 'success');
        this.ngOnInit()
        this.response = false;
      }, err => {
        this.response = false;
        console.log(err);
      }
    )
  };
  public openConfirmationDialog(id) {
    const Toast = Swal.mixin({
      input: 'textarea',
      inputPlaceholder: 'Reason...',
      inputAttributes: {
        'aria-label': 'Reason'
      },
      showCancelButton: true
    })
    Toast.fire('Please confirm...', 'Do you really want to delete... ?', 'info').then((result) => {
      if (result.isConfirmed) {
        this.deleteData(id, result.value);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your file record is safe :)', 'error')
      }
    })
  }

  editPlotData(plots,plotId) {
    console.log('plotsLitsttasdf',plots)
    this.editPlotLists=plots;
    console.log('plotId', plotId)
    this.editPlotId = plotId
    this.rows.forEach((row) => {
      this.editPlotDetails = row.plot.filter((item) => {
        if (item.id == plotId) {
          this.editPlotForm.patchValue({ plot_no: item.plot_no })
          this.editPlotForm.patchValue({ eastw: item.eastw })
          this.editPlotForm.patchValue({ norths: item.norths })
          this.editPlotForm.patchValue({ area: item.area })
          this.editPlotForm.patchValue({ rate: item.rate })
          this.editPlotForm.patchValue({ school_user_id: item.school_user_id })
          this.editPlotForm.patchValue({ project_id: item.project_id })
          this.editPlotForm.patchValue({ duplicate_plot_no: false })
        }
      })
    })
  }

  checkDuplicatePlot(value) {
    console.log('dddd')
    let request = {
      "school_user_id": this.editPlotForm.value.school_user_id,
      "project_id": this.editPlotForm.value.project_id,
      "plot_id": this.editPlotId,
      "plot_no": value
    };
    this.api.post('check-duplicate-plot', request).subscribe(
      data => {
        if (data.code == '200') {
          this.editPlotForm.patchValue({ duplicate_plot_no: false })
        }
        else {
          this.editPlotForm.patchValue({ duplicate_plot_no: true })
        }
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  }



  plotSubmit(plotFormData) {
    let request = this.editPlotForm.value;
    this.response = true;
    if (this.editPlotForm.invalid) {
      this.response = false;
      Swal.fire('Error', 'Please fill required field', 'error');
      return
    }
    request.plotId = this.editPlotId;
    // this.editPlotLists.forEach((plotList)=>{
      let diffOfArea,projectRestLand,extraReqLand; 
      if(this.editPlotLists.id==this.editPlotId && Number(this.editPlotLists.area) > Number(request.area)){
        diffOfArea=Number(this.editPlotLists.area)-Number(request.area);
        this.rows.forEach((project) => {
          if (project.id == this.editPlotLists.project_id) {
            projectRestLand=Number(project.rest_land)+diffOfArea;
            extraReqLand=0;
          } 
        })
      }
      if(this.editPlotLists.id==this.editPlotId && Number(this.editPlotLists.area) < Number(request.area)){
        diffOfArea=Number(request.area)-Number(this.editPlotLists.area);
        this.rows.forEach((project) => {
          if(Number(project.rest_land) > Number(request.area)){
            if (project.id == this.editPlotLists.project_id) {
              projectRestLand=Number(project.rest_land)-diffOfArea;
            }
          }
          else{
            extraReqLand=Number(request.area)-Number(project.rest_land);
            projectRestLand=0;
          }
        })
      }
    request.projectRestLand=projectRestLand
    request.extraReqLand=extraReqLand;
    console.log('request', request);
    this.api.post('update-plot', request).subscribe(
      data => {
        if (data.code == '200') {
          document.getElementById("closeEditPlotDataModal").click();
          this.editPlotForm.reset();
          Swal.fire('Success', 'Updated successfully', 'success');
          this.response = false;
          this.ngOnInit();
        }
        else {
          this.response = false;
          Swal.fire('Error', 'Data Not Updated', 'error');
        }
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  }
}