import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ApiService } from '../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  response;
  apiResponse;
  error;
  fieldTypes;
  items: any = {};
  item:any = {};
  success: string;
  departments = [];
  designations = [];
  employment_types = [];
  reference_vias = [];
  grids = [];
  fileData;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route:ActivatedRoute) { }

  ngOnInit() {
    this.getFieldByType();

    this.registerForm = this.formBuilder.group({
      department: ["", Validators.required],
      designation: ["", Validators.required],
      role: ['', Validators.required],
      file: ['', Validators.required],
      grade: ["", Validators.required],
    });

  }


  
getFieldByType(){
  this.api.post('list/employee-field-by-type',{type:'employee-field-by-type',id:localStorage.getItem('org_id'),grid:true}).subscribe(
    data=>{
      this.fieldTypes = data;
      this.fieldTypes = this.fieldTypes.response;
      this.departments = this.fieldTypes.department;
      this.designations = this.fieldTypes.designation;
      this.employment_types = this.fieldTypes.employment_type;
      this.reference_vias = this.fieldTypes.reference_via;
      this.grids = this.fieldTypes.grid;


    },
    err =>{
      this.response = false;
    },
    ()=>console.log('loading completed')
  )
};

get f() { return this.registerForm.controls; }

onSubmit() {

  this.submitted = true;
  if (this.registerForm.invalid) {
    return;
  }

  let request = this.registerForm.value;
  request.school_user_id = localStorage.getItem('org_id');
  request.type = "employee";

  var postData = JSON.stringify(request);


  const requestData = new FormData();
  if(this.fileData){
    requestData.append('file_data', this.fileData, this.fileData.name);
   }

   requestData.append('type', "employee");
   requestData.append("postData",postData);


  this.api.save('import', requestData).subscribe(
    data => {
      this.apiResponse =data;
      debugger;
      if(this.apiResponse=="duplicate-aadhar"){
        Swal.fire('Oops...',"Aadhar number already exists", 'error')
      }else{
        Swal.fire('Success',"Total " + this.apiResponse.total + " data imported successfully", 'success')
      }

         // this.registerForm.reset();
    }, err => {
      this.response = false;
      // /this.error = "Server error found";
      Swal.fire('Oops...',"Server error found", 'error')


    }

  )

}

uploadData(event) {
  this.fileData = event.target.files[0];
}




}
