import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'



declare var $: any;
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  response;
  error;
  items: any = {};
  item: any = {};
  success: string;
  batches = [];
  selectedRouteMode = [];
  pick_drops_mode = [];
  religions;
  casts;
  admission_categories;
  spoken_languages;
  nationalities;
  blood_groups;
  genders;
  marritals;
  lives_withs;
  handicappeds;
  parent_statuses;
  bank_loans = [];
  classes = [];
  sections = [];
  id: number = null;
  apiResponse;
  duplicateRoll: string;
  countries: any = [];
  form_no: any;
  previous_due: 0;
  caseFiles: any;
  siblingStatus: any;
  samePermanent: any;
  same_permanent: any;
  permanent_state: any;
  permanent_country: any;
  permanent_city: any;
  permanent_address: any;
  family_type: any;
  documentPhoto: any;
  visa_country: any;
  medium: any;
  classType: any;
  sectionType: any;
  roll_no: any;
  controls: any;
  country: any;
  state: any;
  city: any;
  states;
  filterStates = [];
  fieldTypes;
  academics = [];
  studentFile: File;
  motherFile: File;
  fatherFile: File;
  gaurdianFile: File;
  imagUrl;
  document: any = {};
  concessions: any = [];
  subjects: any = [];
  headings = []
  routes;
  pick_drops;
  transports;
  linktodrive = [];
  address;
  zip;
  isbarred = false;
  //urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {

    this.registerForm = this.formBuilder.group({
      batch_id: ["", Validators.required],
      class_id: [[], [Validators.required]],
      medium: ['', Validators.required],
      section_id: ['', Validators.required],
      // enrollment_type: ['', Validators.required],
      enroll_via: [''],
      roll_no: ['', Validators.required],
      enrollment_via: [''],
      enrollment_type: [''],
      registration_no: [''],
      entrance_roll_no: [''],
      admission_no: [''],
      enrollment_no: [''],
      form_no: [''],
      previous_due: ['0'],
      first_name: ['', Validators.required],
      // middle_name: [''],
      // last_name: ['', Validators.required],
      uid: [''],
      doa: [''],
      dob: [''],
      birth_place: [''],
      religion_id: [''],
      cast_id: [''],
      admission_category: [''],
      contact_no: [''],
      email: [''],
      blood_group: [''],
      gender: [''],
      language: [''],
      marrital: [''],
      handicapped: [''],
      nationality: [''],
      living_with: [''],
      domicile_state: [''],
      parent_status: [''],
      physical_fitness: [''],
      case_file: ['no'],
      case_detail: [''],
      sibling_status: [''],
      sibling_student_id: [''],
      // address_details:this.formBuilder.group({
      address: [''],
      zip: [''],
      country: [''],
      state: [''],
      city: [''],
      same_permanent: [''],
      permanent_address: [''],
      permanent_zip: [''],
      permanent_country: [''],
      permanent_state: [''],
      permanent_city: [''],
      // }),
      // family_details:this.formBuilder.group({
      family_type: [''],
      father_name: [''],
      father_occupation: [''],
      father_mobile: [''],
      transport_mode_payment_id: [''],
      route_side: [''],
      father_email: [''],
      father_contact: [''],
      father_income: [''],
      mother_name: [''],
      mother_occupation: [''],
      mother_mobile: [''],
      mother_email: [''],
      mother_contact: [''],
      mother_scholarship: [''],
      gaurdian_name: [''],
      gaurdian_relation: [''],
      gaurdian_mobile: [''],
      gaurdian_email: [''],
      gaurdian_contact: [''],
      // }),
      // additional_details:this.formBuilder.group({
      visa_no: [''],
      visa_till_date: [''],
      visa_application_no: [''],
      udise: [''],
      passport_no: [''],
      visa_country: [''],
      tofel_score: [''],
      ielts: [''],
      facebook: [''],
      twitter: [''],
      skype: [''],
      whatsapp: [''],
      account_no: [''],
      ifsc: [''],
      branch: [''],
      bank: [''],
      bank_loan: [''],
      swift_code: [''],
      micr_code: [''],
      // }),
      // document_details:this.formBuilder.group({
      fphoto: [''],
      document_type: [''],
      document: [''],
      fine_id: [''],
      concession_status: [''],
      concession_remark: [''],
      subject_id: [],
      route_id: [''],
      pickup_id: [''],
      transport_id: [''],
      linktodrive: this.formBuilder.array([this.initLink('', '', '', '', '')]),
    });

    this.linktodrive.push(this.initLink('', '', '', '', ''));

    this.imagUrl = this.api.imageUrl;

    this.getLists();
    this.getCountry();
    this.getState();
    this.getFieldByType();
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);
      this.getState();

    }

  }


  initLink(name, degree, start_date, end_date, grade) {
    return this.formBuilder.group({
      academic_name: [name],
      academic_degree: [degree],
      academic_start_date: [start_date],
      academic_end_date: [end_date],
      academic_grade: [grade]
    });

  }


  addLink() {
    this.linktodrive.push(this.initLink('', '', '', '', ''));
    // const control = (this.registerForm.controls['linktodrive'] as FormArray);
    // control.push(this.initLink('', '', '', '', ''));
  }

  removeLink(i: number) {
    // this.linktodrive.removeAt(i);
    // const control = this.linktodrive;
    // control.removeAt(i);
    // const control = <FormArray>this.registerForm.controls['linktodrive'];
    // control.removeAt(i);
  }


  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  getArrayControls() {
    // return <FormArray>this.registerForm.controls['linktodrive'];
    return this.linktodrive;
  }

  onSubmit() {
    this.duplicateRoll = "";
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.response = true;
    let request = this.registerForm.value;
    request.id = this.id;
    request.school_user_id = localStorage.getItem('org_id');
    var postData = JSON.stringify(request);

    const requestData = new FormData();

    if (this.studentFile) {
      requestData.append('studentphoto', this.studentFile, this.studentFile.name);
    }

    if (this.motherFile) {
      requestData.append('motherphoto', this.motherFile, this.motherFile.name);
    }
    if (this.fatherFile) {
      requestData.append('fatherphoto', this.fatherFile, this.fatherFile.name);
    }

    if (this.gaurdianFile) {
      requestData.append('gaurdianphoto', this.gaurdianFile, this.gaurdianFile.name);
    }
    requestData.append("postData", postData);
    requestData.append("type", "student-registration");
    //request.type = 'student-registration';

    this.api.addWithoutJson('add', requestData).subscribe(
      data => {
        this.apiResponse = data;

        if (data.id != undefined) {
          if (this.id) {
            this.getItemById(this.id);
            Swal.fire('', 'Updated successfully', 'success');
          } else {
            this.submitted = false;
            this.registerForm.reset();
            this.ngOnInit();
            Swal.fire('', 'Student registration created successfully', 'success');
          }
          setTimeout(() => {
            this.success = null;
          }, 5000);
        }
      }, err => {
        this.response = false;
        if (err.error.roll_no) {
          this.duplicateRoll = "The roll no has already been taken.";
          Swal.fire('Oops...', this.duplicateRoll, 'error')


        } else {
          //this.error = "Server error found";
          Swal.fire('Oops...', 'Server error found', 'error')

        }

      }, () => {
        this.response = false;
      }

    )

  }

  getLists() {
    this.api.post('list/feesAssign', { type: 'feesAssign', id: localStorage.getItem('org_id') }).subscribe(
      data => {
        this.response = false;
        this.items = data;
        this.items = this.items.response;
        this.batches = this.items.batches;
      },
      err => {
        this.response = false;
      },
      () => console.log('')
    )
  };

  clickMedium() {
    let medium = this.registerForm.controls['medium'].value;
    let arrayClass = [];
    if (this.items) {

      this.items.classes.filter(function (itm) {
        if (itm.medium == medium) {
          arrayClass.push(itm);
        }
      });
    }

    this.classes = arrayClass;

  }

  clickClass() {
    let class_id = this.registerForm.controls['class_id'].value;
    this.getClassDataByName(class_id);
    let arraySection = [];
    if (this.items.sections) {
      this.items.sections.filter(function (itm) {
        if (itm.school_class_id == class_id) {
          arraySection.push(itm);
        }
      });
    }

    this.sections = arraySection;
    setTimeout(() => {
      $('.selectpicker').selectpicker('refresh');
    });

  }


  getCountry() {
    this.api.post('list', { type: "country" }).subscribe(
      data => {
        this.countries = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };


  getState() {
    this.api.post('list', { type: "state" }).subscribe(
      data => {
        this.states = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  onChange(country_d) {
    let newStates = [];
    this.states.forEach(function (value) {
      if (value.country_id == country_d) {
        newStates.push(value);
      }
    });
    this.filterStates = newStates;

    this.countries.forEach(element => {
      if (element.id == country_d) {
      }


    });

  }


  getFieldByType() {
    this.api.post('list/field-by-type', { type: 'field-by-type', id: localStorage.getItem('org_id') }).subscribe(
      data => {
        this.fieldTypes = data;
        console.log('fieldTypes',this.fieldTypes.response);
        this.fieldTypes = this.fieldTypes.response;
        this.headings = this.fieldTypes.headings;
        // console.log('fieldTypes',this.fieldTypes)
        this.religions = this.fieldTypes.religion;
        this.casts = this.fieldTypes.cast;
        this.admission_categories = this.fieldTypes.admission_category;
        this.spoken_languages = this.fieldTypes.spoken_languages;
        this.bank_loans = this.fieldTypes.bank_loan;
        //this.admission_categories = this.fieldTypes.scholarship_catagory;
        this.nationalities = this.fieldTypes.nationality;
        this.blood_groups = this.fieldTypes.blood_group;
        this.genders = this.fieldTypes.gender;
        this.marritals = this.fieldTypes.marrital;
        this.lives_withs = this.fieldTypes.lives_with;
        this.handicappeds = this.fieldTypes.handicapped;
        this.routes = this.fieldTypes.route;
        this.parent_statuses = this.fieldTypes.parent_status;
        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        });


      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };


  getItemById(id) {
    let send = { type: 'student', id: id, school_user_id: localStorage.getItem('org_id') };
    this.api.get(send).subscribe(
      data => {

        this.item = data;

        this.address = this.item.address.address;
        this.zip = this.item.address.zip;


        // this.item = this.item;
        //debugger;
        this.isbarred = this.item.concession_status == 'barred' ? true : false;

        this.academics = this.item.academics;

        let family = this.item.other ? JSON.parse(this.item.other.family) : null;
        let additional = this.item.other ? JSON.parse(this.item.other.additional) : null;
        this.document = this.item.other ? JSON.parse(this.item.other.photos) : '';
        if (this.item.other) {
          this.clickRoute(this.item.other.route_id)

        }
        let subject_ids = [];
        if (this.item.student_subjects) {

          this.item.student_subjects.forEach(element =>
            subject_ids.push(element.subject_id.toString())
          );
        }


        this.registerForm = this.formBuilder.group({
          batch_id: [this.item.batch_id, Validators.required],
          medium: [this.item.medium, Validators.required],
          class_id: [this.item.school_class_id, [Validators.required]],
          section_id: [this.item.school_section_id, Validators.required],
          // enrollment_type: [this.item.enrollment_type, Validators.required],
          enroll_via: [this.item.enrollment_via],
          enrollment_type: [this.item.enrollment_type],
          roll_no: [this.item.batch_id, Validators.required],
          enrollment_via: [this.item.enrollment_via],
          registration_no: [this.item.registration_no],
          entrance_roll_no: [this.item.entrance_roll_no],
          admission_no: [this.item.admission_no],
          enrollment_no: [this.item.enrollment_no],
          form_no: [this.item.form_no],
          previous_due: [this.item.detail ? this.item.detail.previous_due : 0],
          first_name: [this.item.detail ? this.item.detail.first_name : '', Validators.required],
          // middle_name: [this.item.detail ? this.item.detail.middle_name : ''],
          // last_name: [this.item.detail ? this.item.detail.last_name : '', Validators.required],
          uid: [this.item.detail ? this.item.detail.uid : ''],
          doa: [this.item.detail ? this.item.detail.uid : ''],
          dob: [this.item.detail ? this.item.detail.dob : ''],
          birth_place: [this.item.detail ? this.item.detail.birth_place : ''],
          religion_id: [this.item.detail ? this.item.detail.religion_id : ''],
          cast_id: [this.item.detail ? this.item.detail.cast_id : ''],
          admission_category: [this.item.detail ? this.item.detail.admission_category : ''],
          contact_no: [this.item.detail ? this.item.detail.contact_no : ''],
          email: [this.item.detail ? this.item.detail.email : ''],
          blood_group: [this.item.detail ? this.item.detail.blood_group : ''],
          gender: [this.item.detail ? this.item.detail.gender : ''],
          language: [this.item.detail && this.item.detail.language ? this.item.detail.language.split(',') : null],
          marrital: [this.item.detail ? this.item.detail.marrital : ''],
          handicapped: [this.item.detail ? this.item.detail.handicapped : ''],
          nationality: [this.item.detail ? this.item.detail.nationality : ''],
          living_with: [this.item.detail ? this.item.detail.living_with : ''],
          domicile_state: [this.item.detail ? this.item.detail.domicile_state : ''],
          parent_status: [this.item.detail ? this.item.detail.parent_status : ''],
          physical_fitness: [this.item.detail ? this.item.detail.physical_fitness : ''],
          case_file: [this.item.detail && this.item.detail.case_file ? this.item.detail.case_file : 'no'],
          case_detail: [this.item.detail ? this.item.detail.case_detail : ''],
          sibling_status: [this.item.detail && this.item.detail.sibling_status ? this.item.detail.sibling_status : ''],
          sibling_student_id: [this.item.detail ? this.item.detail.sibling_student_id : ''],
          address: [this.item.address ? this.item.address.address : ''],
          zip: [this.item.address ? this.item.address.zip : ''],
          country: [this.item.address ? this.item.address.country : ''],
          state: [this.item.address ? this.item.address.state : ''],
          city: [this.item.address ? this.item.address.city : ''],
          same_permanent: [this.item.address ? this.item.address.same_permanent : ''],
          permanent_address: [this.item.address ? this.item.address.permanent_address : ''],
          permanent_zip: [this.item.address ? this.item.address.permanent_zip : ''],
          permanent_country: [this.item.address ? this.item.address.permanent_country : ''],
          permanent_state: [this.item.address ? this.item.address.permanent_state : ''],
          permanent_city: [this.item.address ? this.item.address.permanent_city : ''],
          family_type: [family ? family.family_type : ''],
          father_name: [family ? family.father_name : ''],
          father_occupation: [family ? family.father_occupation : ''],
          father_mobile: [family ? family.father_mobile : ''],
          father_email: [family ? family.father_email : ''],
          father_contact: [family ? family.father_mobile : ''],
          father_income: [family ? family.father_income : ''],
          mother_name: [family ? family.mother_name : ''],
          mother_occupation: [family ? family.mother_occupation : ''],
          mother_mobile: [family ? family.mother_mobile : ''],
          mother_email: [family ? family.mother_email : ''],
          mother_contact: [family ? family.mother_contact : ''],
          mother_scholarship: [family ? family.mother_scholarship : ''],
          gaurdian_name: [family ? family.gaurdian_name : ''],
          gaurdian_relation: [family ? family.gaurdian_relation : ''],
          gaurdian_mobile: [family ? family.gaurdian_mobile : ''],
          gaurdian_email: [family ? family.gaurdian_email : ''],
          gaurdian_contact: [family ? family.gaurdian_contact : ''],
          visa_no: [additional ? additional.visa_no : ''],
          visa_till_date: [additional ? additional.visa_till_date : ''],
          visa_application_no: [additional ? additional.visa_application_no : ''],
          udise: [additional ? additional.udise : ''],
          passport_no: [additional ? additional.passport_no : ''],
          visa_country: [additional ? additional.visa_country : ''],
          tofel_score: [additional ? additional.tofel_score : ''],
          ielts: [additional ? additional.ielts : ''],
          facebook: [additional ? additional.facebook : ''],
          twitter: [additional ? additional.twitter : ''],
          skype: [additional ? additional.skype : ''],
          whatsapp: [additional ? additional.whatsapp : ''],
          account_no: [additional ? additional.account_no : ''],
          ifsc: [additional ? additional.ifsc : ''],
          branch: [additional ? additional.branch : ''],
          bank: [additional ? additional.bank : ''],
          swift_code: [additional ? additional.swift_code : ''],
          micr_code: [additional ? additional.micr_code : ''],
          bank_loan: [additional ? additional.bank_loan : ''],
          document_type: [additional ? additional.document_type : ''],
          document: [additional ? additional.document : ''],
          fine_id: [this.item.concession_id ? this.item.concession_id : ''],
          concession_status: [this.item.concession_status ? this.item.concession_status : ''],
          concession_remark: [this.item.concession_remark ? this.item.concession_remark : null],
          subject_id: [subject_ids],
          route_id: [this.item.other ? this.item.other.route_id : ''],
          pickup_id: [this.item.other ? this.item.other.pickup_id : ''],
          transport_id: [this.item.other ? this.item.other.transport_id : ''],
          linktodrive: this.formBuilder.array([this.initLink('', '', '', '', '')])

        })

        //remove default first academic
        const control1 = <FormArray>this.registerForm.controls['linktodrive'];
        control1.removeAt(0);
        //remove default first academic end

        const control = <FormArray>this.registerForm.controls['linktodrive'];
        this.academics.forEach(x => {
          control.push(this.initLink(x.name, x.degree, x.start_date, x.end_date, x.grade));

        })
        setTimeout(() => {
          this.clickMedium();
          this.clickClass();
          if (this.item.address) {
            this.onChange(this.item.address.country);
          }

        }, 500)
      },
      err => {
        console.error(err);
      },
      () => console.log('')
    )

  }


  clickConcessionStatus() {
    if (this.registerForm.controls['concession_status'].value == 'barred') {
      this.isbarred = true;
    } else {
      this.isbarred = false;
    }
  }


  studentPhoto(event) {
    this.studentFile = event.target.files[0];
  }

  motherPhoto(event) {
    this.motherFile = event.target.files[0];
  }
  fatherPhoto(event) {
    this.fatherFile = event.target.files[0];
    console.log('fatherFile=', this.fatherFile);
  }
  gaurdianPhoto(event) {
    this.gaurdianFile = event.target.files[0];
  }


  getClassDataByName(class_id) {
    this.api.post('list/class-databy-name',
      {
        type: 'data-by-classid',
        classes: 'class',
        subjectByCategory: 'yes',
        school_user_id: localStorage.getItem('org_id'),
        school_class_id: class_id
      }).subscribe(
        data => {
          // this.items = data;
          this.concessions = data.response.concession;
          this.subjects = data.response.subjects;
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          });
        },
        err => {
          this.response = false;
        },
        () => console.log('loading completed')
      )
  };


  clickRoute(id) {
    this.getPickAndDrop(id);
  }


  getPickAndDrop(id) {
    this.selectedRouteMode = [];
    this.pick_drops_mode = []
    this.transports = []

    this.api.post('list/get',
      {
        type: 'get-pick-drop-by-route-id',
        school_user_id: localStorage.getItem('org_id'),
        route_id: id
      }).subscribe(
        (data) => {
          this.pick_drops = data.response;
          this.clickPickAndDrop(this.registerForm.controls['pickup_id'].value);
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          }, 200);
        }
      )

  }

  clickPickAndDrop(id) {
    if (!id) {
      return true;
    }
    this.api.post('list/get',
      {
        type: 'get-transport-by-pick-and-drop-id',
        school_user_id: localStorage.getItem('org_id'),
        pick_and_drop_id: id
      }).subscribe(
        data => {
          let pick_and_drop: any = data;
          this.setUpRouteMode(this.registerForm.controls['pickup_id'].value);
          this.transports = pick_and_drop.response;
        }
      )

  }

  setUpRouteMode(id) {
    this.pick_drops.forEach((r) => {
      if (r.id == id) {
        this.pick_drops_mode = r.details.mode;
        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        }, 200);
      }
    });
  }

  selectRouteModePrice(mode_id) {
    this.pick_drops_mode.forEach((m) => {
      if (m.id == this.registerForm.controls['transport_mode_payment_id'].value) {
        this.selectedRouteMode = m.date_price;
        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        }, 200);
      }
    });
  }

}
