import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-change-cheque-status',
  templateUrl: './change-cheque-status.component.html',
  styleUrls: ['./change-cheque-status.component.css']
})
export class ChangeChequeStatusComponent implements OnInit {

  @Input('paymentId') paymentId: any

  @Output() emitData = new EventEmitter<string>();

  // message: string = '11';

  check_status = [
    {
      key: "",
      value: "Select one"
    },
    {
      key: 1,
      value: "Clear"
    },
    {
      key: 2,
      value: "Bounce"
    },
    {
      key: 3,
      value: "Pending"
    }
  ];
  chequeForm: FormGroup;
  selected_check_tatus = false;
  submitted = false;
  clearCashForm: FormGroup;
  loader = false;


  constructor(private formBuilder: FormBuilder, private api: ApiService) {


  }

  ngOnInit() {

    $("#openchequeform").modal('show');
    this.chequeForm = this.formBuilder.group({
      transaction_id: [''],
      description: ['', Validators.required],
      status: ["", Validators.required],
    });

  }

  get clear() { return this.chequeForm.controls; }

  clearCashSubmit() {
    this.submitted = true;
    if (this.chequeForm.invalid) {
      return;
    }
    let cheque_request = this.chequeForm.value;
    cheque_request.payment_id = this.paymentId;
    this.loader = true;

    this.api.post('update-payment-cheque-status', cheque_request).subscribe(
      data => {
        if (data.code == 200) {
          this.emitData.emit(data);
          document.getElementById("openchequeform").click();
          Swal.fire('Success', 'Cheque status updated successfully', 'success');
        }
        this.loader = false;

      }, err => {
        console.log(err);
        this.loader = false;

      }

    )
  }
  checkStatus(status) {
    if (status == 1) {
      this.selected_check_tatus = true;
      this.chequeForm.controls['transaction_id'].setValidators([Validators.required]);

    } else {
      this.selected_check_tatus = false;
      this.chequeForm.controls['transaction_id'].setValue("")
      this.chequeForm.controls['transaction_id'].clearValidators();
    }
    this.chequeForm.get('transaction_id').updateValueAndValidity();
  }


}
