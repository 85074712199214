import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  Observable
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiURL: string
  imageUrl: string;
  appUrl: string;
  domain = window.location.origin;
  sessions;

  constructor(private http: HttpClient, public router: Router) {

    let date = new Date();
    let currentYear = date.getFullYear();
    this.sessions = [{ id: 1, year: 2015 }, { id: 2, year: 2016 }, { id: 3, year: 2017 }, { id: 4, year: 2018 }, { id: 5, year: 2019 }, { id: 6, year: currentYear }];

    if (this.domain == 'http://localhost:4200' || this.domain == 'http://localhost:4201') {
      this.apiURL = 'http://127.0.0.1:8000/api/v1/';
      this.imageUrl = 'http://127.0.0.1:8000/';
      this.appUrl = 'http://127.0.0.1:8000/'

      // this.apiURL = 'https://link.dopluserp.com/api/v1/';
      // this.imageUrl = 'https://link.dopluserp.com/uploads/';
      // this.appUrl = 'https://link.dopluserp.com/'
    } else {
      this.apiURL = 'https://link.dopluserp.com/api/v1/';
      this.imageUrl = 'https://link.dopluserp.com/uploads/';
      this.appUrl = 'https://link.dopluserp.com/';

      // this.apiURL = 'http://127.0.0.1:8000/api/v1/';
      // this.imageUrl = 'http://127.0.0.1:8000/';
      // this.appUrl = 'http://127.0.0.1:8000/'

    }

  }
  getModules() {
    return this.http.get(this.apiURL + 'modules');
  }


  isLogged() {
    if (localStorage.getItem('token') == null) {
      this.router.navigate(['/login']);
      console.log("Logged User");
    }

  }

  post(service, request): Observable<any> {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });

    return this.http.post(this.apiURL + service, request, { headers: reqHeader });
  }

  addLead(request): Observable<any> {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });

    return this.http.post(this.apiURL + 'organization/addLead', request, { headers: reqHeader });
  }
  addEmiLevel(request, isEdit = false): Observable<any> {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    if (isEdit) {
      return this.http.post(this.apiURL + 'update-emi-level', request, { headers: reqHeader });
    }
    else {
      return this.http.post(this.apiURL + 'emi-level/create', request, { headers: reqHeader });
    }
  }
  getNewEmiLevel(request): Observable<any> {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.post(this.apiURL + 'emi-level/new-no', request, { headers: reqHeader });
  }
  addProject(request, isEdit): Observable<any> {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    if (isEdit) {
      return this.http.post(this.apiURL + 'update-project', request, { headers: reqHeader });
    }
    else {
      return this.http.post(this.apiURL + 'add-project', request, { headers: reqHeader });
    }
  }
  addProjectPayment(request): Observable<any> {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.post(this.apiURL + 'add-project-payment', request, { headers: reqHeader });
  }
  addProjectPlot(request): Observable<any> {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });

    return this.http.post(this.apiURL + 'add-project-plot', request, { headers: reqHeader });

  }
  addProjectOwner(request, isEdit): Observable<any> {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    if (isEdit) {
      return this.http.post(this.apiURL + 'update-project-owner', request, { headers: reqHeader });
    }
    else {
      return this.http.post(this.apiURL + 'add-project-owner', request, { headers: reqHeader });
    }

  }
  assignLead(request): Observable<any> {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.post(this.apiURL + 'lead-assign', request, { headers: reqHeader });
  }

  save(service, request): Observable<any> {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });

    return this.http.post(this.apiURL + service, request, { headers: reqHeader });
  }

  pull(service) {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.get(this.apiURL + service, { headers: reqHeader });
  }

  addModule(items) {
    return this.http.post(this.apiURL + 'module/store', items);
  }

  addSchool(items) {
    let reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });

    return this.http.post(this.apiURL + 'school/store', items, { headers: reqHeader });
  }
  addWithoutJson(service, request): Observable<any> {
    let reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });

    return this.http.post(this.apiURL + service, request, { headers: reqHeader });
  }


  getschools() {
    return this.http.get(this.apiURL + 'schools');
  }

  delete(service, item = {}): Observable<any> {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    if (Object.keys(item).length === 0 && item.constructor === Object) {
      return this.http.post(this.apiURL + 'organization/delete', service, { headers: reqHeader });
    } else {
      return this.http.post(this.apiURL + service, item, { headers: reqHeader });
    }
  }
  deleteData(service, item = {}) {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.post(this.apiURL + service, item, { headers: reqHeader });
  }

  get(item): Observable<any> {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.post(this.apiURL + 'edit', item, { headers: reqHeader });
  }

  add(service, request) {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.post(this.apiURL + service, request, { headers: reqHeader });
  }

  addUser(request) {
    let reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.post(this.apiURL + 'organization/user', request, { headers: reqHeader });
  }

  addLeadExcel(request) {
    let reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.post(this.apiURL + 'organization/lead_excel', request, { headers: reqHeader });
  }

  getItem(request) {
    let reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.get(this.apiURL + request, { headers: reqHeader });
  }

  action(items) {
    return this.http.post(this.apiURL + 'action', items);
  }

  getReport(request) {
    return this.http.post(this.apiURL + 'report', request);
  }

  verify(items) {
    return this.http.post(this.apiURL + 'login', items);
  }

  //download.service.ts
  getPdf(service) {

    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get(this.apiURL + service, httpOptions);
  }

}
