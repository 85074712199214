import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../api.service';

@Component({
  selector: 'app-module-edit',
  templateUrl: './module-edit.component.html',
  styleUrls: ['./module-edit.component.css']
})
export class ModuleEditComponent implements OnInit {

  id:number;
  item;
  success:string='';
  error:string='';
  response = false;
  registerForm: FormGroup;
  submitted = false;
  status;
  constructor(private api:ApiService, private formBuilder: FormBuilder, private route:ActivatedRoute) {
    this.item ={};
   
   }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      price: ['', Validators.required],
      status: ['1', Validators.required]
  });
  this.id = this.route.snapshot.params['id'];
  if(this.id){
    this.getItemById(this.id);
  }
  }

  getItemById(id){
    let item = {id:id, type:"module"};
    this.api.get(item).subscribe(
      data=>{
        this.item = data;
        this.status= true;

      },
      err =>{
      },
      ()=>console.log('loading completed')
    )
  };



  submitItem(data){

    if (this.registerForm.invalid) {
      return;
  }
  console.log(data);
  
  
    this.success='';
    this.error='';
    this.response = true;
    this.submitted = true;
     
      this.api.addModule(data).subscribe(
        data=>{
          this.response =false;
          if(data=='success'){
            this.success="Updated successfully";
          }
  
        },
        err =>{
          this.response = false;
          console.log('error getting',err.error);
          this.error = "Server error found";
        },
        ()=>console.log('loading completed')
      )
    }


}
