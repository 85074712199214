import { Component, OnInit } from '@angular/core';
import { ConfigurableNavigationMode } from 'angular-archwizard';
import { ConfirmationDialogService } from 'src/app/modal/confirmation-dialog.service';
import { ApiService } from '../../../api.service';

@Component({
  selector: 'app-list-charges',
  templateUrl: './list-charges.component.html',
  styleUrls: ['./list-charges.component.css']
})
export class ListChargesComponent implements OnInit {

  pageOfItems: any

  constructor(private api: ApiService, private confirmationDialogService: ConfirmationDialogService) { }


  ngOnInit() {
    this.getlist();
  }


  getlist() {
    this.api.post('charge-list', { 'school_user_id': localStorage.getItem('school_user_id') }).subscribe(
      data => {

        this.pageOfItems = data.result.list

      }

    );

  }

  deleItem(delete_id) {
    this.api.post('delete-charge', { 'charge_id': delete_id }).subscribe(
      data => {

        if (data && data.code == 200) {

          this.getlist();
        }


      }

    );

  }

  openConfirmationDialog(charge_id,) {

    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
      .then((confirmed) => {
        if (confirmed) {

          console.log('hello', charge_id);

          this.deleItem(charge_id);
        }

      })


  }

}
