import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  response;
  error;
  success: string;
  items: any;
  id: number;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);

    }
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      category_id: ['', Validators.required]
    });
    this.getCategory();

  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {

    this.response = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.submitted = true;
    let request = this.registerForm.value;
    request.type = 'subject';
    request.id = this.id;
    request.school_user_id = localStorage.getItem('org_id');
    this.api.save('add', request).subscribe(
      data => {
        if (data.id != undefined) {
          if(this.id){
            this.success = "Updated successfully";
          }else{
            this.submitted = false;
            this.registerForm.reset();
            this.ngOnInit();
            this.success = "New subject created successfully";
          }
        }

      }, err => {
        this.response = false;
        this.error = "Server error found";
      },()=>{
        this.response = false;
      }

    )

  }

  //get school sessions

  getCategory() {
    this.api.post('list/category', { type: 'school-category', id: localStorage.getItem('org_id') }).subscribe(
      data => {
        this.response = false;
        this.items = data;
        this.items = this.items.response;
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  }


  getItemById(id) {
    let send = { type: 'subject', id: id };
    this.api.get(send).subscribe(
      data => {
        this.items = data;
        this.registerForm = this.formBuilder.group({
          name: [this.items.name, Validators.required],
          code: [this.items.code, Validators.required],
          category_id: [this.items.category_id, Validators.required]
        });

      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }

}
