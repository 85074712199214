
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { SharingServiceService } from 'src/app/services/sharing-service.service';


@Component({
  selector: 'app-attendance-list',
  templateUrl: './attendance-list.component.html',
  styleUrls: ['./attendance-list.component.css']
})

export class AttendanceListComponent implements OnInit {
  page = {
    limit: 10,
    count: 0,
    offset: 0,
  };
  public pageLimitOptions = [
    {value: 10},
    {value: 25},
    {value: 50},
    {value: 100},
  ];
  units=[{id:1,title:"ACRE"},{id:2,title:"BIGHA"},{id:3,title:"BISWA"},{id:4,title:"SQ. METER"},{id:5,title:"SQ. FEET"},{id:6,title:"SQ. YARDS"}]
  constructor(private api: ApiService,private router: Router,private sharingService: SharingServiceService) { }
  ngOnInit() {
    this.getProperty();

  }
  response:boolean=false;
  rows: any;
  properties:any;
  projects: any;
  checkInDate:boolean=true;
  checkOutDate:boolean=false;
  getProperty(){
    this.api.post('organization/list',{type:"field",user_id:localStorage.getItem('user_id')}).subscribe(resp => {
      if (resp.response.length > 0) {
        this.properties=resp.response.filter(ele=>{
         return  ele.field_type.name.toLowerCase()=="property type"
        });
        this.projects=resp.response.filter(ele=>{
          return  ele.field_type.name.toLowerCase()=="project";
         });
      }
      this.getProjectList({"page_number":1,"limit":10});
    })
  }
  getProjectList(extra={}){
    var filter = {user_id:localStorage.getItem('user_id') };
    filter = {...filter, ...extra};
    this.response = true;

    this.api.post('project-list', filter).subscribe(
      data=>{
        this.response = false;
        data.result.list.forEach(element=>{
          this.projects.map(x=>{
            if(x.id==Number(element.project)){
              element.projectValue = x.name;
            }
          })
        });

        data.result.list.forEach(element=>{
          this.properties.map(x=>{
            if(x.id==Number(element.project_type)){
              element.projectType = x.name;
            }
          })
        });

        data.result.list.forEach(element=>{
          this.units.map(x=>{
            if(x.id==Number(element.projected_unit)){
              element.unitValue = x.title;
            }
          })
        });

        let p= extra["limit"];
        let pageNumber= extra["page_number"];
        this.page.count= data.result.total;
        data.result.list.forEach((element,index)=>{
         element.no= p * (pageNumber-1)+index+1;
        })
        this.rows = data.result.list;
      },
      err =>{
        this.response = false;
      },
      ()=>console.log('loading completed')
    )
  };

  onDetailToggle(event) { }
  // expanded: any = {id: 1, name: 'Dany', gender: 'Male', age: 30 };
  @ViewChild('myTable', { static: true }) table: any;
  sellerDetails:any;
  toggleExpandRow(row, rowIndex, expanded) {
    this.sellerDetails= row.owners;
    var _this = this;
      _this.table.rowDetail.toggleExpandRow(row);

    // window.setTimeout(function () {
    //   if (!expanded)
       
    //  
    // }, 5000);
  }
  onLimitChange(value){

  this.page.limit=value;
  this.getProjectList({"page_number":1,"limit":value});
  }
  setPage(pageInfo) {
  
    this.getProjectList({"page_number":(pageInfo.offset+1),"limit":10});
    // this.serverResultsService.getResults(this.page).subscribe(pagedData => {
      // this.offset=1;
      // this.limit= 10;
      // this.rows = this.rows;
    // });
}
  filterSearch(value){

  }
  addNewForm(){
    this.sharingService.setData(undefined);
    this.sharingService.formTitleSet("Create New Project")
    this.router.navigateByUrl('/leads/add-attendance');
  }
  addOutAttendance(){
    this.sharingService.setData(undefined);
    this.sharingService.formTitleSet("Create New Project")
    this.router.navigateByUrl('/leads/out-attendance');
  }
  editData(data){
    this.sharingService.setData(data);
    this.sharingService.formTitleSet("Edit Project")
    this.router.navigateByUrl('/realstate/addprojectmanage');
  }
}
