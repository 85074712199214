import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../api.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

registerForm: FormGroup;

  submitted = false;
  response;
  error;
  success: string;
  items: any;
  id: number;
  item;
  fieldTypes;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getFieldType();
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);

    }


    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      status: [true],
      field_type: ['', Validators.required]
    });

  }

  get f() { return this.registerForm.controls; }

  onSubmit() {

    this.response = true;
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    let request = this.registerForm.value;
    request.type = 'field';
    request.user_id = localStorage.getItem('user_id');
    request.id = this.id;

    this.api.post('organization/add', request).subscribe(
      data => {
        this.response = false;
        if (data == 'success') {
          if (this.id) {
            this.success = "Updated successfully";
          } else {
            this.registerForm.reset();
            this.ngOnInit();
            this.submitted = false;
            this.success = "New field created successfully";
          }
          setTimeout(function () {
            this.success = null;
          }, 5000);

        }
      }, err => {
        this.response = false;
        this.error = "Server error found";

      }

    )

  }

  getItemById(id) {
    let send = { type: 'field', id: id };
    this.api.get(send).subscribe(
      data => {
        this.item = data;
        this.registerForm = this.formBuilder.group({
          name: [this.item.name, Validators.required],
          field_type: [this.item.field_type_id, Validators.required],
          status: [this.item.status, Validators.required]
        });

      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )

  }


  getFieldType() {
    this.api.post('organization/list', { type: 'field-type' }).subscribe(
      data => {
        this.response = false;
        this.fieldTypes = data;
        this.fieldTypes = this.fieldTypes.response;
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };

}
