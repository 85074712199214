import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './page/login/login.component';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { ListComponent } from './page/sms-plan/list/list.component';
import { AddComponent } from './page/sms-plan/add/add.component';
import { SmsRequestComponent } from './page/sms-request/sms-request.component';
import { SmsReportComponent } from './page/sms-report/sms-report.component';
import { StateAddComponent } from './page/state/state-add/state-add.component';
import { StateListComponent } from './page/state/state-list/state-list.component';
import { ModuleListComponent } from './page/module/module-list/module-list.component';
import { ModuleAddComponent } from './page/module/module-add/module-add.component';
import { ModuleEditComponent } from './page/module/module-edit/module-edit.component';
import { UserAddComponent } from './page/user/user-add/user-add.component';
import { UserListComponent } from './page/user/user-list/user-list.component';
import { AddComponent as CategoryAdd } from './page/school/category/add/add.component';
import { ListComponent as CategoryList } from './page/school/category/list/list.component';
import { AddComponent as EnrollmentAdd } from './page/student/enrollment/add/add.component';
import { ListComponent as EnrollmentList } from './page/student/enrollment/list/list.component';
import { AddComponent as EnrollmentSettingAdd } from './page/student/enrollment-setting/add/add.component';
import { AddComponent as FieldAdd } from './page/field/add/add.component';
import { ListComponent as FieldList } from './page/field/list/list.component';
import { AddComponent as AssignDesignationAdd } from './page/hr/assign-designation/add/add.component';
import { ListComponent as AssignDesignationList } from './page/hr/assign-designation/list/list.component';
import { AddComponent as MasterAdd } from './page/hr/master/add/add.component';
import { ListComponent as MasterList } from './page/hr/master/list/list.component';
import { AddComponent as GridAdd } from './page/hr/grid/add/add.component';
import { ListComponent as GridList } from './page/hr/grid/list/list.component';
import { AddComponent as EmployeeAdd } from './page/employee/add/add.component';
import { ListComponent as EmployeeList } from './page/employee/list/list.component';
import { ListComponent as EmployeeSetting } from './page/employee/setting/list/list.component';
import { FileUploadComponent as EmployeeFileUpload } from './page/hr/file-upload/file-upload.component';
import { ListComponent as LeadList } from './page/lead/list/list.component';
import { AddComponent as LeadAdd } from './page/lead/add/add.component';
import { ListComponent as PartyList } from './page/party/master/list/list.component';
import { AddComponent as PartyAdd } from './page/party/master/add/add.component';
import { ChangeComponent as PasswordChange } from './page/password/change/change.component';
import { ResetComponent as PasswordReset } from './page/password/reset/reset.component';
import { AddBookingComponent } from './page/realstate/booking/add/add.component'
import { AddPenaltyComponent } from './page/realstate/penalty/add-penalty/add-penalty.component';
import { AddchargesComponent } from './page/realstate/charges/addcharges/addcharges.component';
import { ListChargesComponent } from './page/realstate/list-charges/list-charges.component';
import { ListPenaltyComponent } from './page/realstate/list-penalty/list-penalty.component';
import { ListBookingComponent } from './page/realstate/booking/list-booking/list-booking.component';
import { ProjectManageComponent } from './page/realstate/project-manage/project-manage.component';
import { AddProjectManageComponent } from './page/realstate/add-project-manage/add-project-manage.component';
import { CustomerPaymentReportComponent } from './page/realstate/realstate/customer-payment-report/customer-payment-report.component';
import { CustomerComissionComponent } from './page/realstate/customer-comission/customer-comission.component';
import { EditBookingComponent } from './page/realstate/booking/edit-booking/edit-booking.component';



const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'sms-plan', component: ListComponent },
  { path: 'sms-plan/add', component: AddComponent },
  { path: 'sms-plan/edit/:id', component: AddComponent },
  { path: 'sms-request', component: SmsRequestComponent },
  { path: 'sms-request/:add', component: SmsRequestComponent },
  { path: 'sms-report', component: SmsReportComponent },
  { path: 'category/list', component: CategoryList },
  { path: 'category/add', component: CategoryAdd },
  { path: 'category/edit/:id', component: CategoryAdd },
  { path: 'state/add', component: StateAddComponent },
  { path: 'state/list', component: StateListComponent },
  { path: 'module/edit/:id', component: ModuleEditComponent },
  { path: 'module/add', component: ModuleAddComponent },
  { path: 'module/list', component: ModuleListComponent },
  { path: 'user/create', component: UserAddComponent },
  { path: 'user/list', component: UserListComponent },
  { path: 'enrollment/add', component: EnrollmentAdd },
  { path: 'enrollment/edit/:id', component: EnrollmentAdd },
  { path: 'enrollment/list', component: EnrollmentList },
  { path: 'enrollment/setting', component: EnrollmentSettingAdd },
  { path: 'field/add', component: FieldAdd },
  { path: 'field/edit/:id', component: FieldAdd },
  { path: 'field/list', component: FieldList },
  { path: 'assign-designation/add', component: AssignDesignationAdd },
  { path: 'assign-designation/edit/:id', component: AssignDesignationAdd },
  { path: 'assign-designation/list', component: AssignDesignationList },
  { path: 'master/edit/:id', component: MasterAdd },
  { path: 'master/add', component: MasterAdd },
  { path: 'master/list', component: MasterList },
  { path: 'master/add', component: MasterAdd },
  { path: 'grid/list', component: GridList },
  { path: 'grid/add', component: GridAdd },
  { path: 'grid/edit/:id', component: GridAdd },
  { path: 'employee/add', component: EmployeeAdd },
  { path: 'employee/edit/:id', component: EmployeeAdd },
  { path: 'employee/list', component: EmployeeList },
  { path: 'employee/setting', component: EmployeeSetting },
  { path: 'employee/upload', component: EmployeeFileUpload },
  { path: 'lead/add', component: LeadAdd },
  { path: 'lead/edit/:id', component: LeadAdd },
  { path: 'lead/list', component: LeadList },
  { path: 'party/add', component: PartyAdd },
  { path: 'party/edit/:id', component: PartyAdd },
  { path: 'party/list', component: PartyList },
  { path: 'change/password', component: PasswordChange },
  { path: 'reset/password', component: PasswordReset },
  { path: 'realstate/booking/list', component: ListBookingComponent },
  { path: 'realstate/booking/edit/:id', component: EditBookingComponent },
  { path: 'realstate/booking/add', component: AddBookingComponent },
  { path: 'realstate/penalty/add', component: AddPenaltyComponent },
  { path: 'realstate/penalty/edit/:penaltyid', component: AddPenaltyComponent },
  { path: 'realstate/charges/add', component: AddchargesComponent },
  { path: 'realstate/charges/list', component: ListChargesComponent },
  { path: 'realstate/penalty/list', component: ListPenaltyComponent },
  { path: 'realstate/charges/edit/:chargeid', component: AddchargesComponent },
  { path: 'realstate/projectmanage', component: ProjectManageComponent },
  { path: 'realstate/addprojectmanage', component: AddProjectManageComponent },
  { path: 'realstate/customer-payment-report', component: CustomerPaymentReportComponent },
  { path: 'customers/comission', component: CustomerComissionComponent },

  { path: 'realstate', loadChildren: () => import('./page/realstate/realstate/realstate.module').then(m => m.RealstateModule) },
  { path: 'leads', loadChildren: () => import('./page/lead/lead.module').then(m => m.LeadModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
