import { Component, OnInit } from '@angular/core';
import { ApiService} from '../../..///api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {

  id:number;
  items:any;
  reqSuccess;
  error;
  response = false;
  registerForm: FormGroup;
  submitted = false;
  status:any;
  reqResponse;
  reqResponseError;
  selectedFile:File;
  modules;
  tempArr: any =  [];
  constructor(private api:ApiService, private formBuilder: FormBuilder, private route: ActivatedRoute) {

  }
  ngOnInit() {
    this.getModules();
    this.items = {
      user_name:'',
      user_email:'',
      user_password:'',
      user_mobile:'',
      user_address:'',
      state:'',
      quantity:'',
      price:''
    }
      
    this.id = this.route.snapshot.params['id'];
    if(this.id){
      this.getItemById(this.id);
      console.log("id=",this.id);

    }
  }

  
  getItemById(id){
    let send = {type:'smsplan',id:id};
    this.api.get(send).subscribe(
      data=>{
        this.items = data;
        if(this.items){
          this.status= true;
        }
      },
      err =>{
        console.error(err);
      },
      ()=>console.log('loading completed')
    )
  };

  getModules(){
    this.api.post('list', {type:'modules'}).subscribe(
      data=>{
        this.modules = data;
        console.log(this.modules, 'out');
        this.api.post('edit', {type:'organization', id: localStorage.getItem('user_id')}).subscribe(
          async (data:any) => {
            var user = data.user ? data.user : {};
            this.modules = await this.modules.filter(m => user.modules.find(u => (u.module_id == m.id ) ))
          console.log(user.modules, 'user');
          console.log(this.modules, 'in');
        },
          err => {
            console.error(err);
          },
          () => console.log('loading completed')
        )

      },
      err =>{
        console.error(err);
      },
      ()=>console.log('loading completed')
    )
  };

  onChangeCategory(event, module: any){ // Use appropriate model type instead of any
    this.tempArr.push({title:module.title, type:module.key, id:module.id});
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }

  submitItem(request){
    
    this.reqResponseError = false;
    this.reqSuccess = false;
    this.response = true;
    this.submitted = true;

    request.user_id = localStorage.getItem('user_id');
    request.type ='school-user';
    request.modules = this.tempArr
    const requestData = new FormData();
    var postData = JSON.stringify(request);

    if(this.selectedFile){
      requestData.append('file', this.selectedFile, this.selectedFile.name);
  
     }
     requestData.append("postData",postData);
      this.api.addUser(requestData).subscribe(
        data=>{
          this.reqResponse = data;
          this.response =false;
          if(this.reqResponse=='success'){
            this.reqSuccess= this.id ? "Item updated successfully" : "New user created successfully";
            this.submitted = false;
            this.ngOnInit();
          }
          else if(this.reqResponse=="exceed"){
            this.reqResponseError = "User create limit is exceed please contact admin for more details";
            console.log(this.reqResponseError);
          }
          else if(this.reqResponse=="duplicate"){
            this.reqResponseError = "This email id already exist for this organization";
          }
  
        },
        err =>{
          this.response = false;
          console.log('error getting',err);
          this.error = "Server error found";
        },
        ()=>{
          this.response =false;
        }
      )
    }


}
