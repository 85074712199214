import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../..///api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WizardComponent } from 'angular-archwizard';

@Component({
  selector: 'app-school-add',
  templateUrl: './school-add.component.html',
  styleUrls: ['./school-add.component.css']
})
export class SchoolAddComponent implements OnInit {
  @ViewChild('wizard', { static: false }) wizard: WizardComponent;

  item;
  success: string = '';
  error: string = '';
  response = false;
  registerForm: FormGroup;
  submitted = false;
  modules;
  tempArr: any = [];
  isChecked;
  sessions;
  countries: any = [];
  states;
  filterStates = [];
  selectedFile: File;

  constructor(private api: ApiService, private formBuilder: FormBuilder) {
    this.item = {};


  }


  ngOnInit() {
    this.getCountry();
    this.getState();
    this.sessions = this.api.sessions;

    this.getModules();

    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      pincode: ['', Validators.required],
      currency: ['', Validators.required],
      number: ['', Validators.required],
      website: ['', Validators.required],
      user_name: ['', Validators.required],
      user_mobile: ['', Validators.required],
      user_email: ['', Validators.required],
      user_password: ['', Validators.required],
      question: ['', Validators.required],
      answer: ['', Validators.required]
    })
  }


  get f() { return this.registerForm.controls; }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }

  submitItem(request) {


    if (this.registerForm.invalid) {
      return;
    }

    this.success = '';
    this.error = '';
    this.response = true;
    this.submitted = true;
    request.modules = this.tempArr;
    request.organization_id = localStorage.getItem('user_id');
    const requestData = new FormData();
    var postData = JSON.stringify(request);

    if (this.selectedFile) {
      requestData.append('file', this.selectedFile, this.selectedFile.name);

    }
    requestData.append("postData", postData);

    this.api.addSchool(requestData).subscribe(
      data => {

        if (data == 'success') {
          // this.item = {};
          this.response = false;
          this.tempArr = [];
          this.registerForm.reset();
          this.submitted = false;
          this.wizard.goToPreviousStep();
          this.wizard.goToPreviousStep();
          this.ngOnInit();

          this.success = "New item created successfully";
        } else if (data == "duplicate") {
          this.error = "Email ID already exists";
        }
        else if (data == "exceed") {
          this.error = "Organization create limit is exceed please contact administrator for more details";

        }

      },
      err => {
        this.response = false;
        this.error = "Server error found";
      },
      () => {
        this.response = false;
      }
    )
  }




  getModules() {
    this.api.post('list', { type: 'modules' }).subscribe(
      data => {
        this.modules = data;

        this.api.post('edit', { type: 'organization', id: localStorage.getItem('user_id') }).subscribe(
          (data: any) => {
            this.modules = this.modules.filter(m => data.user.modules.find(u => (u.module_id == m.id)))
          },
          err => {
            console.error(err);
          },
          () => console.log('loading completed')
        )

      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  onChangeCategory(event, module: any) { // Use appropriate model type instead of any
    this.tempArr.push({ title: module.title, type: module.key, id: module.id });
  }


  onChange(country_d) {
    let newStates = [];
    this.states.forEach(function (value) {
      if (value.country_id == country_d) {
        newStates.push(value);
      }
    });
    this.filterStates = newStates;

    this.countries.forEach(element => {
      if (element.id == country_d) {
        this.item.currency = element.currency;
      }


    });

  }

  getCountry() {
    this.api.post('list', { type: "country" }).subscribe(
      data => {
        this.countries = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  getState() {
    this.api.post('list', { type: "state" }).subscribe(
      data => {
        this.states = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };


}
