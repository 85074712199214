import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../../modal/confirmation-dialog.service';

@Component({
  selector: 'app-fee-collection-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnChanges, OnInit {
  @Input() childProperty;
  items;
  response = true;
  pageOfItems: Array<any>;
  other;
  hideme = [];

  constructor(private api: ApiService, public router: Router, private confirmationDialogService: ConfirmationDialogService) { }

  ngOnChanges() {
    if (this.childProperty) {
      this.pageOfItems = this.childProperty.data;
      this.response = false;

    }

  }
  ngOnInit() {
    this.api.isLogged();
    if (!this.childProperty) {
      this.getLists();
    }

  }



  getLists() {
    this.api.post('list/fee-collection', { type: 'search-student-fee-collection', id: localStorage.getItem('org_id') }).subscribe(
      data => {
        this.response = false;
        this.items = data;
        this.items = this.items.response;
      },
      err => {
        this.response = false;
      },
      () => console.log('loading completed')
    )
  };

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;

  }


  deleteItem(id) {
    this.response = true;
    let item = { id: id, type: 'student-fee-collection' };
    this.api.delete(item).subscribe(
      data => {
        this.response = false;
        this.pageOfItems.splice(this.pageOfItems.findIndex(function (i) {
          return i.id == id;
        }), 1);
      }, err => {
        this.response = false;
        console.log(err);
      }
    )

  };

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
      .then((confirmed) => {
        console.log('User confirmed:', confirmed)
        if (confirmed) {
          this.deleteItem(id);
        }

      })
  }


}
